import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TUB_CARDS , ADD_NEW_TUB_CARD , UPDATE_TUB_CARD } from "./actionTypes"

import {
  getTubCardsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"
import { Toast } from "helpers/Toast"


function* fetchTubCards(data) {
  try {
    const response = yield call(API_ENDPOINTS.getTubCards, {queryStringParameters: data.payload})
    yield put(getTubCardsSuccess(response))
  } catch (error) {
    Toast('error', error)
  }
}


function* onAddNewTubCard(data) {
  try {
    let res = yield call(API_ENDPOINTS.createOrEditTubCard, {body: data.payload})
  } catch (error) {
    Toast('error', error)
  }
}

function* updateTubCardDetail(data) {
  try {
    let body = {
      body: data.payload,
      queryStringParameters: {
        imageId: data.payload._id
      }
    }
    yield call(API_ENDPOINTS.createOrEditTubCard, body)

  } catch (error) {
    console.log('error', error)
    Toast('error', error)
  }
}

function* wordsSaga() {
  yield takeEvery(GET_TUB_CARDS, fetchTubCards)
  yield takeEvery(ADD_NEW_TUB_CARD, onAddNewTubCard)
  yield takeEvery(UPDATE_TUB_CARD, updateTubCardDetail)
}

export default wordsSaga;