import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

function GetUserDetail({ user }) {
  const [modal, setModal] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const toggle = async () => {
    setModal(!modal);
    if (!modal) {
      await getAllUsers();
    }
  };

  const getAllUsers = async () => {
    const Token = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_URL}/admin_panel/v3.8/user/getAllUsers?userId=${user?._id}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      setApiData(response?.data?.data?.Schools);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <Button color="primary" onClick={toggle}>
        View
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered lg>
        <ModalHeader
          toggle={toggle}
        >{`${user?.First_name} ${user?.Last_name}`}</ModalHeader>
        <ModalBody>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <strong>Email:</strong>
                </td>
                <td>{user?.Email || "NA"} </td>
              </tr>
              <tr>
                <td>
                  <strong>Gender:</strong>
                </td>
                <td>{user?.Gender || "N/A"}</td>
              </tr>
              <tr>
                <td>
                  <strong>Role Name:</strong>
                </td>
                <td>{user?.RoleName || "NA"}</td>
              </tr>
              <tr>
                <td>
                  <strong>Active Subscription Plan:</strong>
                </td>
                <td className="text-capitalize">
                  {(user?.subscription[0]?.SubscriptionPlan &&
                    user?.subscription[0]?.SubscriptionPlan) ||
                    user?.subscription?.SubscriptionPlan ||
                    "Freemium"}{" "}
                  {user?.subscription[0]?.isTubClassPremium ||
                  user?.subscription?.isTubClassPremium === true
                    ? "(TUB Class user)"
                    : null}
                </td>
              </tr>
              {user?.RoleName !== "Parent" ? (
                <>
                  <tr>
                    <td>
                      <strong>School Name:</strong>
                    </td>
                    <td>
                      {user?.teams[0]?.SchoolName ||
                        user?.teams[0]?.schools[0]?.SchoolName ||
                        "NA"}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>School Affiliated:</strong>
                    </td>
                    <td>{user?.teams[0]?.schools[0]?.AffiliatedID || "NA"}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>School Address:</strong>
                    </td>
                    <td>
                      {user?.teams[0]?.schools[0]?.SchoolAddr1}{" "}
                      {user?.teams[0]?.schools[0]?.SchoolAddr2}
                    </td>
                  </tr>
                  {(user?.subscription[0]?.SubscriptionPlan &&
                    user?.subscription[0]?.SubscriptionPlan) ||
                    user?.subscription?.SubscriptionPlan === "premium" ?
                  <tr>
                    <td>
                      <strong>Subscription Plan:</strong>
                    </td>
                    <td>
                      {moment(
                        user?.teams[0]?.schools[0]?.license?.startDate
                      ).format("MMMM Do YYYY")}{" "}
                      <strong>To</strong>{" "}
                      {moment(
                        user?.teams[0]?.schools[0]?.license?.endDate
                      ).format("MMMM Do YYYY")}
                    </td>
                  </tr>: null}
                </>
              ) : null}
              <tr>
                <td>
                  <strong>Created At:</strong>
                </td>
                <td>{moment(user?.createdAt).format("MMMM Do YYYY")}</td>
              </tr>
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default GetUserDetail;
