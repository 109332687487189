import React, { useEffect,useState } from "react";
import {
    BreadcrumbItem,
  CardTitle,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import {  WordiconPacksColumn } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import { getPacksInCategory, getWordiconPacks } from "store/actions";
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { history } from "helpers/utils";
import BackBtn from "components/Button/backBtn";

const PacksInCategoryList = (props) => {
    const dispatch = useDispatch();
    const [categoryName, setCategoryName] = useState('')
    const { packsInCategory } = useSelector(state => ({
        packsInCategory: state.categories.packsInCategory,
    }));
    
    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        const categoryname = query.get('categoryName')

        let queryPayload = {
            categoryFilter: categoryname,
            search: ''
        }
        setCategoryName(categoryname)
        dispatch(getPacksInCategory(queryPayload));
    }, [dispatch, props]);

    const getApplicationDetails = (row) => {
        props.history.push(`/staff/pack/detail/${row[9]}`)
    }
    const filterList = (queryPayload) => {
        let payload = {
            ...queryPayload,
            categoryFilter: categoryName
            // curriculum_Id: props.match.params.curriculumId
        }
        dispatch(getPacksInCategory(payload))
    }

    const redirectTo = (row) => {
        history.push(`/staff/pack/detail/list/${row[9]}`)
    }

    const getTableColumns = () => {
        let editObj = {
            label: "Actions",
            name: '',
            options: { 
                filter: true,
                sort: false, 
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className='d-flex align-items-center justify-content-center'>
                        <div
                            onClick={() => redirectTo(tableMeta?.rowData)}
                        >
                            <MdOutlineRemoveRedEye size={18}/>
                        </div>
                        {/* <div
                            className="ms-1"
                            onClick={() => getApplicationDetails(tableMeta?.rowData, true)}
                        >
                            <MdEdit size={16}/>
                        </div>   */}
                    </div>
                )
            }
        }
        let newtable = [...WordiconPacksColumn,editObj]
         return newtable
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-space-between">
                        <div className="d-flex  align-items-center ">
                            <BackBtn history={props.history} />
                            <div className="mx-2">
                                <CardTitle>{categoryName}</CardTitle>
                            </div>
                        </div>
                        <div className="page-title-box">
                            <div className="page-title-right d-none d-lg-block">
                                <ol className="breadcrumb m-0">
                                    <BreadcrumbItem>
                                        <Link to="#">Categories</Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <Link to="#">{categoryName}</Link>
                                    </BreadcrumbItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <CustomBootstrapTable 
                        rows = {packsInCategory}
                        columns = {getTableColumns()}
                        isLoading={false}
                        showCreateBtn = {false}
                        keyField={'_id'}
                        createBtnLabel="Add New Pack"
                        onCreateRedirect = {`/staff/pack/detail`}
                        getApplicationDetails={(row) => null}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                        filterList = {(...params) => filterList(...params)}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export { PacksInCategoryList }