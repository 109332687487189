export const TEMPLATE_DATA = [
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png"
    },
    "alphabetName": "A"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png"
    },
    "alphabetName": "B"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png"
    },
    "alphabetName": "C"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png"
    },
    "alphabetName": "D"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png"
    },
    "alphabetName": "E"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png"
    },

    "alphabetName": "F"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6664649112192.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6664649112192.png"
    },

    "alphabetName": "G"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1609776497592.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1609776497592.png"
    },

    "alphabetName": "H"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1779615999440.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1779615999440.png"
    },
    "alphabetName": "I"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7293351637223.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7293351637223.png"
    },
    "alphabetName": "J"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5316365070587.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5316365070587.png"
    },
    "alphabetName": "K"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4988269700428.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4988269700428.png"
    },
    "alphabetName": "L"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7767693220186.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7767693220186.png"
    },
    "alphabetName": "M"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4484263990806.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4484263990806.png"
    },

    "alphabetName": "N"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3687316189967.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3687316189967.png"
    },

    "alphabetName": "O"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_281985584616.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_281985584616.png"
    },
    "alphabetName": "P"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5991491925056.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5991491925056.png"
    },
    "alphabetName": "Q"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_725857606841.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_725857606841.png"
    },
    "alphabetName": "R"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1762037198112.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1762037198112.png"
    },
    "alphabetName": "S"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3772998262112.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3772998262112.png"
    },
    "alphabetName": "T"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_8868602215171.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_8868602215171.png"
    },

    "alphabetName": "U"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9194360141960.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9194360141960.png"
    },

    "alphabetName": "V"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6786152969940.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6786152969940.png"
    },
    "alphabetName": "W"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2958395729464.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2958395729464.png"
    },
    "alphabetName": "X"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3976354170542.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3976354170542.png"
    },
    "alphabetName": "Y"
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_562850494695.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_562850494695.png"
    },
    "alphabetName": "Z"
  }
]

export const STENCIL_DROPDOWN = [
  {
    "label": "A",
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png",
  },
  {
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png",
    "label": "B"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png",
    "label": "C"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png",
    "label": "D"
  },
  {
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png",
    "label": "E"
  },
  {
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png",
    "label": "F"
  },
  {
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6664649112192.png",
    "label": "G"
  },
  {
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1609776497592.png",
    "label": "H"
  },
  {
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1779615999440.png",
    "label": "I"
  },
  {
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7293351637223.png",
    "label": "J"
  },
  {
    "value": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5316365070587.png",
    "label": "K"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4988269700428.png",
    "label": "L"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7767693220186.png",
    "label": "M"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4484263990806.png",
    "label": "N"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3687316189967.png",
    "label": "O"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_281985584616.png",
    "label": "P"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5991491925056.png",
    "label": "Q"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_725857606841.png",
    "label": "R"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1762037198112.png",
    "label": "S"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3772998262112.png",
    "label": "T"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_8868602215171.png",
    "label": "U"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9194360141960.png",
    "label": "V"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6786152969940.png",
    "label": "W"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2958395729464.png",
    "label": "X"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3976354170542.png",
    "label": "Y"
  },
  {
    "value":
      "https://s3-us-west-2.amazonaws.com/alphatubnew/img_562850494695.png",
    "label": "Z"
  }
]

export const CONTENT_RATING_DROPDOWN = [
  {
    label: '1+',
    value: '1+'
  },
  {
    label: '2+',
    value: '2+'
  },
  {
    label: '3+',
    value: '3+'
  },
  {
    label: '4+',
    value: '4+'
  },
  {
    label: '5+',
    value: '5+'
  }
]

export const TUB_SHEET_MOCK_DATA = [
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png"
    },
    "alphabetName": "A",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png"
    },
    "alphabetName": "B",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png"
    },
    "alphabetName": "C",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png"
    },
    "alphabetName": "D",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png"
    },
    "alphabetName": "E",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png"
    },

    "alphabetName": "F",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6664649112192.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6664649112192.png"
    },

    "alphabetName": "G",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1609776497592.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1609776497592.png"
    },

    "alphabetName": "H",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1779615999440.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1779615999440.png"
    },
    "alphabetName": "I",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7293351637223.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7293351637223.png"
    },
    "alphabetName": "J",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5316365070587.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5316365070587.png"
    },
    "alphabetName": "K",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4988269700428.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4988269700428.png"
    },
    "alphabetName": "L",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7767693220186.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7767693220186.png"
    },
    "alphabetName": "M",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4484263990806.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_4484263990806.png"
    },

    "alphabetName": "N",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3687316189967.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3687316189967.png"
    },

    "alphabetName": "O",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_281985584616.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_281985584616.png"
    },
    "alphabetName": "P",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5991491925056.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_5991491925056.png"
    },
    "alphabetName": "Q",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_725857606841.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_725857606841.png"
    },
    "alphabetName": "R",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1762037198112.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_1762037198112.png"
    },
    "alphabetName": "S",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3772998262112.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3772998262112.png"
    },
    "alphabetName": "T",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_8868602215171.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_8868602215171.png"
    },
    "alphabetName": "U",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9194360141960.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9194360141960.png"
    },
    "alphabetName": "V",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6786152969940.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6786152969940.png"
    },
    "alphabetName": "W",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2958395729464.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2958395729464.png"
    },
    "alphabetName": "X",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3976354170542.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_3976354170542.png"
    },
    "alphabetName": "Y",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_562850494695.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_562850494695.png"
    },
    "alphabetName": "Z",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  }
]

export const TUB_CARD_MOCK_DATA = [
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7434680891132.png"
    },
    "alphabetName": "A",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_6194119907633.png"
    },
    "alphabetName": "B",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_7999382794595.png"
    },
    "alphabetName": "C",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2355419504753.png"
    },
    "alphabetName": "D",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_2839176645093.png"
    },
    "alphabetName": "E",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  },
  {
    "stencilImage": {
      "original": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png",
      "thumbnail": "https://s3-us-west-2.amazonaws.com/alphatubnew/img_9531919999261.png"
    },

    "alphabetName": "F",
    "imageTitle": "",
    "alphabetImage": {
      "original": "",
      "thumbnail": ""
    }
  }
]