import {
  GET_WORDICON_PACKS_SUCCESS,
  GET_WORDICON_PACK_DETAIL_SUCCESS,
  GET_WORDICON_PACK_DETAIL_LIST_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  packsList: [],
  packDetailList: {},
  packDetail: {},
  error: {},
}

const wordiconPack = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WORDICON_PACKS_SUCCESS:
      return {
        ...state,
        packsList: action.payload,
      }

    case GET_WORDICON_PACK_DETAIL_LIST_SUCCESS:
        return {
          ...state,
          packDetailList: action.payload,
        }
    case GET_WORDICON_PACK_DETAIL_SUCCESS:
      return {
        ...state,
        packDetail: action.payload,
      }


    default:
      return state
  }
}

export default wordiconPack
