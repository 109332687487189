import { Breadcrumbs } from "@mui/material";
import { API_ENDPOINTS } from "api/endpoints";
import { ModalContainer } from "components/Modal";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
import { SchoolList, DirectorDetails } from "helpers/tableDataMapper";
import React, { useEffect, useState } from "react";
import { Container, Row, Form, Col, CardTitle } from "reactstrap";
import { MdEdit, MdVisibility  } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import { Toast } from "helpers/Toast";
import ReactSelect from "components/ReactSelect";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { history } from "helpers/utils";
import { useLocation } from 'react-router-dom';
import Select from 'react-select'; // Import react-select

const DirectorDetail = () => {
  // let username = localStorage.getItem("username");
  const location = useLocation();
  const myObject = location?.state?.id;
  const [loading, setLoading] = useState(false);
  const [directorDetail, setDirectorDetail] = useState([]);
  const [directorListing, setDirectorList] = useState([]);
  

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);

  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);

  const [selectedCity, setSelectedCity] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const redirectAssignSchool = (row) => {
    // history.push(`/staff/school/${row[1]}`)
    console.log(row,'redirectAssignSchool');
    history.push({
      pathname: `/staff/school/${row[1]}`,
      state: { id: row?.[5] }
    });
}
  const getDirectorDetail = async () => {
    let queryPayload = {
      userId:myObject,
      Country: selectedCountry ? selectedCountry.value : '',
      State:selectedState ? selectedState.value : '',
      City:selectedCity ? selectedCity.value : '',
    };
    try {
      setLoading(true);
      const responce = await API_ENDPOINTS.getdirectorDetail(queryPayload);
      if (responce) {
        setLoading(false);
        let schools  =  responce[0]?.teams?.map((val)=>(val?.schools))
        setDirectorList(schools);
        setDirectorDetail(responce[0])
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDirectorDetail();
  }, [selectedCountry, selectedState, selectedCity]);



  const getCountries = async () => {
    try {
    setLoading(true);
    const response = await API_ENDPOINTS.getCountries();
    console.log(response, "responsesssss");
    if (response) {
      const countryOptions = response.map(country => ({
        value: country.name,
        label: country.name,
        Id:country.id
      }));
      setCountryOptions(countryOptions);
      setIsDisabled(false); // Enable state selection if countries are available
      setLoading(false);
    }
   
  } catch (error) {
    console.error("Error fetching countries:", error);
  }
  };
  useEffect(() => {
    getCountries();
  }, []);

  const getStates = async () => {
    if (selectedCountry) {
      let queryPayload = {
        countryId: selectedCountry.Id // Pass the selected country ID
      };
      try {
        setLoading(true);
        const response = await API_ENDPOINTS.getStates(queryPayload);
        console.log(response, "responsesssss");
        if (response) {
          const stateOptions = response.map(state => ({
            value: state.name,
            label: state.name,
            id:state.id
          }));
          setStateOptions(stateOptions);
          setIsDisabled(false); // Enable city selection if states are available
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
  };

    useEffect(() => {
      if (selectedCountry) {
        const getStates = async () => {
          let queryPayload = {
            countryId: selectedCountry.Id
          };
          try {
            setLoading(true);
            const response = await API_ENDPOINTS.getStates(queryPayload);
            console.log(response, "responsesssss");
            if (response) {
              const stateOptions = response.map(state => ({
                value: state.name,
                label: state.name,
                id: state.id
              }));
              setStateOptions(stateOptions);
              setIsDisabled(false); // Enable state selection if states are available
              setLoading(false);
            }
          } catch (error) {
            console.error("Error fetching states:", error);
          }
        };
        getStates();
      }
    }, [selectedCountry])

  
  const getCities = async () => {
    if (selectedState) {
      let queryPayload = {
        stateId: selectedState.id // Pass the selected state ID
      };
      try {
        setLoading(true);
        const response = await API_ENDPOINTS.getCities(queryPayload);
        console.log(response, "responsesssss");
        if (response) {
          const cityOptions = response.map(city => ({
            value: city.name,
            label: city.name
          }));
          setCityOptions(cityOptions);
          setIsDisabled(false); // Enable city selection if cities are available
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    }
  };
  useEffect(() => {
    if (selectedState) {
      const getCities = async () => {
        let queryPayload = {
          stateId: selectedState.id
        };
        try {
          setLoading(true);
          const response = await API_ENDPOINTS.getCities(queryPayload);
          console.log(response, "responsesssss");
          if (response) {
            const cityOptions = response.map(city => ({
              value: city.name,
              label: city.name
            }));
            setCityOptions(cityOptions);
            setIsDisabled(false); // Enable city selection if cities are available
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };
      getCities();
    }
  }, [selectedState]);

    const getTableColumns = () => {
      let newtable = [...DirectorDetails];
      return newtable;
    };

  return loading ? (
    <></>
  ) : (
    <div className="page-content">
      <Container fluid>


      <Form>
          <div className="Filter-location">
          <Row>   
             <Col>
            <div className="card-body">
                <h4>{directorDetail.First_name} {directorDetail.Last_name}</h4>
                <span>{directorDetail.Email}</span>
            </div>
             </Col>
            <Col>
              <Select
                options={countryOptions}
                value={selectedCountry}
                onChange={(selectedOption) => {
                  setSelectedCountry(selectedOption);
                }}
                placeholder="Select a country"
                isSearchable={true} // Enable search
              />
            </Col>
            <Col>
              <Select
                options={stateOptions}
                value={selectedState}
                onChange={(selectedOption) => {
                  setSelectedState(selectedOption);
                }}
                placeholder="Select a state"
                isSearchable={true} // Enable search\
                // isDisabled={isDisabled}
              />
            </Col>
            <Col>
              <Select
                options={cityOptions}
                value={selectedCity}
                onChange={(selectedOption) => {
                  setSelectedCity(selectedOption);
                }}
                placeholder="Select a city"
                isSearchable={true} // Enable search
                //  isDisabled={isDisabled}
              />
            </Col>
          </Row>
          </div>  
        </Form>


        <Breadcrumbs title="TUB Cards" breadcrumbItem="TUB Card List" />
      {/* <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
                <h4>{directorDetail.First_name} {directorDetail.Last_name}</h4>
                <span>{directorDetail.Email}</span>
            </div>
          </div>
        </div>
      </div> */}
        <CustomBootstrapTable
          rows={directorListing}
          columns={getTableColumns()}
          isLoading={false}
          keyField={"_id"}
          showStatusFilter={false}
          showDateFilter={false}
          isSearch={true} 
        />
      </Container>
      
    </div>
  );
};

export { DirectorDetail };
