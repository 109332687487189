import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

function ViewChildren({ directorId }) {
  const [modal, setModal] = useState(false);
  const [levels, setLevels] = useState();
  const [deactive, setDeactive] = useState();
  const [restore, setRestore] = useState();
  const [level, setLevel] = useState(null);
  const [replace, setReplace] = useState();
  const [replaceId, setReplaceId] = useState();

  const [replaceIds, setReplaceIds] = useState(false);
  const [unassigned, setUnassigned] = useState([]);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchUnassigned();
  }, [modal]);

  const fetchUserLevels = async () => {
    try {
      const token = localStorage.getItem("accessToken") || "{}";
      const headers = {
        Authorization: token,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_URL}/user-levels/${directorId}`,
        { headers }
      );
      setLevels(response?.data?.data?.userLevels?.children);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchUnassigned = async () => {
    try {
      const token = localStorage.getItem("accessToken") || "{}";
      const headers = {
        Authorization: token,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_URL}/user-levels/child-users/unassigned`,
        { headers }
      );
      setUnassigned(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDeactivate = async (userId) => {
    try {
      const token = localStorage.getItem("accessToken") || "{}";
      const headers = {
        Authorization: token,
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_DEV_URL}/user-levels/${userId}/deactivate`,
        null,
        { headers }
      );

      setDeactive(response?.data);
      fetchUserLevels();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchActivate = async (userId) => {
    try {
      const token = localStorage.getItem("accessToken") || "{}";
      const headers = {
        Authorization: token,
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_DEV_URL}/user-levels/${userId}/restore`,
        null,
        { headers }
      );

      setRestore(response?.data);
      fetchUserLevels();
      fetchUserLevels();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchUserLevel = async (directorItemId) => {
    try {
      const token = localStorage.getItem("accessToken") || "{}";
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_DEV_URL}/user-levels`,
        { parentUserId: directorId, childUserIds: [directorItemId] },
        { headers }
      );

      setLevel(response?.data);
      fetchUnassigned();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchReplace = async (directorItemId) => {
    console.log(directorItemId, "dddd", replaceId);
    try {
      const token = localStorage.getItem("accessToken") || "{}";
      const headers = {
        Authorization: token,
      };
      const response = await axios.patch(
        `${process.env.REACT_APP_DEV_URL}/user-levels/${replaceId}/replace`,
        { newUserId: directorItemId },
        { headers }
      );

      setReplace(response?.data);
      setReplaceIds(false);
      fetchUserLevels();
      fetchUnassigned();
    } catch (error) {
      console.error("Error fetching data:", error);
      setReplaceIds(false);
    }
  };

  return (
    <div>
      <Button
        className="px-4 primary_btn"
        onClick={() => {
          toggle();
          fetchUserLevels();
        }}
      >
        View
      </Button>

      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalHeader toggle={toggle}>View</ModalHeader>
        <ModalBody>
          <table className="table">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Name</th>
                <th>Role</th>
                <th>Date</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(levels) && levels.length ? (
                levels.map((level, index) => (
                  <tr
                    key={index}
                    className={level?.isDeleted === true ? "text-danger" : ""}
                  >
                    <td>{index + 1}</td>
                    <td>
                      {level?.userDetails?.First_name}{" "}
                      {level?.userDetails?.Last_name}
                    </td>
                    <td>{level?.userDetails?.RoleName}</td>
                    <td>
                      {moment(level?.userDetails?.createdAt).format("LL")}
                    </td>
                    <td>{level?.userDetails?.Email}</td>
                    <td>
                      <Button
                        className="me-2 px-4 primary_btn"
                        onClick={() =>
                          level?.isDeleted
                            ? fetchActivate(level?.userDetails?._id)
                            : fetchDeactivate(level?.userDetails?._id)
                        }
                      >
                        {level?.isDeleted ? "Activate" : "Deactivate"}
                      </Button>
                      <Button
                        className="px-4 primary_btn"
                        onClick={() => {
                          setReplaceId(level?.userDetails?._id);
                          setReplaceIds(true);
                        }}
                      >
                        Replace
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <table className="table">
            <thead>
              <tr>
                <th>S No.</th>
                <th>Name</th>
                <th>Role</th>
                <th>Date</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(unassigned) && unassigned.length ? (
                unassigned.map((directorItem, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {directorItem?.First_name} {directorItem?.Last_name}
                    </td>
                    <td>{directorItem?.RoleName}</td>
                    <td>{moment(directorItem?.createdAt).format("LL")}</td>
                    <td>{directorItem?.Email}</td>
                    <td>
                      {replaceIds === true ? (
                        <Button
                          className="px-4 primary_btn"
                          onClick={() => fetchReplace(directorItem?._id)}
                        >
                          Replace With
                        </Button>
                      ) : (
                        <Button
                          className="px-4 primary_btn"
                          onClick={() => fetchUserLevel(directorItem?._id)}
                        >
                          Add
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ViewChildren;
