import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { Provider } from "react-redux";
import store from "./store";
import Routes from "routes";
import { initializeAws } from "helpers/AWSHelper";

initializeAws();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Routes />
  </Provider>
);

serviceWorker.unregister();
