import React, { useEffect, useState } from "react";
import {
  Container,
  CardTitle
} from "reactstrap";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { CustomersColumns } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import {
    getCustomers,
} from "store/customers/actions";

const Customers = (props) => {
    const dispatch = useDispatch();

    const { customersList } = useSelector(state => ({
        customersList: state.customers.customersList,
    }));

    useEffect(() => {
        let queryPayload = {
            search: ''
        }
        dispatch(getCustomers(queryPayload));
    }, [dispatch]);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <CardTitle className="page-title-box">CUSTOMERS</CardTitle>
                    <CustomBootstrapTable 
                        rows = {customersList}
                        columns = {CustomersColumns}
                        showCreateBtn = {false}
                        isLoading={false}
                        keyField={'_id'}
                        createBtnLabel="Add New Customers"
                        onCreateRedirect = {null}
                        filterList = {null}
                        triggerPopup = {null}
                        getApplicationDetails={null}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                    />
                </Container>
            </div>
        </>
    )
}

export { Customers }