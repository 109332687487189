import {
  // curriculums
  GET_CURRICULUMS,
  GET_CURRICULUMS_FAIL,
  GET_CURRICULUMS_SUCCESS,
  ADD_NEW_CURRICULUM,
  ADD_CURRICULUM_SUCCESS,
  ADD_CURRICULUM_FAIL,
  UPDATE_CURRICULUM,
  UPDATE_CURRICULUM_SUCCESS,
  UPDATE_CURRICULUM_FAIL,

  // Reading program
  GET_READING_PROGRAM_DETAIL,
  GET_READING_PROGRAM_DETAIL_FAIL,
  GET_READING_PROGRAM_DETAIL_SUCCESS,
  GET_READING_PROGRAMS,
  GET_READING_PROGRAMS_FAIL,
  GET_READING_PROGRAMS_SUCCESS,
  ADD_NEW_READING_PROGRAM,
  ADD_READING_PROGRAM_SUCCESS,
  ADD_READING_PROGRAM_FAIL,
  UPDATE_READING_PROGRAM,
  UPDATE_READING_PROGRAM_SUCCESS,
  UPDATE_READING_PROGRAM_FAIL,
  UPDATE_READING_PROGRAM_DATA,
  UPDATE_READING_PROGRAM_DATA_SUCCESS,
  UPDATE_READING_PROGRAM_DATA_FAIL,
  ADD_NEW_READING_PROGRAM_DATA,
  ADD_NEW_READING_PROGRAM_DATA_SUCCESS,
  ADD_NEW_READING_PROGRAM_DATA_FAIL,
  GET_CURRICULUM_READING_PROGRAMS,
  GET_CURRICULUM_READING_PROGRAMS_SUCCESS,
} from "./actionTypes"

export const getCurriculums = (queryPayload) => ({
  type: GET_CURRICULUMS,
  payload: queryPayload
})

export const getCurriculumsSuccess = curriculums => ({
  type: GET_CURRICULUMS_SUCCESS,
  payload: curriculums,
})

export const createOrEditCurriculum = data => ({
  type: ADD_NEW_CURRICULUM,
  payload: data,
})

export const addCurriculumSuccess = user => ({
  type: ADD_CURRICULUM_SUCCESS,
  payload: user,
})

export const addCurriculumFail = error => ({
  type: ADD_CURRICULUM_FAIL,
  payload: error,
})

export const getCurriculumsFail = error => ({
  type: GET_CURRICULUMS_FAIL,
  payload: error,
})

export const updateCurriculum = user => ({
  type: UPDATE_CURRICULUM,
  payload: user,
})

export const updateCurriculumSuccess = user => ({
  type: UPDATE_CURRICULUM_SUCCESS,
  payload: user,
})

export const updateCurriculumFail = error => ({
  type: UPDATE_CURRICULUM_FAIL,
  payload: error,
})

export const getReadingPrograms = (queryPayload) => ({
  type: GET_READING_PROGRAMS,
  payload: queryPayload
})

export const getReadingProgramsSuccess = ReadingPrograms => ({
  type: GET_READING_PROGRAMS_SUCCESS,
  payload: ReadingPrograms,
})

export const getReadingProgramDetail = (params) => ({
  type: GET_READING_PROGRAM_DETAIL,
  payload: params
})

export const addNewReadingProgram = data => ({
  type: ADD_NEW_READING_PROGRAM,
  payload: data,
})

export const addReadingProgramSuccess = user => ({
  type: ADD_READING_PROGRAM_SUCCESS,
  payload: user,
})

export const addReadingProgramFail = error => ({
  type: ADD_READING_PROGRAM_FAIL,
  payload: error,
})

export const getReadingProgramsFail = error => ({
  type: GET_READING_PROGRAMS_FAIL,
  payload: error,
})

export const getReadingProgramDetailSuccess = data => ({
  type: GET_READING_PROGRAM_DETAIL_SUCCESS,
  payload: data,
})

export const getReadingProgramDetailFail = error => ({
  type: GET_READING_PROGRAM_DETAIL_FAIL,
  payload: error,
})


export const updateReadingProgram = data => ({
  type: UPDATE_READING_PROGRAM,
  payload: data,
})

export const updateReadingProgramSuccess = user => ({
  type: UPDATE_READING_PROGRAM_SUCCESS,
  payload: user,
})

export const updateReadingProgramFail = error => ({
  type: UPDATE_READING_PROGRAM_FAIL,
  payload: error,
})

// PROGRAM DATA

export const addNewReadingProgramData= data => ({
  type: ADD_NEW_READING_PROGRAM_DATA,
  payload: data,
})

export const addReadingProgramDataSuccess = user => ({
  type: ADD_NEW_READING_PROGRAM_DATA_SUCCESS,
  payload: user,
})

export const addReadingProgramDataFail = error => ({
  type: ADD_NEW_READING_PROGRAM_DATA_FAIL,
  payload: error,
})

export const updateReadingProgramData = data => ({
  type: UPDATE_READING_PROGRAM_DATA,
  payload: data,
})

export const updateReadingProgramDataSuccess = user => ({
  type: UPDATE_READING_PROGRAM_DATA_SUCCESS,
  payload: user,
})

export const updateReadingProgramDataFail = error => ({
  type: UPDATE_READING_PROGRAM_DATA_FAIL,
  payload: error,
})

// curriculum wise get reading progam
export const getCurriculumReadingPrograms = (queryPayload) => ({
  type: GET_CURRICULUM_READING_PROGRAMS,
  payload: queryPayload
})

export const getCurriculumReadingProgramsSuccess = ReadingPrograms => ({
  type: GET_CURRICULUM_READING_PROGRAMS_SUCCESS,
  payload: ReadingPrograms,
})