import { Breadcrumbs } from "@mui/material";
import { API_ENDPOINTS } from "api/endpoints";
import { ModalContainer } from "components/Modal";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
import { SchoolList } from "helpers/tableDataMapper";
import React, { useEffect, useState } from "react";
import { Container, Row, Form, Col, CardTitle } from "reactstrap";
import { MdEdit } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import { Toast } from "helpers/Toast";
import Select from "react-select"; // Import react-select
import ReactSelect from "components/ReactSelect";
import { Delete } from "@mui/icons-material";
import axios from "axios";

const AddSchool = () => {
  let username = localStorage.getItem("username");

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState("Freemium");

  const [countryOptions, setCountryOptions] = useState([]);
  const [apiData, setApiData] = useState();
  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);

  const [selectedCity, setSelectedCity] = useState(null);
  const [tubClassType, setTubClassType] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const [isOpen, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [schoolListData, setSchoolList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [schoolId, setSchoolID] = useState({
    SchoolName: "",
    SeatsPurchased: "",
    SchoolEmailID: "",
    PrincipalFirstName: "",
    id: "",

    SchoolAddr1: "",
    SchoolAddr2: "",
    PrincipalFirstName: "",
    ZipCode: "",
    AffiliatedID: "",

    country: "",
    state: "",
    city: "",
    startDate: "",
    endDate: "",
    // District: "",
  });

  const subscriptionOptions = [
    { value: "Freemium", label: "Freemium" },
    { value: "Premium", label: "Premium" },
  ];
  const TubClassType = apiData?.map((data) => ({
    value: data?._id || "",
    label: data?.name || "",
  }));

  // const handleSubscriptionChange = (selectedOption) => {
  //   setSelectedSubscription(selectedOption);
  //   console.log(selectedOption, "selectedOption");
  // };

  useEffect(() => {
    const Token = localStorage.getItem("accessToken");
    axios
      .get(`${process.env.REACT_APP_DEV_URL}/school-categories`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        setApiData(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [isOpen]);

  const getSchoolListing = async () => {
    let queryPayload = {
      // userName: username,
      limit: 1000,
      page: 1,
    };

    try {
      setLoading(true);
      const responce = await API_ENDPOINTS.getSchool(queryPayload);
      console.log(responce, "responcesssss");
      if (responce) {
        setLoading(false);
        setSchoolList(responce);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getSchoolListing();
  }, []);

  const getCountries = async (screenLoading = true) => {
    try {
      screenLoading && setLoading(true);
      const response = await API_ENDPOINTS.getCountries();
      console.log(response, "responcesssss");
      if (response) {
        const countryOptions = response.map((country) => ({
          value: country.name,
          label: country.name,
          id: country.id,
        }));
        setCountryOptions(countryOptions);
        setIsDisabled(false); // Enable state selection if countries are available
        screenLoading && setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  useEffect(() => {
    getCountries();
  }, []);

  const getStates = async () => {
    if (selectedCountry) {
      let queryPayload = {
        countryId: selectedCountry.id, // Pass the selected country ID
      };
      try {
        setLoading(true);
        const response = await API_ENDPOINTS.getStates(queryPayload);
        console.log(response, "responcesssss");
        if (response) {
          const stateOptions = response.map((state) => ({
            value: state.name,
            label: state.name,
            id: state.id,
          }));
          setStateOptions(stateOptions);
          setIsDisabled(false); // Enable city selection if states are available
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      const getStates = async (screenLoading = true) => {
        let queryPayload = {
          countryId: selectedCountry.id,
        };
        try {
          screenLoading && setLoading(true);
          const response = await API_ENDPOINTS.getStates(queryPayload);
          console.log(response, "responcesssss");
          if (response) {
            const stateOptions = response.map((state) => ({
              value: state.name,
              label: state.name,
              id: state.id,
            }));
            setStateOptions(stateOptions);
            setIsDisabled(false); // Enable state selection if states are available
            screenLoading && setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching states:", error);
        }
      };
      getStates(false);
    }
  }, [selectedCountry]);

  const getCities = async () => {
    if (selectedState) {
      let queryPayload = {
        stateId: selectedState.id, // Pass the selected state ID
      };
      try {
        setLoading(true);
        const response = await API_ENDPOINTS.getCities(queryPayload);
        console.log(response, "responcesssss");
        if (response) {
          const cityOptions = response.map((city) => ({
            value: city.name,
            label: city.name,
          }));
          setCityOptions(cityOptions);
          setIsDisabled(false); // Enable city selection if cities are available
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    }
  };
  useEffect(() => {
    if (selectedState) {
      const getCities = async (screenLoading = true) => {
        let queryPayload = {
          stateId: selectedState.id,
        };
        try {
          screenLoading && setLoading(true);
          const response = await API_ENDPOINTS.getCities(queryPayload);
          console.log(response, "responcesssss");
          if (response) {
            const cityOptions = response.map((city) => ({
              value: city.name,
              label: city.name,
            }));
            setCityOptions(cityOptions);
            setIsDisabled(false); // Enable city selection if cities are available
            screenLoading && setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };
      getCities(false);
    }
  }, [selectedState]);

  const getSchoolDetails = (data) => {
    console.log(data, "updateValue");
    const updatedSchoolIDs = {
      SchoolName: data[2],
      PrincipalFirstName: data[1],
      SeatsPurchased: data[9]?.[0]?.SeatsPurchased,
      // SchoolEmailID: data[3],
      subcription_type: selectedSubscription?.value,
      SchoolAddr1: data[5],
      SchoolAddr2: data[6],
      ZipCode: data[8],
      AffiliatedID: data[7],
      Country: data[10],
      State: data[11],
      City: data[12],
      startDate: data[13]?.startDate || "",
      endDate: data[13]?.endDate || "",
      id: data[4],
    };
    if (updatedSchoolIDs.startDate) {
      updatedSchoolIDs.startDate = updatedSchoolIDs.startDate.split("T")[0];
    }
    if (updatedSchoolIDs.endDate) {
      updatedSchoolIDs.endDate = updatedSchoolIDs.endDate.split("T")[0];
    }

    // Assuming schoolID is a state variable and setSchoolID is a state setter function
    setSchoolID(updatedSchoolIDs);
    // Set selected country, state, and city based on the retrieved data
    const selectedCountryOption = data[10];
    const selectedStateOption = data[11];

    const selectedCityOption = data[12];
    console.log(
      selectedCountryOption,
      selectedStateOption,
      selectedCityOption,
      "<<--- selected options"
    );

    setSelectedCountry(selectedCountryOption);
    setSelectedState(selectedStateOption);
    setSelectedCity(selectedCityOption);

    setModal(true);
    setIsEdit(true);
  };
  const getTableColumns = () => {
    let editObj = {
      label: "Actions",
      name: "",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="ms-1"
              onClick={() => getSchoolDetails(tableMeta?.rowData)}
            >
              <MdEdit size={16} />
            </div>
          </div>
        ),
      },
    };
    let newtable = [...SchoolList, editObj];
    return newtable;
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      SchoolName: schoolId ? schoolId.SchoolName : "",
      SeatsPurchased: schoolId ? schoolId.SeatsPurchased : "",
      // SchoolEmailID: schoolId ? schoolId.SchoolEmailID : "",
      PrincipalFirstName: schoolId ? schoolId.PrincipalFirstName : "",
      SchoolAddr1: schoolId ? schoolId.SchoolAddr1 : "",
      SchoolAddr2: schoolId ? schoolId.SchoolAddr2 : "",
      ZipCode: schoolId ? schoolId.ZipCode : "",
      AffiliatedID: schoolId ? schoolId.AffiliatedID : "",
      startDate: schoolId ? schoolId.startDate : "", // Include startDate here
      endDate: schoolId ? schoolId.endDate : "", // Include endDate here
      // District: schoolId ? schoolId.District : "",
      // Country: schoolId ? schoolId.Country : "",
      // State: schoolId ? schoolId.State : "",
      // City: schoolId ? schoolId.City : "",
    },
    validationSchema: Yup.object({
      SeatsPurchased: Yup.string().required("required"),
      // SchoolEmailID: Yup.string().required("required"),
      PrincipalFirstName: Yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      const data = {
        ...values,
        Country: selectedCountry,
        State: selectedState,
        City: selectedCity,
        subscription_type:
          selectedSubscription?.value !== "Freemium" || "Premium"
            ? selectedSubscription?.value
            : "Freemium",
            categoryId:tubClassType?.value,
      };
      try {
        let res;
        if (isEdit == true) {
          res = await API_ENDPOINTS.UpdateSchool(schoolId.id, data);
        } else {
          res = await API_ENDPOINTS.createSchool(data);
        }

        console.log(res, "responceee");
        getSchoolListing();
        Toast("Success", res.message);
      } catch (error) {}
      validation.resetForm();

      setModal(false);
    },
  });

  const toggle = () => {
    setModal((prevModal) => !prevModal);
    setSchoolID("");
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
    setIsEdit(false);
  };

  return loading ? (
    <></>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="TUB Cards" breadcrumbItem="TUB Card List" />
        <CustomBootstrapTable
          rows={schoolListData}
          columns={getTableColumns()}
          isLoading={false}
          showCreateBtn={true}
          keyField={"_id"}
          createBtnLabel="Add School"
          triggerPopup={toggle}
          getApplicationDetails={(row) => null}
          showStatusFilter={false}
          showDateFilter={false}
          //   history={props.history}
          //   filterList={(...params) => filterList(...params)}
        />
      </Container>
      <ModalContainer
        isOpen={isOpen}
        toggle={() => toggle(false)}
        title={isEdit ? "Edit School" : "Add New School"}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <FormikFormInput
              placeholder="Enter School Name"
              name="SchoolName"
              type="text"
              formGroupClassName="col-6"
              label="School Name"
              labelClassName="col-form-label"
              value={validation.SchoolName}
              onChange={validation.handleChange}
              validation={validation}
              disabled={false}
            />
            <FormikFormInput
              placeholder="Enter Admin Name"
              name="PrincipalFirstName"
              type="text"
              formGroupClassName="col-6"
              label="Admin Name"
              labelClassName="col-form-label"
              value={validation.PrincipalFirstName}
              onChange={validation.handleChange}
              validation={validation}
              disabled={false}
            />
            {/* <FormikFormInput
              placeholder="Enter School Email"
              name="SchoolEmailID"
              type="email"
              formGroupClassName="col-12"
              label="Enter School Email"
              labelClassName="col-form-label"
              value={validation.SchoolEmailID}
              onChange={validation.handleChange}
              validation={validation}
            /> */}
            <FormikFormInput
              placeholder="Enter Seats Purchased"
              name="SeatsPurchased"
              type="text"
              formGroupClassName="col-6"
              label="Seats Purchased"
              labelClassName="col-form-label"
              value={validation.seatsPurchased}
              onChange={validation.handleChange}
              validation={validation}
            />
            <FormikFormInput
              placeholder="Enter School Branch"
              name="AffiliatedID"
              type="text"
              formGroupClassName="col-6"
              label="School Branch"
              labelClassName="col-form-label"
              value={validation.AffiliatedID}
              onChange={validation.handleChange}
              validation={validation}
              disabled={false}
            />
            <FormikFormInput
              placeholder="Select Start Date"
              name="startDate"
              type="date"
              formGroupClassName="col-6"
              label="License Start Date"
              labelClassName="col-form-label"
              value={validation.startDate}
              onChange={validation.handleChange}
              validation={validation}
            />
            <FormikFormInput
              placeholder="Enter End Date"
              name="endDate"
              type="date"
              formGroupClassName="col-6"
              label="License End Date"
              labelClassName="col-form-label"
              value={validation.endDate}
              onChange={validation.handleChange}
              validation={validation}
              disabled={!validation.values.startDate}
              min={validation.values.startDate}
            />
            <div className="row align-items-baseline mt-2">
              <div className="col-6 pe-0">
                <ReactSelect
                  name="subscription"
                  label="Subscription"
                  type="react-select"
                  formGroupClassName="row"
                  labelClassName="col-12"
                  placeholder="Subscription Type"
                  isMulti={false}
                  isClearable={false}
                  closeMenuOnSelect={true}
                  maxLimit={1}
                  value={selectedSubscription}
                  options={subscriptionOptions}
                  onChange={(selectedOption, val) => {
                    setSelectedSubscription(val);
                    console.log(selectedSubscription.value, " selectedOption");
                  }}
                  isSearchable={true} // Enable search
                  // isDisabled={isEdit}
                />
              </div>
              <div className="col-6 ps-4 pe-0">
                <ReactSelect
                  name="TubClassType"
                  label="TubClassType"
                  type="react-select"
                  formGroupClassName="row"
                  labelClassName="col-12"
                  placeholder="TubClass Type"
                  isMulti={false}
                  isClearable={false}
                  closeMenuOnSelect={true}
                  maxLimit={1}
                  value={tubClassType}
                  options={TubClassType}
                  onChange={(selectedOption, val) => {
                    setTubClassType(val);
                  }}
                  isSearchable={true} // Enable search
                  isDisabled={isEdit && TubClassType}
                />
              </div>
            </div>
            <FormikFormInput
              placeholder="Enter SchoolAddr 1"
              name="SchoolAddr1"
              type="text"
              formGroupClassName="col-6"
              label="Street"
              labelClassName="col-form-label"
              value={validation.SchoolAddr1}
              onChange={validation.handleChange}
              validation={validation}
              disabled={false}
            />

            <FormikFormInput
              placeholder="Enter SchoolAddr 2"
              name="SchoolAddr2"
              type="text"
              formGroupClassName="col-6"
              label="street 2/Landmark"
              labelClassName="col-form-label"
              value={validation.SchoolAddr2}
              onChange={validation.handleChange}
              validation={validation}
              disabled={false}
            />
            <div className="row align-items-baseline">
              <div className="col-6">
                <FormikFormInput
                  placeholder="Enter Zip Code"
                  name="ZipCode"
                  type="text"
                  formGroupClassName="col-12"
                  label="Zip Code/PinCode"
                  labelClassName="col-form-label"
                  value={validation.ZipCode}
                  onChange={validation.handleChange}
                  validation={validation}
                  disabled={false}
                />
              </div>
              <div className="col-6 ">
                {/* <FormikFormInput
              placeholder="Enter District"
              name="District"
              type="text"
              formGroupClassName="col-6"
              label="Enter District"
              labelClassName="col-form-label"
              value={validation.District}
              onChange={validation.handleChange}
              validation={validation}
            /> */}
                <ReactSelect
                  name="Country"
                  label="Country"
                  type="react-select"
                  formGroupClassName="row"
                  labelClassName="col-12"
                  placeholder="Country"
                  isMulti={false}
                  isClearable={false}
                  closeMenuOnSelect={true}
                  maxLimit={1}
                  value={selectedCountry}
                  options={countryOptions}
                  onChange={(selectedOption, val) => {
                    setSelectedCountry(val);
                    console.log(selectedCountry, " selectedOption");
                  }}
                  isSearchable={true} // Enable search
                  isDisabled={isEdit}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <ReactSelect
                  name="State"
                  label="State"
                  type="react-select"
                  formGroupClassName="row"
                  labelClassName="col-12"
                  placeholder="State"
                  isMulti={false}
                  isClearable={false}
                  closeMenuOnSelect={true}
                  maxLimit={1}
                  value={selectedState}
                  options={stateOptions}
                  onChange={(selectedOption, val) => {
                    setSelectedState(val);
                  }}
                  isSearchable={true} // Enable search
                  isDisabled={isEdit && selectedCountry}
                />
              </div>
              <div className="col-6">
                <ReactSelect
                  name="City"
                  label="City"
                  type="react-select"
                  formGroupClassName="row"
                  labelClassName="col-12"
                  placeholder="City"
                  isMulti={false}
                  isClearable={false}
                  closeMenuOnSelect={true}
                  maxLimit={1}
                  value={selectedCity}
                  options={cityOptions}
                  onChange={(selectedOption, val) => {
                    setSelectedCity(val);
                  }}
                  isSearchable={true} // Enable search
                  isDisabled={isEdit && selectedCountry}
                />
              </div>
            </div>
            {/* <Col>
              <Select
                options={countryOptions}
                value={selectedCountry}
                onChange={(selectedOption) => {
                  setSelectedCountry(selectedOption);
                }}
                placeholder="Select a country"
                isSearchable={true} 
              />
            </Col>
            <Col>
              <Select
                options={stateOptions}
                value={selectedState}
                onChange={(selectedOption) => {
                  setSelectedState(selectedOption);
                }}
                placeholder="Select a state"
                isSearchable={true}
              />
            </Col>
            <Col>
              <Select
                options={cityOptions}
                value={selectedCity}
                onChange={(selectedOption) => {
                  setSelectedCity(selectedOption);
                }}
                placeholder="Select a city"
                isSearchable={true} 
              />
            </Col>
 */}
          </Row>
          <Row className="align-items-center">
            {/* {isEdit && (
              <Col>
                <div>
                  <p className="danger" onClick={() => save(true)}>
                    Delete Tag
                  </p>
                </div>
              </Col>
            )} */}
            <Col>
              <div className="d-flex justify-content-end">
                <button type="submit" className="px-4 primary_btn">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalContainer>
    </div>
  );
};

export { AddSchool };
