/* CURRICULUMS */
export const GET_CURRICULUMS = "GET_CURRICULUMS"
export const GET_CURRICULUMS_SUCCESS = "GET_CURRICULUMS_SUCCESS"
export const GET_CURRICULUMS_FAIL = "GET_CURRICULUMS_FAIL"

/**
 * add user
 */
export const ADD_NEW_CURRICULUM = "ADD_NEW_CURRICULUM"
export const ADD_CURRICULUM_SUCCESS = "ADD_CURRICULUM_SUCCESS"
export const ADD_CURRICULUM_FAIL = "ADD_CURRICULUM_FAIL"

/**
 * Edit user
 */
export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM"
export const UPDATE_CURRICULUM_SUCCESS = "UPDATE_CURRICULUM_SUCCESS"
export const UPDATE_CURRICULUM_FAIL = "UPDATE_CURRICULUM_FAIL"


/* Reading Progras */
export const GET_READING_PROGRAMS = "GET_READING_PROGRAMS"
export const GET_READING_PROGRAMS_SUCCESS = "GET_READING_PROGRAMS_SUCCESS"
export const GET_READING_PROGRAMS_FAIL = "GET_READING_PROGRAMS_FAIL"

/* READING_PROGRAMS DETAIL */
export const GET_READING_PROGRAM_DETAIL = "GET_READING_PROGRAM_DETAIL"
export const GET_READING_PROGRAM_DETAIL_SUCCESS = "GET_READING_PROGRAM_DETAIL_SUCCESS"
export const GET_READING_PROGRAM_DETAIL_FAIL = "GET_READING_PROGRAM_DETAIL_FAIL"

/**
 * add reading program
 */
export const ADD_NEW_READING_PROGRAM = "ADD_NEW_READING_PROGRAM"
export const ADD_READING_PROGRAM_SUCCESS = "ADD_READING_PROGRAM_SUCCESS"
export const ADD_READING_PROGRAM_FAIL = "ADD_READING_PROGRAM_FAIL"

/**
 * udpate reading program
 */
export const UPDATE_READING_PROGRAM = "UPDATE_READING_PROGRAM"
export const UPDATE_READING_PROGRAM_SUCCESS = "UPDATE_READING_PROGRAM_SUCCESS"
export const UPDATE_READING_PROGRAM_FAIL = "UPDATE_READING_PROGRAM_FAIL"


/**
 * add reading program DATA
 */
export const ADD_NEW_READING_PROGRAM_DATA = "ADD_NEW_READING_PROGRAM_DATA"
export const ADD_NEW_READING_PROGRAM_DATA_SUCCESS = "ADD_NEW_READING_PROGRAM_DATA_SUCCESS"
export const ADD_NEW_READING_PROGRAM_DATA_FAIL = "ADD_NEW_READING_PROGRAM_DATA_FAIL"

/**
 * udpate reading program DATA
 */
export const UPDATE_READING_PROGRAM_DATA = "UPDATE_READING_PROGRAM_DATA"
export const UPDATE_READING_PROGRAM_DATA_SUCCESS = "UPDATE_READING_PROGRAM_DATA_SUCCESS"
export const UPDATE_READING_PROGRAM_DATA_FAIL = "UPDATE_READING_PROGRAM_DATA_FAIL"

// CURRICULUM WISE PROGRAM DETAIL

export const GET_CURRICULUM_READING_PROGRAMS = 'GET_CURRICULUM_READING_PROGRAMS'
export const GET_CURRICULUM_READING_PROGRAMS_SUCCESS = 'GET_CURRICULUM_READING_PROGRAMS_SUCCESS'