import {
  GET_COUPON_DETAIL,
  GET_COUPON_DETAIL_SUCCESS,
  GET_COUPONS,
  GET_COUPONS_SUCCESS,
  ADD_NEW_COUPON,
  ADD_COUPON_SUCCESS,
  UPDATE_COUPON,
  UPDATE_COUPON_SUCCESS,
} from "./actionTypes"

export const getCoupons = (queryPayload) => ({
  type: GET_COUPONS,
  payload: queryPayload
})
export const getCouponsSuccess = Coupons => ({
  type: GET_COUPONS_SUCCESS,
  payload: Coupons,
})


export const addNewCoupon = data => ({
  type: ADD_NEW_COUPON,
  payload: data,
})
export const addCouponSuccess = () => ({
  type: ADD_COUPON_SUCCESS,
  payload: null,
})


export const getCouponDetail = (params) => ({
  type: GET_COUPON_DETAIL,
  payload: params
})
export const getCouponDetailSuccess = data => ({
  type: GET_COUPON_DETAIL_SUCCESS,
  payload: data,
})


export const updateCoupon = data => ({
  type: UPDATE_COUPON,
  payload: data,
})
export const updateCouponSuccess = () => ({
  type: UPDATE_COUPON_SUCCESS,
  payload: null,
})