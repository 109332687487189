import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form,
    Label
} from "reactstrap";
import { API_ENDPOINTS } from "api/endpoints";
import FormInput from "components/FormInput";
import ReactSelect from "components/ReactSelect";
import CreateApplication from 'components/Button/createApplicationBtn'
import BackBtn from "components/Button/backBtn";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import trash from 'assets/images/bxs-trash.svg';
import { useDispatch } from "react-redux";
import classnames from "classnames"
import _ from 'lodash';
import { history } from "helpers/utils";
import { Toast } from "helpers/Toast";
import { MdEdit } from "react-icons/md";
import { CONTENT_RATING_DROPDOWN } from "helpers/variables";
import validate from "helpers/validation";
import { addNewWordiconPack } from "store/wordiconPacks/actions";
import moment from "moment";
import Select from 'react-select'

const RuleDetail = (props) => {
    const { match: { params } } = props

    const [isLoading, setLoading] = useState(false)
    const [isEdit, setEdit] = useState(false)

    const [subscriptionDetail, setDetail] = useState({
        subscrption_Name: null,
        subscription_For: null,
        subscrption_Time_Period: null,
        players_Count: null,
        game_Play_Count: null,
        custom_Word_Creation_Count: null,
        wordicon_Create_Count: null,
        social_Circle_Count: null,
        free_Packs_Count: null,
        discount_On_MarketPlace: null,
    })
    const [errors, setErrors] = useState({
        subscrption_Name: null,
        subscription_For: null,
        subscrption_Time_Period: null,
        players_Count: null,
        game_Play_Count: null,
        custom_Word_Creation_Count: null,
        wordicon_Create_Count: null,
        social_Circle_Count: null,
        free_Packs_Count: null,
        discount_On_MarketPlace: null,
    })

    useEffect(() => {
        getApis()
    }, []);

    const getApis = async () => {
        setLoading(true)
        if (params && params.id && !isEdit) {
            await getOnePackDetail(params.id)
        }
        setLoading(false)
    }

    const getOnePackDetail = async (id) => {
        try {
            const res = await API_ENDPOINTS.getSubscriptionRuleDetail(id)
            if (res && !_.isEmpty(res)) {
                setEdit(true)
                setDetail({
                    ...res
                })
            }
        } catch (error) {
        }
    }

    const onChange = (name, value) => {
            setDetail({ ...subscriptionDetail, [name]: value })
            setErrors({ ...errors, [name]: undefined })
    }

    const savePackDetail = async () => {
        let validation = {
            ...inputValidations
        }
        const notValid = validate(subscriptionDetail, validation);
        if (notValid) {
            setErrors(notValid)
        } else {
            let payload = {
                ...subscriptionDetail,
                Createdby: localStorage.getItem('username'),
            }
            if (isEdit) {
                payload["_id"] = subscriptionDetail._id
            }
            let res = await API_ENDPOINTS.createOrEditSubscriptionRule({ body: payload })
            setDetail({
                ...subscriptionDetail,
                ...res
            })
        }
    }

    const deleteSubscriptionRule = async () => {
        let payload = {
            body: {
                ...subscriptionDetail,
                isDeleted: true,
            }
        }
        let res = await API_ENDPOINTS.createOrEditSubscriptionRule(payload)
        history.goBack()
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (
                        <div>...Data Loading.....</div>
                    ) :
                        <>
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box d-flex">
                                        <BackBtn history={props.history} />
                                        <h4 className="mb-0 font-size-18 px-2">{isEdit ? "Edit Subscription rule" : 'Add new Subscription rule PACK'}</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <div className="content clearfix mt-4">
                                                <FormInput
                                                    placeholder="Enter Subscription Name"
                                                    name="subscrption_Name"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Subscription Name'
                                                    maxLength={70}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['subscrption_Name']}
                                                    error={errors?.subscrption_Name}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    placeholder="Enter Subscription For"
                                                    name="subscription_For"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Subscription For'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['subscription_For']}
                                                    error={errors?.subscription_For}
                                                    onChange={(...params) => onChange(...params)}
                                                />

                                                <FormInput
                                                    placeholder="Subscription Time Period"
                                                    name="subscrption_Time_Period"
                                                    type='number'
                                                    formGroupClassName="row"
                                                    label='Subscription Time Period'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['subscrption_Time_Period']}
                                                    error={errors?.subscrption_Time_Period}
                                                    onChange={(...params) => onChange(...params)}
                                                />

                                                <FormInput
                                                    placeholder="Players Count"
                                                    name="players_Count"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Players Count'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['players_Count']}
                                                    error={errors?.players_Count}
                                                    onChange={(...params) => onChange(...params)}
                                                />

                                                <FormInput
                                                    placeholder="Game Play Count"
                                                    name="game_Play_Count"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Game Play Count'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['game_Play_Count']}
                                                    error={errors?.game_Play_Count}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    placeholder="Custom Word Creation Count"
                                                    name="custom_Word_Creation_Count"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Custom Word Creation Count'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['custom_Word_Creation_Count']}
                                                    error={errors?.custom_Word_Creation_Count}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    placeholder="Wordicon Create Count"
                                                    name="wordicon_Create_Count"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Wordicon Create Count'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['wordicon_Create_Count']}
                                                    error={errors?.wordicon_Create_Count}
                                                    onChange={(...params) => onChange(...params)}
                                                />

                                                <FormInput
                                                    placeholder="Social Circle Count"
                                                    name="social_Circle_Count"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Social Circle Count'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['social_Circle_Count']}
                                                    error={errors?.social_Circle_Count}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    placeholder="Free Pack Count"
                                                    name="free_Packs_Count"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Free Pack Count'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['free_Packs_Count']}
                                                    error={errors?.free_Packs_Count}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    placeholder="Discount"
                                                    name="discount_On_MarketPlace"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Discount'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['discount_On_MarketPlace']}
                                                    error={errors?.discount_On_MarketPlace}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    name="share_Content"
                                                    formGroupClassName="row"
                                                    label='Share Content'
                                                    type={"two-way-switch"}
                                                    leftLabel="Inactive"
                                                    rightLabel="Active"
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={subscriptionDetail['share_Content']}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                {/* <FormInput
                                                            name="status"
                                                            formGroupClassName="row"
                                                            label='Status'
                                                            type={"two-way-switch"}
                                                            leftLabel="Inactive"
                                                            rightLabel="Active"
                                                            labelClassName="col-md-2 col-form-label"
                                                            value={subscriptionDetail['status']}
                                                            onChange={(...params) => onChange(...params)}
                                                        /> */}
                                                <div className="d-flex justify-flex-end">
                                                    <button
                                                        type="submit"
                                                        className="primary_btn create_button"
                                                        onClick={() => savePackDetail()}
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {isEdit && !subscriptionDetail.isPublished &&
                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <p
                                                        className="danger"
                                                        onClick={() => deleteSubscriptionRule()}
                                                    >Delete Pack</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </>}
                </Container>
            </div>
        </React.Fragment >
    )
}

export { RuleDetail }

let inputValidations = {
    subscrption_Name: {
        presence: {
            allowEmpty: false,
            message: "^Subscrption Name can't be blank"
        }
    },
    subscription_For: {
        presence: {
            allowEmpty: false,
            message: "^Subscription For can't be blank"
        }
    },
    subscrption_Time_Period: {
        presence: {
            allowEmpty: false,
            message: "^Subscrption Time Period can't be blank"
        }
    },
    players_Count: {
        presence: {
            allowEmpty: false,
            message: "^players count can't be blank"
        }
    },
    game_Play_Count: {
        presence: {
            allowEmpty: false,
            message: "^Game Play Count can't be blank"
        }
    },
    custom_Word_Creation_Count: {
        presence: {
            allowEmpty: false,
            message: "^Custom Word Creation Count can't be blank"
        }
    },
    wordicon_Create_Count: {
        presence: {
            allowEmpty: false,
            message: "^Wordicon Create  Count can't be blank"
        }
    },
    social_Circle_Count: {
        presence: {
            allowEmpty: false,
            message: "^Social Circle Count can't be blank"
        }
    },
    free_Packs_Count: {
        presence: {
            allowEmpty: false,
            message: "^Free Packs Count can't be blank"
        }
    },
    discount_On_MarketPlace: {
        presence: {
            allowEmpty: false,
            message: "^Discount can't be blank"
        }
    }
}