import {
  GET_TUB_SHEETS_SUCCESS,
  GET_TUB_SHEET_DETAIL_SUCCESS,

} from "./actionTypes"

const INIT_STATE = {
  tubSheetList: [],
  tubSheetDetail: {},
  error: {},
}

const tubSheet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TUB_SHEETS_SUCCESS:
      return {
        ...state,
        tubSheetList: action.payload,
      }

    case GET_TUB_SHEET_DETAIL_SUCCESS:
      return {
        ...state,
        tubSheetDetail: action.payload,
      }


    default:
      return state
  }
}

export default tubSheet
