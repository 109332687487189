import React, { useEffect } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
import { TubsheetListColumn } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import { getTubSheets } from "store/actions";
import { MdEdit } from "react-icons/md";

const TubsheetList = (props) => {
  const dispatch = useDispatch();

  const { tubSheetList } = useSelector((state) => ({
    tubSheetList: state.tubSheet.tubSheetList,
  }));

  useEffect(() => {
    let queryPayload = {
      ObjectType: "Sheet",
    };
    dispatch(getTubSheets(queryPayload));
  }, [dispatch, props]);

  const getApplicationDetails = (row) => {
    console.log(row, "row");
    props.history.push(`/staff/sheet/detail/${row[6]}`);
  };
  const filterList = (queryPayload) => {
    let payload = {
      ...queryPayload,
      ObjectType: "Sheet",
      // curriculum_Id: props.match.params.curriculumId
    };
    dispatch(getTubSheets(payload));
  };

  const getTableColumns = () => {
    let editObj = {
      label: "Actions",
      name: "",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="ms-1"
              onClick={() => getApplicationDetails(tableMeta?.rowData, true)}
            >
              <MdEdit size={16} />
            </div>
          </div>
        ),
      },
    };
    let newtable = [...TubsheetListColumn, editObj];
    return newtable;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="TUB Sheets" breadcrumbItem="TUB Sheet List" />
          <CustomBootstrapTable
            rows={tubSheetList}
            columns={getTableColumns()}
            isLoading={false}
            showCreateBtn={true}
            keyField={"_id"}
            createBtnLabel="Create New TUB Sheet"
            onCreateRedirect={`/staff/sheet/detail`}
            getApplicationDetails={(row) => null}
            showStatusFilter={false}
            showDateFilter={false}
            history={props.history}
            filterList={(...params) => filterList(...params)}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export { TubsheetList };
