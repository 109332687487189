import axios from "axios";
// import imageCompression from "browser-image-compression";
// var fs = require('browserfs');

let currentPrintImageIndex = 0
export function getPosTranslationPoint(itemsSize, canvasWidth, canvasHeight, currentIndex) {
    if (itemsSize == 9) {
        let x = canvasWidth / 3;
        let y = canvasHeight / 3;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: x, yAxis: 0 }
            case 2: return { xAxis: x + x, yAxis: 0 }
            case 3: return { xAxis: 0, yAxis: y }
            case 4: return { xAxis: x, yAxis: y }
            case 5: return { xAxis: x + x, yAxis: y }
            case 6: return { xAxis: 0, yAxis: y + y }
            case 7: return { xAxis: x, yAxis: y + y }
            case 8: return { xAxis: x + x, yAxis: y + y }
        }
    }

    if (itemsSize == 8) {
        const x = canvasWidth / 3;
        const y = canvasHeight / 3;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: x, yAxis: 0 }
            case 2: return { xAxis: 0, yAxis: y }
            case 3: return { xAxis: x, yAxis: y }
            case 4: return { xAxis: 0, yAxis: y + y }
            case 5: return { xAxis: x, yAxis: y + y }
            case 6: return { xAxis: x + x, yAxis: 0 }
            case 7: return { xAxis: x + x, yAxis: canvasHeight / 2 }
        }
    }

    if (itemsSize == 7) {
        const x = canvasWidth / 2;
        const x3 = canvasWidth / 3;
        const y = canvasHeight / 3;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: x, yAxis: 0 }
            case 2: return { xAxis: 0, yAxis: y }
            case 3: return { xAxis: x, yAxis: y }
            case 4: return { xAxis: 0, yAxis: y + y }
            case 5: return { xAxis: x3, yAxis: y + y }
            case 6: return { xAxis: x3 + x3, yAxis: y + y }
        }
    }

    if (itemsSize == 6) {
        const x = canvasWidth / 3;
        const y = canvasHeight / 2;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: x, yAxis: 0 }
            case 2: return { xAxis: x + x, yAxis: 0 }
            case 3: return { xAxis: 0, yAxis: y }
            case 4: return { xAxis: x, yAxis: y }
            case 5: return { xAxis: x + x, yAxis: y }
        }
    }

    if (itemsSize == 5) {
        const x3 = canvasWidth / 3;
        const x = canvasWidth / 2;
        const y = canvasHeight / 2;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: x, yAxis: 0 }
            case 2: return { xAxis: 0, yAxis: y }
            case 3: return { xAxis: x3, yAxis: y }
            case 4: return { xAxis: x3 + x3, yAxis: y }
        }
    }

    if (itemsSize == 4) {
        const x = canvasWidth / 2;
        const y = canvasHeight / 2;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: x, yAxis: 0 }
            case 2: return { xAxis: 0, yAxis: y }
            case 3: return { xAxis: x, yAxis: y }
        }
    }

    if (itemsSize == 3) {
        const x = canvasWidth / 2;
        const y = canvasHeight / 2;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: x, yAxis: 0 }
            case 2: return { xAxis: x, yAxis: y }
        }
    }

    if (itemsSize == 2) {
        const x = canvasWidth / 2;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: x, yAxis: 0 }
        }
    }
    if (itemsSize == 1) {
        const x = canvasWidth / 2;
        switch (currentIndex) {
            case 0: return { xAxis: 0, yAxis: 0 }
            case 1: return { xAxis: 0, yAxis: 0 }
        }
    }


}

export function getImageHeight(itemsSize, canvasHeight, currentIndex) {
    let x = 0;
    if (itemsSize == 9 || itemsSize == 7) {
        x = (canvasHeight / 3) - 1;
    }

    if (itemsSize == 8) {
        const spacing = 120;
        switch (currentIndex) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                x = (canvasHeight / 3) - 60;
                break;
            case 6:
            case 7:
                x = (canvasHeight / 2) - 60;
                break;
            default:
                x = spacing
                break;
        }
    }

    if (itemsSize == 6) {
        x = (canvasHeight / 2) - 1;
    }

    if (itemsSize == 5 || itemsSize == 4) {
        x = (canvasHeight / 2) - 1;
    }

    if (itemsSize == 3) {
        const spacing = 120;
        switch (currentIndex) {
            case 0:
                x = canvasHeight;
                break;
            case 1:
                x = (canvasHeight / 2) - spacing;
                break;
            case 2:
                x = (canvasHeight / 2);
                break;
            default:
                x = spacing;
                break;
        }
    }

    if (itemsSize == 2) {
        x = canvasHeight;
    }

    return x;
}

export function getImageWidth(itemsSize, canvasWidth, currentIndex) {
    let x = 0;
    if (itemsSize == 9 || itemsSize == 8) {
        x = (canvasWidth / 3) - 1;
    }

    if (itemsSize == 7) {
        switch (currentIndex) {
            case 0:
            case 1:
            case 2:
            case 3:
                x = (canvasWidth / 2) - 1;
                break;
            case 4:
            case 5:
            case 6:
                x = (canvasWidth / 3) - 2;
                break;
        }
    }

    if (itemsSize == 6) {
        x = (canvasWidth / 3) - 1;
    }

    if (itemsSize == 5) {
        switch (currentIndex) {
            case 0:
            case 1:
                x = (canvasWidth / 2) - 1;
                break;
            case 2:
            case 3:
            case 4:
                x = (canvasWidth / 3) - 1;
                break;
        }
    }

    if (itemsSize == 4 || itemsSize == 3 || itemsSize == 2) {
        x = (canvasWidth / 2) - 1;
    }

    return x;
}

export async function renderSheetName(parentCanvas, canvas, sheetName) {
    // const base_image = new CanvasImage(canvas);
    const base_image = canvas
    await getBase64Image('https://alphatubnew.s3.us-west-2.amazonaws.com/sheet_title_image+(1).png', (base64Image) => {
        console.log('9th', base64Image)
        base_image.src = base64Image;
        base_image.crossOrigin = 'Anonymous';
        base_image.addEventListener('load', async () => {
            parentCanvas.drawImage(base_image, 0, 0, 784 / 10, 848 / 10);
            parentCanvas.font = '40 px '  //+ .PopLight;
            parentCanvas.textAlign = 'left';
            parentCanvas.textBaseline = 'top';
            parentCanvas.fillStyle = 'black';
            let requiredName = sheetName.length >= 11 ? sheetName.slice(0, 10) + '...' : sheetName
            parentCanvas.fillText(requiredName, 7, 52);
        })
    });
}

export async function renderSheetImagesToCanvas(parentCanvas, canvas, item, completeListener) {

    var images = {};
    // images = canvas
    // images.src = item;
    // images.crossOrigin = 'Anonymous';
    // console.log('images',images)
    // const image = new window.Image();
    // image.onload = () => {
    //     parentCanvas.drawImage(
    //         item,
    //         item.xCoordinate / 10,
    //         item.yCoordinate / 10,
    //         item.width / 10,
    //         item.height / 10,
    //     );
    //     completeListener(parentCanvas)
    // }

    await getBase64Image(item.imageUrl, (base64Image) => {
        console.log('base64Image', base64Image)
        images.src = base64Image;
        images.crossOrigin = 'Anonymous';

        const image = new window.Image();
        image.onload = () => {
            parentCanvas.drawImage(
                images,
                item.xCoordinate / 10,
                item.yCoordinate / 10,
                item.width / 10,
                item.height / 10,
            );

            console.log('parentCanvas', parentCanvas)
            completeListener()
        }
    });

}

function convert(oldImag) {
    var img = new Image();
    img.onload = function () {
        return img
    }
    // img.setAttribute('crossorigin', 'anonymous');
    img.src = oldImag;
    console.log('7th', img)
    return img
}

function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export const getBase64Image = async (imgUrl, callback) => {
    fetch(imgUrl)
        .then(res => res.blob())
        .then(blob => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                callback(base64String)
                // Do something with the base64 string, such as rendering an image
            };
            reader.readAsDataURL(blob);
        });
    // const reader = new FileReader();
    // reader.onload = () => {
    //     const base64String = reader.result;
    //     console.log("base64String",base64String);
    //     // Do something with the base64 string, such as rendering an image
    // };
    // reader.readAsDataURL(blob);
    // let img = dataURLtoBlob(imgUrl)
    // console.log('img', img)
    // const data = await fetch(img);
    // var img = new Image();

    // onload fires when the image is fully loadded, and has width and height
    // img.crossOrigin =  'anonymous' //

    // img.onload = function(){

    //   var canvas = document.createElement("canvas");
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   var ctx = canvas.getContext("2d");
    //   ctx.drawImage(img, 0, 0);
    //   var dataURL = canvas.toDataURL("image/png"),
    //     dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    //     console.log('dataURL',dataURL)
    //   callback(dataURL); // the base64 string

    // };
    // img.onerror = function() {
    //     reject("The image could not be loaded.");
    //   }

    // set attributes and src 
    // img.src = imgUrl;

    // var canvas = document.createElement('canvas');
    // console.log('img',img)
    // canvas.width = img.width;
    // canvas.height = img.height;
    // var ctx = canvas.getContext("2d");
    // ctx.drawImage(img, 0, 0);
    // var dataURL = canvas.toDataURL("image/png");
    // return dataURL.replace(/^data:image\/?[A-z]*;base64,/);

    // return new Promise(function (response, reject) {
    //     const fs = RNFetchBlob.fs;
    //     let imagePath = null;
    //     let base64Img = null;
    //     RNFetchBlob.config({
    //         fileCache: true,
    //     })
    //         .fetch('GET', img)
    //         // the image is now downloaded to device's storage
    //         .then(resp => {
    //             // the image path you can use it directly with Image component
    //             imagePath = resp.path();
    //             return resp.readFile('base64');
    //         })
    //         .then(base64Data => {
    //             // here's base64 encoded image
    //             base64Img = base64Data;
    //             // remove the file from storage
    //             response('data:image/jpeg;base64,' + base64Img)
    //             return fs.unlink(imagePath);
    //         })
    //         .catch(error => {
    //             console.log('base 64  issue => ', error);
    //             reject(error)
    //         })

    // })

};

export async function convertCanvasToImage(canvas, canvasWidth, canvasHeight) {
    return new Promise(async function (response, reject) {
        // const RNFS = fs
        let base64ImageData = await canvas.toDataURL()
        const imagePath = '/card_image.jpeg'
        // let fileExist = await RNFS.exists(imagePath)
        // if (fileExist) {
        //     RNFS.unlink(imagePath)
        // }
        console.log('base64ImageData', base64ImageData)
        // fs.writeFile(imagePath, base64ImageData?.split(',')[1], 'base64')
        //     .then(() => {
        //         const options = {
        //             maxSizeMB: 2,
        //             maxWidthOrHeight: 800,
        //             useWebWorker: true
        //         };
        //         let output;
        //         imageCompression(imagePath, options).then(x => {
        //             output = x;

        //             const downloadLink = URL.createObjectURL(output);
        //             response(downloadLink)
        //           });
        //     })
        //     .catch(error => {
        //         reject(error)
        //         // SimpleToast.show('Not able to write file. Please check your permissions and try again.')
        //     })
    })
}