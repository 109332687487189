import {
  GET_DEALS_SUCCESS,
  GET_DEAL_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  dealsList: [],
  dealDetail: {},
  error: {},
}

const deal = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DEALS_SUCCESS:
      return {
        ...state,
        dealsList: action.payload,
      }
    case GET_DEAL_DETAIL_SUCCESS:
      return {
        ...state,
        dealDetail: action.payload,
      }


    default:
      return state
  }
}

export default deal
