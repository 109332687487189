import moment from 'moment'

export const formatDate = (format, value) => {
    const finalDate = moment(value).format(format);
    return finalDate === 'Invalid date' ? '' : finalDate;
}

export function validateUploadSize(file) {
    if (!file) return false
    let FileSize = file.files[0].size / 1024 / 1024; // in MB
    return FileSize <= 5
}