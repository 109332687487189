import { call, put, take, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TAGS, ADD_TAG_SUCCESS , ADD_NEW_TAG , UPDATE_TAG } from "./actionTypes"

import {
  getTagsSuccess,
  getTagFail,
  addTagFail,
  addTagSuccess,
  updateTagSuccess,
  updateTagFail,
  getTags,
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"

function* fetchTags(data) {
  try {
    const response = yield call(API_ENDPOINTS.getTags, {queryStringParameters: data.payload})
    yield put(getTagsSuccess(response))
  } catch (error) {
    yield put(getTagFail(error))
  }
}

function* onUpdateTag(data) {
  try {
    const response = yield call(API_ENDPOINTS.createOrEditTag, {body: data.payload})
  } catch (error) {
    yield put(updateTagFail(error))
  }
}

function* createOrEditTag(data) {
  try {
    const response = yield call(API_ENDPOINTS.createOrEditTag, {body: data.payload})

    yield put(getTags())
    
  } catch (error) {
    yield put(addTagFail(error))
  }
}

function* tagsSaga() {
  yield takeEvery(GET_TAGS, fetchTags)
  yield takeEvery(ADD_TAG_SUCCESS, fetchTags)
  yield takeEvery(ADD_NEW_TAG, createOrEditTag)
  yield takeEvery(UPDATE_TAG, onUpdateTag)
}

export default tagsSaga;
