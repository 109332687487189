import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    BreadcrumbItem,
    CardTitle,
    Container,
} from "reactstrap";
import CreateApplication from 'components/Button/createApplicationBtn'
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import {  CurriculumWiseReadingProgramsColumn } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import { getCurriculumReadingPrograms } from "store/actions";
import ReactSelect from "react-select";
import { ModalContainer } from "components/Modal";
import { API_ENDPOINTS } from "api/endpoints";
import _ from 'lodash';
import { Toast } from "helpers/Toast";
import BackBtn from "components/Button/backBtn";
import { MdDelete ,MdOutlineRemoveRedEye} from "react-icons/md";
import { history } from "helpers/utils";

const CurriculumReadingProgramList = (props) => {
    const dispatch = useDispatch();
    const { match: {params} } = props

    const [isOpenDeletePopup, setDeleteModal] = useState(false)
    const [isOpen, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [readingProgramsDropdown, setReadingPrDropdown] = useState([])
    const [ deleteData, setDeleteData] = useState({
        _id: '',
        isDeleted: true
    })
    const [mappingData, setMappingData] = useState({
        _id: null,
        selectedReadingPrograms: null
    })
    const { curriculumReadingProgramsList } = useSelector(state => ({
        curriculumReadingProgramsList: state.curriculums.curriculumReadingProgramsList,
    }));
    
    useEffect(() => {
        getReadingProgramsList()
        getCurriculumReadingProgramsList()
    }, [dispatch, props]);

    const getCurriculumReadingProgramsList = () => {
        let queryPayload = {
            search: '',
            curriculum_Id: params?._id
        }
        dispatch(getCurriculumReadingPrograms(queryPayload));
    }
    const getReadingProgramsList = async () => {
        let res = await API_ENDPOINTS.getReadingPrograms(null)
        if (!_.isEmpty(res)) {
            let wordsd = res.map(r => ({ label: r.program_Name, value: r._id }))
            setReadingPrDropdown(wordsd)
        } else {
            Toast('error', 'Reading Programs are empty')
        }
    }

    const getApplicationDetails = (row, edit) => {
        setIsEdit(edit)
        if(edit){
            setMappingData({
                ...mappingData,
                _id: row[1],
                selectedReadingPrograms: {
                    label: row[3]?.props?.children,
                    value: row[1]
                }
            })
        }
        setModal(true)
        
    }
    const filterList = (queryPayload) => {
        let payload = {
            ...queryPayload,
            curriculum_Id: params._id
        }
        dispatch(getCurriculumReadingPrograms(payload))
    }
    const mapReadingProgram = async () => {
        if(!_.isEmpty(mappingData['selectedReadingPrograms'])){
            let body = {
                curriculum_Id: params._id,
                reading_Program_Id: mappingData['selectedReadingPrograms']?.value,
                createdBy: localStorage.getItem('username')
            }
            if(isEdit){
                body = {
                    ...body,
                    _id: mappingData["_id"]
                }
            }
            let payload = {
                body: body
            }
            let res = await API_ENDPOINTS.mapCurriculumToReadingProgram(payload)
            if(res){
                Toast('success', 'Success')
                setModal(false)
                getCurriculumReadingProgramsList()
            }
        } else {
            Toast('error', 'Please select Reading Program')
        }
    }
    const onChangeSelect = (name, value) => {
        setMappingData({
            ...mappingData,
            selectedReadingPrograms: value
        })
    }

    const setDeletePopup = (row) => {
        let payload = {
            _id: row[6],
            isDeleted: true
        }
        setDeleteModal(true)
        setDeleteData({...deleteData, ...payload})
    }

    const redirectToProgramDetail = (row) => {
        history.push(`/staff/readingprogram/detail/${row[1]}`)
    }
    const getTableColumns = () => {
        let editObj = {
            label: "Actions",
            name: '',
            options: { 
                filter: true,
                sort: false, 
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className='d-flex align-items-center justify-content-center'>
                        <div
                            onClick={() => redirectToProgramDetail(tableMeta?.rowData)}
                        >
                            <MdOutlineRemoveRedEye size={18}/>
                        </div>
                        <div
                            onClick={() => setDeletePopup(tableMeta?.rowData)}
                        >
                            <MdDelete size={18}/>
                        </div>
                    </div>
                )
            }
        }
        let newtable = [...CurriculumWiseReadingProgramsColumn,editObj]
         return newtable
    }

    const deleteReadingPr = async () => {
        let payload = {
            body: {
                ...deleteData
            }
        }
        let res = await API_ENDPOINTS.mapCurriculumToReadingProgram(payload)
        Toast('success', 'Program Deleted Successfully')
        setDeleteModal(false)
        getCurriculumReadingProgramsList()
    }

    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-space-between">
                        <div className="d-flex  align-items-center ">
                            <BackBtn history={props.history} />
                            <div className="mx-2">
                                <CardTitle>{curriculumReadingProgramsList?.curriculumDetail?.curriculum_Name}</CardTitle>
                            </div>
                        </div>
                        <div className="page-title-box">
                            <div className="page-title-right d-none d-lg-block">
                                <ol className="breadcrumb m-0">
                                <BreadcrumbItem>
                                    <Link to="#">{curriculumReadingProgramsList?.curriculumDetail?.curriculum_Name}</Link>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    <Link to="#">{"Reading Programs"}</Link>
                                </BreadcrumbItem>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <CustomBootstrapTable 
                        rows = {curriculumReadingProgramsList?.list}
                        columns = {getTableColumns()}
                        isLoading={false}
                        keyField={'_id'}
                        showCreateBtn = {true}
                        createBtnLabel="Assign Reading Program"
                        onCreateRedirect = {null}
                        getApplicationDetails={(row) => null}
                        triggerPopup = {() => getApplicationDetails(null, false)}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                        filterList = {(...params) => filterList(...params)}
                    />
                </Container>
            </div>
            <ModalContainer
                isOpen={isOpen}
                toggle={() => setModal(false)}
                title={'Assign Reading Program'}
            >
               <ReactSelect
                    name="readingProgramList"
                    formGroupClassName="mb-3"
                    placeholder="search & assign Reading Program"
                    isMulti={false}
                    isClearable = {false}
                    value={mappingData['selectedReadingPrograms']}
                    options={readingProgramsDropdown}
                    closeMenuOnSelect = {true}
                    onChange={(value) => onChangeSelect('readingProgramList', value)}
                />
                
                <div className='d-flex justify-content-end mt-2'>
                    <button
                        type="submit"
                        onClick={() => mapReadingProgram()}
                        className="px-4 primary_btn"
                    >
                        Save
                    </button>
                </div>
                        
            </ModalContainer>
            <ModalContainer
                isOpen={isOpenDeletePopup}
                toggle={() => setDeleteModal(false)}
                title={'Reading Program'}
            >
                <div>
                    <p>Are you sure you want to delete this Reading Program ?</p>
                </div>
                <div className="d-flex justify-content-end">
                    <CreateApplication
                        onCreateApplicationBtnClick={() => deleteReadingPr()}
                        btnName="Confirm"
                        btnClassname="px-4 mx-4 text-white primary_btn"
                        disableCreateApp={false}
                    />
                </div>
            </ModalContainer>
        </React.Fragment>
    )
}

export { CurriculumReadingProgramList }