import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BreadcrumbItem, CardTitle, Container } from "reactstrap";
import CreateApplication from "components/Button/createApplicationBtn";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
import {
  CurriculumWiseReadingProgramsColumn,
  WordiconPacksDetailListColumn,
} from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import {
  getCurriculumReadingPrograms,
  getWordiconPackDetailList,
} from "store/actions";
import ReactSelect from "react-select";
import { ModalContainer } from "components/Modal";
import { API_ENDPOINTS } from "api/endpoints";
import _ from "lodash";
import { Toast } from "helpers/Toast";
import BackBtn from "components/Button/backBtn";
import { MdEdit } from "react-icons/md";
import { history } from "helpers/utils";
import FormInput from "components/FormInput";

const PackDetailList = (props) => {
  const dispatch = useDispatch();
  const {
    match: { params },
  } = props;

  const [isOpenDeletePopup, setDeleteModal] = useState(false);
  const [isOpen, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [tubSheetDropdown, setSheetDropdown] = useState([]);
  const [tubCardDropdown, setCardDropdown] = useState([]);
  const [deleteData, setDeleteData] = useState({
    _id: "",
    isDeleted: true,
  });
  const [mappingData, setMappingData] = useState({
    _id: null,
    selectedContentType: null,
  });
  const [contentType, setContentType] = useState(null);
  const { packDetailList } = useSelector((state) => ({
    packDetailList: state.wordiconPack.packDetailList,
  }));

  useEffect(() => {
    getApis();
  }, [dispatch, props]);

  const getApis = async () => {
    setLoading(true);
    await getPackDetailList();
    await getContentTypesListDropdown("Sheet");
    await getContentTypesListDropdown("Card");
    setLoading(false);
  };
  const getPackDetailList = async () => {
    dispatch(getWordiconPackDetailList(params?._id));
  };

  const getContentTypesListDropdown = async (objectType) => {
    let queryPayload = {
      ObjectType: objectType,
    };
    let res = await API_ENDPOINTS.getTubSheets({
      queryStringParameters: queryPayload,
    });
    if (!_.isEmpty(res)) {
      let arrs = [];
      res.map((r) => {
        if (r.isActive) {
          arrs.push({ label: r.ObjectName, value: r._id });
        }
      });
      if (objectType == "Sheet") {
        setSheetDropdown(arrs);
      } else {
        setCardDropdown(arrs);
      }
      //
    }
  };

  const getApplicationDetails = (row, edit) => {
    setIsEdit(edit);
    if (edit) {
      setMappingData({
        ...mappingData,
        _id: row[1],
        selectedContentType: {
          label: row[3]?.props?.children,
          value: row[1],
        },
      });
    }
    setModal(true);
  };
  const filterList = (queryPayload) => {
    let payload = {
      ...queryPayload,
      curriculum_Id: params._id,
    };
    dispatch(getCurriculumReadingPrograms(payload));
  };
  const mapContentToPack = async () => {
    if (!_.isEmpty(mappingData["selectedContentType"])) {
      let body = {
        tub_PackId: params._id,
        tub_ObjectId: mappingData["selectedContentType"]?.value,
        AWS_UserName: localStorage.getItem("username"),
        status: "Active",
        tub_ObjectType: contentType === "1" ? "Sheet" : "Card",
      };
      if (isEdit) {
        body = {
          ...body,
          _id: mappingData["_id"],
        };
      }
      let payload = {
        body: body,
      };
      let res = await API_ENDPOINTS.addContentsToPack(payload);
      if (res) {
        setModal(false);
        setContentType("");
        setMappingData({ ...mappingData, _id: "", selectedContentType: null });
        getPackDetailList();
      }
    } else {
      Toast("error", "Please select Reading Program");
    }
  };
  const onChangeSelect = (name, value) => {
    setMappingData({
      ...mappingData,
      selectedContentType: value,
    });
  };

  const setDeletePopup = (row) => {
    let payload = {
      _id: row[6],
      isDeleted: true,
    };
    setDeleteModal(true);
    setDeleteData({ ...deleteData, ...payload });
  };

  const redirectToProgramDetail = (row) => {
    console.log(row, "row");
    let path = null;
    if (row[3] == "Card") {
      path = `/staff/card/detail/${row[7]}`;
    } else if (row[3] === "Sheet") {
      path = `/staff/sheet/detail/${row[7]}`;
    }

    history.push(path);
  };
  const getTableColumns = () => {
    let editObj = {
      label: "Actions",
      name: "",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="d-flex align-items-center justify-content-center">
            <div onClick={() => redirectToProgramDetail(tableMeta?.rowData)}>
              <MdEdit size={16} />
            </div>
          </div>
        ),
      },
    };
    let newtable = [...WordiconPacksDetailListColumn, editObj];
    return newtable;
  };

  const deleteReadingPr = async () => {
    let payload = {
      body: {
        ...deleteData,
      },
    };
    let res = await API_ENDPOINTS.mapCurriculumToReadingProgram(payload);
    Toast("success", "Program Deleted Successfully");
    setDeleteModal(false);
    getPackDetailList();
  };

  const redirect = (url) => {
    history.push(url);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex justify-space-between">
            <div className="d-flex  align-items-center ">
              <BackBtn history={props.history} />
              <div className="mx-2">
                <CardTitle>{packDetailList?.pack_Name}</CardTitle>
              </div>
            </div>
            <div className="page-title-box">
              <div className="page-title-right d-none d-lg-block">
                <ol className="breadcrumb m-0">
                  <BreadcrumbItem>
                    <Link to="#">Wordicon Packs</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>
                    <Link to="#">{packDetailList?.pack_Name}</Link>
                  </BreadcrumbItem>
                </ol>
              </div>
            </div>
          </div>
          <CustomBootstrapTable
            rows={packDetailList?.data}
            columns={getTableColumns()}
            isLoading={false}
            keyField={"_id"}
            showCreateBtn={false}
            showCustomComponent={true}
            customComponent={
              <div className="col-lg-7">
                <div className="row justify-flex-end flex-wrap gap-2">
                  <div className="col-md-3 col-12">
                    <FormInput
                      placeholder="Add Existing Content"
                      name="contentType"
                      type="select"
                      className="custom_list_select"
                      labelClassName="col-md-2 col-form-label"
                      value={contentType}
                      options={[
                        {
                          label: "Add Existing TUB Sheet",
                          value: "1",
                        },
                        {
                          label: "Add Existing TUB Cards",
                          value: "2",
                        },
                      ]}
                      error={""}
                      onChange={(...params) => {
                        setContentType(params[1]);
                        setModal(true);
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <CreateApplication
                      onCreateApplicationBtnClick={() =>
                        redirect("/staff/card/detail")
                      }
                      btnName={"Create New TUB Cards"}
                      btnClassname="px-4 text-white primary_bg"
                      disableCreateApp={false}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <CreateApplication
                      onCreateApplicationBtnClick={() =>
                        redirect("/staff/sheet/detail")
                      }
                      btnName={"Create New TUB Sheets"}
                      btnClassname="px-4 text-white primary_bg"
                      disableCreateApp={false}
                    />
                  </div>
                </div>
              </div>
            }
            createBtnLabel="Create New TUB Sheet"
            onCreateRedirect={null}
            getApplicationDetails={(row) => null}
            triggerPopup={() => getApplicationDetails(null, false)}
            showStatusFilter={false}
            showDateFilter={false}
            history={props.history}
            filterList={(...params) => filterList(...params)}
          />
        </Container>
      </div>
      <ModalContainer
        isOpen={isOpen}
        toggle={() => {
          setModal(false);
          setContentType("");
        }}
        title={`Add ${contentType === "1" ? "TUB Sheet" : "TUB Card"} To ${
          packDetailList?.pack_Name
        }`}
      >
        <ReactSelect
          label={`Add ${contentType === "1" ? "TUB Sheet" : "TUB Card"}`}
          name="selectedContentType"
          formGroupClassName="mb-3"
          placeholder={`search ${
            contentType === "1" ? "TUB Sheet" : "TUB Card"
          }`}
          isMulti={false}
          isClearable={false}
          value={mappingData["selectedContentType"]}
          options={contentType === "1" ? tubSheetDropdown : tubCardDropdown}
          closeMenuOnSelect={true}
          onChange={(value) => onChangeSelect("selectedContentType", value)}
        />

        <div className="d-flex justify-content-end mt-2">
          <button
            type="submit"
            onClick={() => mapContentToPack()}
            className="px-4 primary_btn"
          >
            Save
          </button>
        </div>
      </ModalContainer>
      <ModalContainer
        isOpen={isOpenDeletePopup}
        toggle={() => setDeleteModal(false)}
        title={"Reading Program"}
      >
        <div>
          <p>Are you sure you want to delete this Reading Program ?</p>
        </div>
        <div className="d-flex justify-content-end">
          <CreateApplication
            onCreateApplicationBtnClick={() => deleteReadingPr()}
            btnName="Confirm"
            btnClassname="px-4 mx-4 text-white primary_btn"
            disableCreateApp={false}
          />
        </div>
      </ModalContainer>
    </React.Fragment>
  );
};

export { PackDetailList };
