import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
    GET_CURRICULUMS, 
    ADD_CURRICULUM_SUCCESS , 
    ADD_NEW_CURRICULUM , 
    UPDATE_CURRICULUM ,
    GET_READING_PROGRAMS , 
    ADD_NEW_READING_PROGRAM , 
    UPDATE_READING_PROGRAM, 
    ADD_READING_PROGRAM_SUCCESS, 
    UPDATE_READING_PROGRAM_SUCCESS,
    ADD_NEW_READING_PROGRAM_DATA,
    UPDATE_READING_PROGRAM_DATA,
    GET_CURRICULUM_READING_PROGRAMS,
    GET_CURRICULUM_READING_PROGRAMS_SUCCESS
  } 
  from "./actionTypes"

import {
  getCurriculumsSuccess,
  getCurriculumsFail,
  addCurriculumFail,
  addCurriculumSuccess,
  updateCurriculumSuccess,
  updateCurriculumFail,

  // READING PROGRAM
  getReadingProgramsSuccess,
  getReadingProgramsFail,
  addReadingProgramFail,
  addReadingProgramSuccess,
  updateReadingProgramSuccess,
  getCurriculumReadingProgramsSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"
import { Toast } from "helpers/Toast"

function* fetchCurriculums() {
  try {
    const response = yield call(API_ENDPOINTS.getCurriculums)
    yield put(getCurriculumsSuccess(response))
  } catch (error) {
    yield put(getCurriculumsFail(error))
  }
}

function* onUpdateCurriculum({ payload: data }) {
  try {
    const response = yield call(API_ENDPOINTS.createOrEditCurriculum, data)
    yield put(updateCurriculumSuccess(response))
  } catch (error) {
    yield put(updateCurriculumFail(error))
  }
}

function* onAddNewCurriculum(data) {

  try {
    const response = yield call(API_ENDPOINTS.createOrEditCurriculum, {body: data.payload})
  
    yield put(addCurriculumSuccess(response))
  } catch (error) {

    yield put(addCurriculumFail(error))
  }
}

// reading program

function* fetchReadingPrograms(data) {
  try {
    const response = yield call(API_ENDPOINTS.getReadingPrograms, {queryStringParameters: data.payload})
    yield put(getReadingProgramsSuccess(response))
  } catch (error) {
    yield put(getReadingProgramsFail(error))
  }
}


function* onAddNewReadingProgram(data) {
  try {
    const response = yield call(API_ENDPOINTS.createOrEditReadingProgram, {body: data.payload})

  } catch (error) {
    yield put(addReadingProgramFail(error))
  }
}

function* updateReadingProgramDetail(data) {
  try {
    let body = {
      body: data.payload,
      queryStringParameters: {
        imageId: data.payload._id
      }
    }
    const response = yield call(API_ENDPOINTS.createOrEditReadingProgram, body)

    yield put(updateReadingProgramSuccess(response))
  } catch (error) {
    yield put(addReadingProgramFail(error))
  }
}

// PROGRAM DATA

function* onAddNewReadingProgramData(data) {
  try {
    const response = yield call(API_ENDPOINTS.createOrEditReadingProgramInnerData, {body: data.payload})

  } catch (error) {
    yield put(addReadingProgramFail(error))
  }
}

function* onUpdateNewReadingProgramData(data) {
  try {
    let body = {
      body: data.payload,
      queryStringParameters: {
        imageId: data.payload._id
      }
    }
    const response = yield call(API_ENDPOINTS.createOrEditReadingProgramInnerData, body)

    yield put(updateReadingProgramSuccess(response))
  } catch (error) {
    yield put(addReadingProgramFail(error))
  }
}


function* fetchCurriculReadingPrograms(data) {
  try {
    const response = yield call(API_ENDPOINTS.getCurriculumReadingPrograms, {queryStringParameters: data.payload})
    yield put(getCurriculumReadingProgramsSuccess(response))
  } catch (error) {
    Toast('error', error?.response?.data)
  }
}
function* CurriculumsSaga() {
  yield takeEvery(GET_CURRICULUMS, fetchCurriculums)
  yield takeEvery(ADD_CURRICULUM_SUCCESS, fetchCurriculums)
  yield takeEvery(ADD_NEW_CURRICULUM, onAddNewCurriculum)
  yield takeEvery(UPDATE_CURRICULUM, onUpdateCurriculum)

  // reading programs
  yield takeEvery(GET_READING_PROGRAMS, fetchReadingPrograms)
  yield takeEvery(ADD_READING_PROGRAM_SUCCESS, fetchReadingPrograms)
  yield takeEvery(ADD_NEW_READING_PROGRAM, onAddNewReadingProgram)
  yield takeEvery(UPDATE_READING_PROGRAM, updateReadingProgramDetail)
  yield takeEvery(ADD_NEW_READING_PROGRAM_DATA, onAddNewReadingProgramData)
  yield takeEvery(UPDATE_READING_PROGRAM_DATA, onUpdateNewReadingProgramData)

  // curriculum wise reading programs
  yield takeEvery(GET_CURRICULUM_READING_PROGRAMS, fetchCurriculReadingPrograms)
}

export default CurriculumsSaga;
