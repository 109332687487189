import { Breadcrumbs } from "@mui/material";
import { API_ENDPOINTS } from "api/endpoints";
import { ModalContainer } from "components/Modal";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
import { SchoolList, UserList } from "helpers/tableDataMapper";
import React, { useEffect, useState } from "react";
import { Container, Row, Form, Col, CardTitle } from "reactstrap";
import { MdEdit, MdVisibility } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import { Toast } from "helpers/Toast";
import ReactSelect from "components/ReactSelect";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { history } from "helpers/utils";

const Director = () => {
  // let username = localStorage.getItem("username");
  const [isOpen, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolListData, setSchoolList] = useState([]);
  const [directorListing, setDirectorList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [schoolId, setSchoolID] = useState({
    SchoolName: "",
    SchoolEmailID: "",
    PrincipalFirstName: "",
    id: "",
  });
  const redirectAssignSchool = (row) => {
    history.push({
      pathname: `/staff/schoolList/${row[1]}`,
      state: { id: row?.[5] }
    });
  }
  const redirectDirectorDetail = (row) => {
    history.push({
      pathname: `/staff/DirectorDetail/${row[1]}`,
      state: { id: row?.[5] }
    });
  }

  const genderOptions = [
    {
      label: "Male",
      value: "M",
    },
    {
      label: "Female",
      value: "F",
    },
    // {
    //   label: "Others",
    //   value: "O",
    // },
  ];
  const getDirectorListing = async () => {
    let queryPayload = {
      //   userName: username,
      limit: 1000,
      page: 1,
    };

    try {
      setLoading(true);
      const responce = await API_ENDPOINTS.GET_Director(queryPayload);
      if (responce) {
        setLoading(false);
        setDirectorList(responce);
      }
    } catch (error) { }
  };
  const getSchoolListing = async () => {
    let queryPayload = {
      //   userName: username,
      limit: 1000,
      page: 1,
    };

    try {
      setLoading(true);
      const responce = await API_ENDPOINTS.getSchool(queryPayload);
      console.log(responce, "responce");
      if (responce) {
        setLoading(false);

        // setSchoolList(responce);
        const transformedList = responce?.map((val) => ({
          value: val.schoolIdString,
          label: val.SchoolName,
        }));

        setSchoolList(transformedList);
      }
    } catch (error) { }
  };
  useEffect(() => {
    getDirectorListing();
    getSchoolListing();
  }, []);
  const getSchoolDetails = (data) => {
    console.log(data, "updateValue");

    const updatedSchoolIDs = {
      SchoolName: data[2],

      SchoolEmailID: data[3],
      PrincipalFirstName: data[1],
      id: data[4],
    };

    // Assuming schoolID is a state variable and setSchoolID is a state setter function
    setSchoolID(updatedSchoolIDs);

    setModal(true);
    setIsEdit(true);
  };
  const getTableColumns = () => {
    let editObj = {
      label: "Actions",
      name: "",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="ms-1"
              onClick={() => redirectDirectorDetail(tableMeta?.rowData, true)}
            >
              <button className="px-4 primary_btn">View

              </button>
            </div>
            {/* <MdVisibility size={16}  /> */}
            <div className="px-4"
              onClick={() => redirectAssignSchool(tableMeta?.rowData, true)}
            >
              <button className="px-4 primary_btn">Assign School</button>
            </div>
          </div>
        ),
      },
    };
    let newtable = [...UserList, editObj];
    return newtable;
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: schoolId ? schoolId.firstName : "",

      email: schoolId ? schoolId.email : "",
      lastName: schoolId ? schoolId.lastName : "",
      // mobileNumber: schoolId ? schoolId.mobileNumber : "",
      // SchoolName: schoolId ? schoolId.SchoolName : "",
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required("required"),
      firstName: Yup.string().required("required"),
      // mobileNumber: Yup.string().required("required"),
      email: Yup.string().required("required"),

      // SchoolName: Yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      const data = {
        user: {
          firstName: values.firstName,
          email: values.email,
          lastName: values.lastName,
          // mobileNumber: values.mobileNumber,
          gender: selectedOption?.gender?.value,

        },
        schoolIds: selectedOption?.SchoolName?.map((val) => val.value),
      };
      try {
        // if (isEdit == true) {
        //   res = await API_ENDPOINTS.UpdateSchool(schoolId.id, data);
        // } else {

        // }
        let res = await API_ENDPOINTS.createDirector(data);

        console.log(res, "responceee");
        Toast("Success", res.message);
        getDirectorListing();
        setSelectedOption(null);
      } catch (error) { 
        Toast("Error", error.message);
      }
      validation.resetForm();

      setModal(false);
      getDirectorListing();

    },
  });

  const toggle = () => {
    setModal((prevModal) => !prevModal);
  };
  const onChange = (name, value) => {
    // console.log(value, "selectedOption");
    setSelectedOption({ ...selectedOption, [name]: value });
  };
  console.log(schoolListData, "schoolListData");
  console.log(selectedOption, "selectedOption");
  return loading ? (
    <></>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="TUB Cards" breadcrumbItem="TUB Card List" />
        <CustomBootstrapTable
          rows={directorListing}
          columns={getTableColumns()}
          isLoading={false}
          showCreateBtn={true}
          keyField={"_id"}
          createBtnLabel="Create Director"
          triggerPopup={toggle}
          getApplicationDetails={(row) => null}
          showStatusFilter={false}
          showDateFilter={false}
        // history={props.history}
        //   filterList={(...params) => filterList(...params)}
        />
      </Container>
      <ModalContainer
        isOpen={isOpen}
        toggle={() => toggle(false)}
        title={isEdit ? "Edit Director" : "Add New Director"}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <FormikFormInput
              placeholder="Enter First Name"
              name="firstName"
              type="text"
              formGroupClassName="col-6"
              label="Enter First Name"
              labelClassName="col-form-label"
              value={validation.firstName}
              onChange={validation.handleChange}
              validation={validation}
            />
            <FormikFormInput
              placeholder="Enter Last Name"
              name="lastName"
              type="text"
              formGroupClassName="col-6"
              label="Enter Last Name"
              labelClassName="col-form-label"
              value={validation.lastName}
              onChange={validation.handleChange}
              validation={validation}
            />
            {/* <FormikFormInput
              placeholder="Enter SchoolName"
              name="SchoolName"
              type="text"
              formGroupClassName="col-6"
              label="Enter School Name"
              labelClassName="col-form-label"
              value={validation.SchoolName}
              onChange={validation.handleChange}
              validation={validation}
            /> */}

            <FormikFormInput
              placeholder="Enter School Email"
              name="email"
              type="email"
              formGroupClassName="col-12"
              label="Enter  Email"
              labelClassName="col-form-label"
              value={validation.email}
              onChange={validation.handleChange}
              validation={validation}
            />
            {/* <FormikFormInput
              placeholder="Enter Mobile Number"
              name="mobileNumber"
              type="text"
              formGroupClassName="col-6"
              label="Enter  Mobile Number"
              labelClassName="col-form-label"
              value={validation.mobileNumber}
              onChange={validation.handleChange}
              validation={validation}
            /> */}
            <ReactSelect
              name="gender"
              label="Select Gender"
              type="react-select"
              formGroupClassName="row"
              labelClassName="col-12"
              placeholder="Select gender"
              isMulti={false}
              isClearable={false}
              closeMenuOnSelect={false}
              maxLimit={1}
              value={selectedOption?.["gender"]}
              options={genderOptions}
              onChange={(...params) => onChange(...params)}
              //   error={validation}
            />
            <ReactSelect
              name="SchoolName"
              label="School Name"
              type="react-select"
              formGroupClassName="row"
              labelClassName="col-12"
              placeholder="School Name"
              isMulti={true}
              isClearable={false}
              closeMenuOnSelect={false}
              maxLimit={5}
              value={selectedOption?.["SchoolName"]}
              options={schoolListData}
              onChange={(...params) => onChange(...params)}
            //   error={validation}
            />
          </Row>
          <Row className="align-items-center">
            <Col>
              <div className="d-flex justify-content-end">
                <button type="submit" className="px-4 primary_btn">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalContainer>
    </div>
  );
};

export { Director };
