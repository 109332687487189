import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_TUB_SHEETS , ADD_NEW_TUB_SHEET , UPDATE_TUB_SHEET } from "./actionTypes"

import {
  getTubSheetsSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"
import { Toast } from "helpers/Toast"


function* fetchTubSheets(data) {
  try {
    const response = yield call(API_ENDPOINTS.getTubSheets, {queryStringParameters: data.payload})
    yield put(getTubSheetsSuccess(response))
  } catch (error) {
    Toast('error', error)
  }
}


function* onAddNewTubSheet(data) {
  try {
    let res = yield call(API_ENDPOINTS.createOrEditTubSheet, {body: data.payload})
  } catch (error) {
    Toast('error', error)
  }
}

function* updateTubSheetDetail(data) {
  try {
    let body = {
      body: data.payload,
      queryStringParameters: {
        imageId: data.payload._id
      }
    }
    yield call(API_ENDPOINTS.createOrEditTubSheet, body)

  } catch (error) {
    console.log('error', error)
    Toast('error', error)
  }
}

function* wordsSaga() {
  yield takeEvery(GET_TUB_SHEETS, fetchTubSheets)
  yield takeEvery(ADD_NEW_TUB_SHEET, onAddNewTubSheet)
  yield takeEvery(UPDATE_TUB_SHEET, updateTubSheetDetail)
}

export default wordsSaga;