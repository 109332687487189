import {
  GET_TAGS_SUCCESS,
  GET_TAG_FAIL,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  UPDATE_TAG_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  tagsList: [],
  tagDetail: {},
  success: '',
  error: {},
}

const tags = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        tagsList: action.payload,
      }

    case GET_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TAG_SUCCESS:
      return {
        ...state,
        success: 'success'
      }

    case ADD_TAG_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case UPDATE_TAG_FAIL:
        return {
          ...state,
          error: action.payload,
        }
    default:
      return state
  }
}

export default tags
