import {
  GET_COUPONS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  couponsList: [],
  couponDetail: {},
  error: {},
}

const Coupon = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        couponsList: action.payload,
      }
    default:
      return state
  }
}

export default Coupon
