import React from 'react';
import { Spinner } from 'reactstrap';
import './button.scss';

const ButtonType1 = ({onClick, btnName, isLoading}) => {
    return (
        <>
            {isLoading 
                ?
                    <div className='buttonType1'>
                        <Spinner 
                            size={15}
                            color={"#ffffff"}
                        />
                    </div>
                :
                    <div
                        className={`buttonType1`}
                        onClick={() => onClick()}
                    >   
                        <span className="ml-2">{btnName}</span>
                    </div>
            }
        </>
     );
}

export default ButtonType1;