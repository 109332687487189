import React from "react";
import { Col, Row, FormGroup, Label, Input, FormFeedback } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Formik, Form, Field } from "formik";

const animatedComponents = makeAnimated();
// const getCurrentDate = () => {
//     const currentDate = new Date();
//     const year = currentDate.getFullYear();
//     let month = currentDate.getMonth() + 1;
//     let day = currentDate.getDate();

//     // Add leading zeros if month or day is less than 10
//     if (month < 10) {
//       month = '0' + month;
//     }
//     if (day < 10) {
//       day = '0' + day;
//     }

//     return `${year}-${month}-${day}`;
//   };
const FormikFormInput = ({ type, validation, disabled, ...rest }) => {
  console.log(rest, "validatiosssssn");
  const getInput = () => {
    switch (type) {
      case "text":
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <Col className={rest.colClassName}>
              <Input
                type="text"
                name={rest.name}
                maxLength={rest.maxLength}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={rest.valueClassName}
                value={validation.values[rest.name] || ""}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
                disabled={disabled}
              />
              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type="invalid">
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </Col>
          </div>
        );
      case "textarea":
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <Col>
              <Input
                type="textarea"
                name={rest.name}
                maxLength={rest.maxLength}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[rest.name] || ""}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
              />
              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type="invalid">
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </Col>
          </div>
        );
      case "two-way-switch":
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName}>{rest.label}</Label>
            )}
            <Col>
              <div className=" form-switch form-switch-sm two-way-switch">
                {rest.leftLabel && <Label>{rest.leftLabel}</Label>}
                <Input
                  type="checkbox"
                  name={rest.name}
                  className="form-check-input"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values[rest.name] || ""}
                  invalid={
                    validation.touched[rest.name] &&
                    validation.errors[rest.name]
                      ? true
                      : false
                  }
                />

                {validation.touched[rest.name] &&
                validation.errors[rest.name] ? (
                  <FormFeedback type="invalid">
                    {validation.errors[rest.name]}
                  </FormFeedback>
                ) : null}
                {rest.rightLabel && <Label>{rest.rightLabel}</Label>}
              </div>
            </Col>
            <strong className="text-danger small">
              {rest.error ? rest.error[0] : ""}
            </strong>
          </div>
        );
      case "price":
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <div className="d-flex col-md-10 gap-3">
              <Col className="col-sm-auto">
                <Input
                  type="text"
                  className={rest.valueClassName}
                  value="$ USD"
                  disabled={true}
                />
              </Col>
              <Col className="col-sm-auto">
                <Input
                  type="number"
                  min={0}
                  name={rest.name}
                  maxLength={rest.maxLength}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  className={rest.valueClassName}
                  value={validation.values[rest.name] || ""}
                  invalid={
                    validation.touched[rest.name] &&
                    validation.errors[rest.name]
                      ? true
                      : false
                  }
                />
                {validation.touched[rest.name] &&
                validation.errors[rest.name] ? (
                  <FormFeedback type="invalid">
                    {validation.errors[rest.name]}
                  </FormFeedback>
                ) : null}
              </Col>
            </div>
          </div>
        );
      case "flat-datepicker":
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName} for={"date-picker"}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <div className="d-flex col-md-10 gap-1">
              <Col className="col-sm-6">
                <Input
                  className="form-control d-block"
                  placeholder="Choose Start Date"
                  type="date"
                  id="date-picker"
                  name={rest.name1}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values[rest.name1] || ""}
                  invalid={
                    validation.touched[rest.name1] &&
                    validation.errors[rest.name1]
                      ? true
                      : false
                  }
                />
                {validation.touched[rest.name1] &&
                validation.errors[rest.name1] ? (
                  <FormFeedback type="invalid">
                    {validation.errors[rest.name1]}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col className="col-sm-6">
                <Input
                  className="form-control d-block"
                  placeholder="Choose Start Date"
                  type="date"
                  id="date-picker"
                  name={rest.name2}
                  onBlur={validation.handleBlur}
                  onChange={validation.handleChange}
                  value={validation.values[rest.name2] || ""}
                  invalid={
                    validation.touched[rest.name2] &&
                    validation.errors[rest.name2]
                      ? true
                      : false
                  }
                />
                {validation.touched[rest.name2] &&
                validation.errors[rest.name2] ? (
                  <FormFeedback type="invalid">
                    {validation.errors[rest.name2]}
                  </FormFeedback>
                ) : null}
              </Col>
            </div>
          </div>
        );
      case "react-select":
        return (
          <div
            className={`templating-select select2-container pb-3 ${rest.formGroupClassName}`}
          >
            {rest.label && (
              <label className={`control-label ${rest.labelClassName}`}>
                {rest.label}
                {rest.spnText && (
                  <span className="spn-text">&nbsp; ( {rest.spnText} )</span>
                )}
              </label>
            )}
            <Col>
              <Select
                options={rest.options}
                defaultValue={rest.value}
                value={validation.values[rest.name]}
                styles={rest.styles}
                isMulti={rest.isMulti}
                isDisabled={rest.isDisabled}
                isClearable={rest.isClearable}
                placeholder={rest.placeholder}
                classNamePrefix={`select2-selection ${
                  rest.error !== "" ? "select_error" : ""
                }`}
                closeMenuOnSelect={rest.closeMenuOnSelect}
                onChange={(e) => validation.setFieldValue(rest.name, e.value)}
                isOptionDisabled={(option) =>
                  rest.isMulti ? rest.value.length >= rest.maxLimit : false
                }
                components={animatedComponents}
              />
              <div className="invalid_text">{rest.error ? rest.error : ""}</div>
            </Col>
            {rest.spanText !== "" && rest.spanText !== undefined ? (
              <label>{rest.spanText}</label>
            ) : (
              ""
            )}
          </div>
        );
      case "date":
        return (
          <div className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <Col className={rest.colClassName}>
              <Input
                type="date"
                name={rest.name}
                maxLength={rest.maxLength}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={rest.valueClassName}
                value={validation.values[rest.name] || ""}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
                disabled={disabled}
                // min={getCurrentDate()}
                min={rest?.min}
              />
              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type="invalid">
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </Col>
          </div>
        );
      default:
        return (
          <FormGroup className={`pb-3 ${rest.formGroupClassName}`}>
            {rest.label && (
              <Label className={rest.labelClassName}>
                {rest.label}
                {rest.isOptional ? (
                  <span className="text-yellow">&nbsp;( Optional )</span>
                ) : (
                  ""
                )}
              </Label>
            )}
            <Col>
              <Input
                type="text"
                name={rest.name}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[rest.name] || ""}
                invalid={
                  validation.touched[rest.name] && validation.errors[rest.name]
                    ? true
                    : false
                }
              />
              {validation.touched[rest.name] && validation.errors[rest.name] ? (
                <FormFeedback type="invalid">
                  {validation.errors[rest.name]}
                </FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
        );
    }
  };
  return getInput();
};

export default FormikFormInput;
