import {
  SETUP_NEW_PASSWORD,
  SETUP_NEW_PASSWORD_SUCCESS,
  SETUP_NEW_PASSWORD_ERROR,
} from "./actionTypes"

const initialState = {
  setNewPassSuccessMsg: '',
  setNewPassError: '',
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case SETUP_NEW_PASSWORD:
      state = {
        ...state,
        setNewPassSuccessMsg: '',
        setNewPassError: '',
      }
      break
    case SETUP_NEW_PASSWORD_SUCCESS:
      state = {
        ...state,
        setNewPassSuccessMsg: action.payload,
      }
      break
    case SETUP_NEW_PASSWORD_ERROR:
      state = { ...state, setNewPassError: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
