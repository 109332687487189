import {
  GET_CURRICULUMS_SUCCESS,
  GET_CURRICULUMS_FAIL,
  ADD_CURRICULUM_FAIL,
  // reading program
  GET_READING_PROGRAMS_SUCCESS,
  GET_READING_PROGRAMS_FAIL,
  ADD_READING_PROGRAM_FAIL,
  GET_READING_PROGRAM_DETAIL_SUCCESS,
  GET_READING_PROGRAM_DETAIL_FAIL,
  GET_CURRICULUM_READING_PROGRAMS,
  GET_CURRICULUM_READING_PROGRAMS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  curriculumList: [],
  curriculumDetail: {},
  error: {},
  readingProgramsList: [],
  curriculumReadingProgramsList: {
    list: [],
    curriculumDetail : {
      curriculum_Name: ''
    }
  }
}

const curriculums = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CURRICULUMS_SUCCESS:
      return {
        ...state,
        curriculumList: action.payload,
      }

    case GET_CURRICULUMS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CURRICULUM_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      case GET_READING_PROGRAMS_SUCCESS:
        return {
          ...state,
          readingProgramsList: action.payload,
        }
  
      case GET_READING_PROGRAMS_FAIL:
        return {
          ...state,
          error: action.payload,
        }
      case ADD_READING_PROGRAM_FAIL:
        return {
          ...state,
          error: action.payload,
        }
  
      case GET_READING_PROGRAM_DETAIL_SUCCESS:
        return {
          ...state,
          readingProgramDetail: action.payload,
        }
  
      case GET_READING_PROGRAM_DETAIL_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case GET_CURRICULUM_READING_PROGRAMS_SUCCESS:
        console.log('action.payload',action.payload)
        return {
          ...state,
          curriculumReadingProgramsList: {
            list: action.payload?.Programs,
            curriculumDetail: action.payload?.Curriculum
          },
        }
    default:
      return state
  }
}

export default curriculums
