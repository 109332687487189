/* SUBSCRIPTION_RULES */
export const GET_SUBSCRIPTION_RULES = "GET_SUBSCRIPTION_RULES"
export const GET_SUBSCRIPTION_RULES_SUCCESS = "GET_SUBSCRIPTION_RULES_SUCCESS"
export const GET_SUBSCRIPTION_RULES_FAIL = "GET_SUBSCRIPTION_RULES_FAIL"

/* SUBSCRIPTION_RULES DETAIL */
export const GET_SUBSCRIPTION_RULE_DETAIL = "GET_SUBSCRIPTION_RULE_DETAIL"
export const GET_SUBSCRIPTION_RULE_DETAIL_SUCCESS = "GET_SUBSCRIPTION_RULE_DETAIL_SUCCESS"
export const GET_SUBSCRIPTION_RULE_DETAIL_FAIL = "GET_SUBSCRIPTION_RULE_DETAIL_FAIL"

/**
 * CREATE SUBSCRIPTION RULE
 */
export const ADD_NEW_SUBSCRIPTION_RULE = "ADD_NEW_SUBSCRIPTION_RULE"
export const ADD_SUBSCRIPTION_RULE_SUCCESS = "ADD_SUBSCRIPTION_RULE_SUCCESS"
export const ADD_SUBSCRIPTION_RULE_FAIL = "ADD_SUBSCRIPTION_RULE_FAIL"

/**
 * Update SUBSCRIPTION RULE
 */
export const UPDATE_SUBSCRIPTION_RULE = "UPDATE_SUBSCRIPTION_RULE"
export const UPDATE_SUBSCRIPTION_RULE_SUCCESS = "UPDATE_SUBSCRIPTION_RULE_SUCCESS"
export const UPDATE_SUBSCRIPTION_RULE_FAIL = "UPDATE_SUBSCRIPTION_RULE_FAIL"