import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
    CardTitle,
  Container,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import {  ReadingProgramsColumn } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import { getReadingPrograms } from "store/actions";

const ReadingProgramList = (props) => {
    const dispatch = useDispatch();

    const { readingProgramsList } = useSelector(state => ({
        readingProgramsList: state.curriculums.readingProgramsList,
    }));
    
    useEffect(() => {
        let queryPayload = {
            search: '',
        }
        dispatch(getReadingPrograms(queryPayload));
    }, [dispatch, props]);

    const getApplicationDetails = (row) => {
        props.history.push(`/staff/readingprogram/detail/${row[1]}`)
    }
    const filterList = (queryPayload) => {
        let payload = {
            ...queryPayload,
            curriculum_Id: props.match.params.curriculumId
        }
        dispatch(getReadingPrograms(payload))
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Reading Program" breadcrumbItem="Reading Program List" />
                    <CustomBootstrapTable 
                        rows = {readingProgramsList}
                        columns = {ReadingProgramsColumn}
                        isLoading={false}
                        showCreateBtn = {true}
                        keyField={'_id'}
                        createBtnLabel="Create New Program"
                        onCreateRedirect = {`/staff/readingprogram/detail`}
                        getApplicationDetails={(row) => getApplicationDetails(row)}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                        filterList = {(...params) => filterList(...params)}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export { ReadingProgramList }