import React, { useEffect, useState } from "react";
import {  withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Form,
  Col,
  CardTitle
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { getMuiTheme, TagsColumn, WordsColumn } from "helpers/tableDataMapper";
import { ModalContainer } from "components/Modal";
import FormInput from "components/FormInput";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import CreateApplication from 'components/Button/createApplicationBtn'
import { useSelector, useDispatch } from "react-redux";
import {
    getTags,
    createOrEditTag
} from "store/tags/actions";
import { API_ENDPOINTS } from "api/endpoints";

import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import _ from 'lodash'

const Tags = (props) => {
    const defaultTagData = {
        tags: '',
        logo: {
            original: ''
        },
        status: true
    }

    const dispatch = useDispatch();

    const [isOpen, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false);

    const [tagDetail, setDetail] = useState({
        tags: null,
        logo: {
            original: ''
        },
        status: true
    })
    const { tagsList } = useSelector(state => ({
        tagsList: state.tags.tagsList,
    }));

    useEffect(() => {
        let queryPayload = {
            search: ''
        }
        dispatch(getTags(queryPayload));
    }, [dispatch]);

    const onChange = (name, value) => {
        if(name == 'logo'){
            uploadImage(value)
        } else {
            setDetail({...tagDetail, [name]: value})
        }
    }

    const uploadImage = async (value) => {
        let formData = new FormData()
        formData.append('image', value)
        try  { 
            const myInit = {
                body: formData,
            };
            const response = await API_ENDPOINTS.uploadImage(myInit)
            setDetail({
                ...tagDetail,
                logo: {
                    original: response?.originalImage?.original,
                }
            })
         } catch(error) {
            console.log('error', error)
        }
    }

    const getApplicationDetails = (row, edit) => {
        setIsEdit(edit)
        if(edit){
            setDetail({
                _id: row[1],
                tags: row[2]?.props?.children,
                status: row[3]?.props?.children?.props?.className === 'primary_bg' ? true : false
            })
        }
        setModal(true)
    }

    const save = async (isDelete) => {
        let body = {
            ...tagDetail,
            tags: tagDetail.tags.charAt(0).toUpperCase() + tagDetail.tags.slice(1),
            AWS_UserName: localStorage.getItem('username'),
            status: tagDetail.status ? 'Active' : 'InActive'
        }

        if(isDelete){
            body = {
                ...body,
                isDeleted: true
            }
        } 

        dispatch(createOrEditTag(body))
        setDetail({...tagDetail, ...defaultTagData})
        setModal(false)
    }
    
    const filterList = (queryPayload) => {
        dispatch(getTags(queryPayload))
    }
   
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            tags: (tagDetail && tagDetail.tags) || "",
        },
        validationSchema: Yup.object({
            tags: Yup.string().required("Please Enter Tag Name"),
            // logo: Yup.mixed().required('File is required'),
        }),
        onSubmit: (values) => {
            let body = {
                AWS_UserName: localStorage.getItem('username'),
            }
            if(isEdit){
                body = {
                    ...body,
                    _id: tagDetail._id,
                    status: tagDetail.status ? 'Active' : 'InActive',
                    tags: values.tags.charAt(0).toUpperCase() + values.tags.slice(1),
                }
            } else {
                body = {
                    ...values,
                    tags: values.tags.charAt(0).toUpperCase() + values.tags.slice(1),
                    status: tagDetail.status ? 'Active' : 'InActive',
                    logo: tagDetail.logo
                }
            }
        
            dispatch(createOrEditTag(body));
            validation.resetForm();

            setModal(false)
        }
    });

    const toggle = (type) => {
        setDetail({...tagDetail, ...defaultTagData})
        setModal(type)
    }
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <CardTitle className="page-title-box">TAGS</CardTitle>
                    <CustomBootstrapTable 
                        rows = {tagsList}
                        columns = {TagsColumn}
                        showCreateBtn = {true}
                        isLoading={false}
                        keyField={'_id'}
                        createBtnLabel="Add New Tag"
                        onCreateRedirect = {null}
                        filterList = {(...params) => filterList(...params)}
                        triggerPopup = {() => getApplicationDetails(defaultTagData, false)}
                        getApplicationDetails={(row) => getApplicationDetails(row, true)}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                    />
                </Container>
            </div>
            <ModalContainer
                isOpen={isOpen}
                toggle={() => toggle(false)}
                title={isEdit ? 'Edit Tag' : 'Add New Tag'}
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                <FormikFormInput 
                    placeholder="Enter Tag Name"
                    name="tags" 
                    type = 'text'
                    formGroupClassName = ""
                    label = 'Tag Name'
                    labelClassName = "col-form-label"
                    value={tagDetail['tags']}
                    onChange = {(...params) => onChange(...params)}
                    validation = {validation}
                />
                <LogoUpload 
                    title="Upload Logo"
                    name = "logo"
                    preview = {tagDetail['logo']?.original}
                    onChange = {(...params) => onChange(...params)}
                    validation = {validation}
                    removeImage = {() => setDetail({...tagDetail, logo: {original: ''}})}
                />
                <FormInput 
                    name="status" 
                    formGroupClassName = "row mt-3 "
                    label = 'Status'
                    type={"two-way-switch"}
                    leftLabel = "Inactive"
                    rightLabel = "Active"
                    labelClassName = "col-md-2 col-form-label"
                    value={tagDetail['status']}
                    onChange = {(...params) => onChange(...params)}
                />
                
                <Row className="align-items-center">
                    {isEdit &&
                        <Col>
                            <div>
                                <p className="danger" onClick={() => save(true)}>Delete Tag</p>
                            </div>
                        </Col>
                    }
                    <Col>
                        <div className='d-flex justify-content-end'>
                            <button
                                type="submit"
                                className="px-4 primary_btn"
                            >
                                Save
                            </button>
                        </div>
                    </Col>
                </Row>
                </Form>
            </ModalContainer>
        </>
    )
}

export { Tags }