import { Breadcrumbs } from "@mui/material";
import { API_ENDPOINTS } from "api/endpoints";
import { ModalContainer } from "components/Modal";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
import { SchoolList, DirectorSchoolListing } from "helpers/tableDataMapper";
import React, { useEffect, useState  } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Row, Form, Col, CardTitle } from "reactstrap";
import { MdCheck, MdClose } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import { Toast } from "helpers/Toast";
import Select from 'react-select'; // Import react-select
import { useLocation } from 'react-router-dom';
import { isDisabled } from "@testing-library/user-event/dist/utils";
const SchoolListing = () => {
   const history = useHistory()
  // let username = localStorage.getItem("username");
  const location = useLocation();
  const myObject = location?.state?.id;

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);

  const [selectedState, setSelectedState] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);

  const [selectedCity, setSelectedCity] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const [isOpen, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [schoolListData, setSchoolList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [schoolId, setSchoolID] = useState({
    SchoolName: "",
    SeatsPurchased: "",
    SchoolEmailID: "",
    PrincipalFirstName: "",
    id: "",
  });
  const [selectedSchoolIds, setSelectedSchoolIds] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [directorListing, setDirectorList] = useState([]);

  console.log(selectedCountry,'selectedCountry');
  const getSchoolListing = async () => {
    let queryPayload = {
      // userName: username,
      limit: 1000,
      page: 1,
      Country: selectedCountry ? selectedCountry.value : '',
      State:selectedState ? selectedState.value : '',
      City:selectedCity ? selectedCity.value : '',
    };

    try {
      setLoading(true);
      const response = await API_ENDPOINTS.getSchool(queryPayload);
      console.log(response, "responsesssss");
      if (response) {
        setLoading(false);
        setSchoolList(response);
      }
    } catch (error) {
      console.error("Error fetching schools:", error);
    }
  };
  useEffect(() => {
    getSchoolListing();
    getDirectorDetail();
    // getCountries();
  }, [selectedCountry, selectedState, selectedCity]);

  const getDirectorDetail = async () => {
    let queryPayload = {
      userId:myObject
    };

    try {
      setLoading(true);
      const response = await API_ENDPOINTS.getdirectorDetail(queryPayload);
      setSelectedSchoolIds(() => [...response[0].teams.map(team => team.schoolId)]);
      if (response) {
        setLoading(false);
        setDirectorList(response[0].teams);
      }
    } catch (error) {}
  };

  const getCountries = async () => {
    try {
    setLoading(true);
    const response = await API_ENDPOINTS.getCountries();
    console.log(response, "responsesssss");
    if (response) {
      const countryOptions = response.map(country => ({
        value: country.name,
        label: country.name,
        Id:country.id
      }));
      setCountryOptions(countryOptions);
      setIsDisabled(false); // Enable state selection if countries are available
      setLoading(false);
    }
   
  } catch (error) {
    console.error("Error fetching countries:", error);
  }
  };
  useEffect(() => {
    getCountries();
  }, []);

  const getStates = async () => {
    if (selectedCountry) {
      let queryPayload = {
        countryId: selectedCountry.Id // Pass the selected country ID
      };
      try {
        setLoading(true);
        const response = await API_ENDPOINTS.getStates(queryPayload);
        console.log(response, "responsesssss");
        if (response) {
          const stateOptions = response.map(state => ({
            value: state.name,
            label: state.name,
            id:state.id
          }));
          setStateOptions(stateOptions);
          setIsDisabled(false); // Enable city selection if states are available
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
  };

    useEffect(() => {
      if (selectedCountry) {
        const getStates = async () => {
          let queryPayload = {
            countryId: selectedCountry.Id
          };
          try {
            setLoading(true);
            const response = await API_ENDPOINTS.getStates(queryPayload);
            console.log(response, "responsesssss");
            if (response) {
              const stateOptions = response.map(state => ({
                value: state.name,
                label: state.name,
                id: state.id
              }));
              setStateOptions(stateOptions);
              setIsDisabled(false); // Enable state selection if states are available
              setLoading(false);
            }
          } catch (error) {
            console.error("Error fetching states:", error);
          }
        };
        getStates();
      }
    }, [selectedCountry])

  
  const getCities = async () => {
    if (selectedState) {
      let queryPayload = {
        stateId: selectedState.id // Pass the selected state ID
      };
      try {
        setLoading(true);
        const response = await API_ENDPOINTS.getCities(queryPayload);
        console.log(response, "responsesssss");
        if (response) {
          const cityOptions = response.map(city => ({
            value: city.name,
            label: city.name
          }));
          setCityOptions(cityOptions);
          setIsDisabled(false); // Enable city selection if cities are available
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    }
  };
  useEffect(() => {
    if (selectedState) {
      const getCities = async () => {
        let queryPayload = {
          stateId: selectedState.id
        };
        try {
          setLoading(true);
          const response = await API_ENDPOINTS.getCities(queryPayload);
          console.log(response, "responsesssss");
          if (response) {
            const cityOptions = response.map(city => ({
              value: city.name,
              label: city.name
            }));
            setCityOptions(cityOptions);
            setIsDisabled(false); // Enable city selection if cities are available
            setLoading(false);
          }
        } catch (error) {
          console.error("Error fetching cities:", error);
        }
      };
      getCities();
    }
  }, [selectedState]);

  const getSchoolDetails = (data) => {
    console.log(data, "updateValue");
    const updatedSchoolIDs = {
      SchoolName: data[2]?.[0]?.SchoolName,
      SeatsPurchased: data[5]?.[0]?.SeatsPurchased,
      // SchoolEmailID: data[3],
      PrincipalFirstName: data[2]?.[0]?.PrincipalFirstName,
      id: data[4],
    };

    // Assuming schoolID is a state variable and setSchoolID is a state setter function
    setSchoolID(updatedSchoolIDs);

    setModal(true);
    setIsEdit(true);
  };

  const handleAssignSchool = async (props) => {
    try {
      const payload = {
        userId: myObject, // Assuming this is the user ID to assign schools to
        schoolIds: selectedSchoolIds,
      };
      const response = await API_ENDPOINTS.assignSchools(payload);
      console.log(response, "response from assignSchools API");
      // Handle success or show a toast message
     // Toast("Success", response.message);
      // You might want to reload the school list after assigning
      // getSchoolListing();
      history.push(`/staff/director`)
    } catch (error) {
      console.error("Error assigning schools:", error);
      // Handle error or show a toast message
     // Toast("Error", "Failed to assign schools");
    }
  };

  // const handleCheckboxChange = (event, id) => {
  //   const isChecked = event.target.checked;
  //   console.log('event --->>', event.target);
  
  //   if (id === 'selectAll') {
  //     setSelectAllChecked(isChecked);
  //     if (isChecked) {
  //       setSelectedSchoolIds(() => [...schoolListData.map(school => school._id)]);
  //     } else {
  //       setSelectedSchoolIds([]);
  //     }
  //   } else {
  //     let updatedSelectedSchoolIds;
  //     if (isChecked) {
  //       updatedSelectedSchoolIds = [...selectedSchoolIds, id];
  //     } else {
  //       updatedSelectedSchoolIds = selectedSchoolIds.filter(schoolId => schoolId !== id);
  //     }
  
  //     setSelectedSchoolIds(() => [...updatedSelectedSchoolIds]);
  //     // Check if all checkboxes are unchecked, then uncheck "Select All"
  //     if (updatedSelectedSchoolIds.length === 0) {
  //       setSelectAllChecked(false);
  //     } else {
  //       // Check if all checkboxes are checked, then check "Select All"
  //       const allChecked = schoolListData.every(school => updatedSelectedSchoolIds.includes(school._id));
  //       setSelectAllChecked(() => allChecked);
  //     }
  //   }
  // };
  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
  
    if (id === 'selectAll') {
      setSelectAllChecked(isChecked);
      if (isChecked) {
        setSelectedSchoolIds([...schoolListData.map(school => school._id)]);
      } else {
        setSelectedSchoolIds([]);
      }
    } else {
      let updatedSelectedSchoolIds;
      if (isChecked) {
        updatedSelectedSchoolIds = [...selectedSchoolIds, id];
      } else {
        updatedSelectedSchoolIds = selectedSchoolIds.filter(schoolId => schoolId !== id);
      }
      setSelectedSchoolIds([...updatedSelectedSchoolIds]);
      // Check if all checkboxes are unchecked, then uncheck "Select All"
      if (updatedSelectedSchoolIds.length === 0) {
        setSelectAllChecked(false);
      } else {
        // Check if all checkboxes are checked, then check "Select All"
        const allChecked = schoolListData.every(school => updatedSelectedSchoolIds.includes(school._id));
        setSelectAllChecked(allChecked);
      }
    }
  };
  
  const getTableColumns = () => {

    // let editObj = {
    //   label: "Assign",
    //   name: "",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <div className="d-flex align-items-center justify-content-center">
    //         <div
    //           className="ms-1"
    //           onClick={() => getSchoolDetails(tableMeta?.rowData)}
    //         >
    //           <MdCheck size={16} />
    //           <MdClose size={16} />

    //         </div>
    //       </div>
    //     ),
    //   },
    // };
    let newtable = [...DirectorSchoolListing];
     // Add checkbox column for selecting schools
     newtable.unshift({
      name: "checkbox",
      label: (
        <input
          type="checkbox"
          checked={selectAllChecked}
          onChange={(e) => handleCheckboxChange(e, 'selectAll')}
        />
      ),
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          // const schoolId = schoolListData[dataIndex]._id;
          // const isSchoolInTeam = directorListing.some(team => team.schoolId === schoolId);
          const schoolId = schoolListData[dataIndex]._id;
          const isChecked = selectedSchoolIds.includes(schoolId);
          return (
          //   <input
          //   type="checkbox"
          //   onChange={(e) => handleCheckboxChange(e, schoolId)}
          //   defaultChecked={selectedSchoolIds.includes(schoolId) || isSchoolInTeam}
          // />
          <input
          type="checkbox"
          onChange={(e) => handleCheckboxChange(e, schoolId)}
          checked={isChecked}
        />
            // <input
            //   type="checkbox"
            //   onChange={(e) => handleCheckboxChange(e, schoolListData[dataIndex]._id)}
            //   checked={selectedSchoolIds.includes(schoolListData[dataIndex]._id)}
            // />
            
          );
        },
      },
    });
    return newtable;
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      SchoolName: schoolId ? schoolId.SchoolName : "",
      SeatsPurchased: schoolId ? schoolId.SeatsPurchased : "",
      // SchoolEmailID: schoolId ? schoolId.SchoolEmailID : "",
      PrincipalFirstName: schoolId ? schoolId.PrincipalFirstName : "",
    },
    validationSchema: Yup.object({
      SeatsPurchased: Yup.string().required("required"),
      // SchoolEmailID: Yup.string().required("required"),
      PrincipalFirstName: Yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      const data = { ...values};
      try {
        let res;
        if (isEdit == true) {
          res = await API_ENDPOINTS.UpdateSchool(schoolId.id, data);
        } else {
          res = await API_ENDPOINTS.createSchool(data);
        }

        console.log(res, "responseee");
        getSchoolListing();
        Toast("Success", res.message);
      } catch (error) {}
      validation.resetForm();

      setModal(false);
    },
  });

  const toggle = () => {
    setModal((prevModal) => !prevModal);
    setSchoolID("");
  };

  return loading ? (
    <></>
  ) : (
    
    <div className="page-content">
       <Container fluid>
        <Form>
          <div className="Filter-location">
          <Row>   
             
            <Col>
              <Select
                options={countryOptions}
                value={selectedCountry}
                onChange={(selectedOption) => {
                  setSelectedCountry(selectedOption);
                }}
                placeholder="Select a country"
                isSearchable={true} // Enable search
              />
            </Col>
            <Col>
              <Select
                options={stateOptions}
                value={selectedState}
                onChange={(selectedOption) => {
                  setSelectedState(selectedOption);
                }}
                placeholder="Select a state"
                isSearchable={true} // Enable search\
                // isDisabled={isDisabled}
              />
            </Col>
            <Col>
              <Select
                options={cityOptions}
                value={selectedCity}
                onChange={(selectedOption) => {
                  setSelectedCity(selectedOption);
                }}
                placeholder="Select a city"
                isSearchable={true} // Enable search
                //  isDisabled={isDisabled}
              />
            </Col>
            <Col>
            <button type="submit" className="px-4 primary_btn" onClick={(event) => {
            event?.preventDefault();
            handleAssignSchool()
            }} 
  disabled={selectedSchoolIds.length === 0}>Assign School</button>

            </Col>
          </Row>
          </div>  
        </Form>
     
        <Breadcrumbs title="TUB Cards" breadcrumbItem="TUB Card List" />
        <CustomBootstrapTable
          rows={schoolListData}
          columns={getTableColumns()}
          isLoading={false}
          // showCreateBtn={true}
          keyField={"_id"}
          // createBtnLabel="Assign School"
          triggerPopup={toggle}
          getApplicationDetails={(row) => null}
          showStatusFilter={false}
          showDateFilter={false}
          //   history={props.history}
          //   filterList={(...params) => filterList(...params)}
        />
      </Container>
    
    </div>
  );
};

export { SchoolListing };
