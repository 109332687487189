import { getImageHeight, getImageWidth, getPosTranslationPoint } from './CanvasHelper';
import React, { useEffect, useState, useRef } from 'react'
import imageCompression from 'browser-image-compression';
import { API_ENDPOINTS } from 'api/endpoints';
import CreateApplicationBtn from 'components/Button/createApplicationBtn';


export default function ContentViewCard(props) {
    const [isBtnDisabled, setIsBtnDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const canvasRef = useRef(null);

    useEffect(() => {
        handleCanvas()
        setIsBtnDisabled(props.selections.length >= 2 ? false : true)
    }, [props])


    const handleCanvas = async () => {
        if (canvasRef != null && props?.selections.length > 0) {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");

            const drawingAreaWidth = props?.canvasWidth - 100;
            const drawingAreaHeight = props?.canvasHeight - 100;

            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = "#fff";
            context.fillRect(0, 0, canvas.width, canvas.height);
            const borderRadius = 20
            for (let index = 0; index < props.selections.length; index++) {
                const itemObject = props.selections[index]
                const image = new window.Image();
                image.crossOrigin = "Anonymous";
                image.style = "object-fit: contain";
                image.onload = () => {
                    const printingPoint = getPosTranslationPoint(props?.selections.length, drawingAreaWidth, drawingAreaHeight, index)
                    let item = {
                        imageUrl: itemObject !== undefined ? itemObject?.data?.alphabetImage?.original : itemObject?.data?.originalImage?.original,
                        xCoordinate: printingPoint.xAxis + 60,
                        yCoordinate: printingPoint.yAxis + 60,
                        width: getImageWidth(props?.selections.length, drawingAreaWidth - 120, index),
                        height: getImageHeight(props?.selections.length, (props.selections.length === 3 || props.selections.length === 8 ) ? drawingAreaHeight : drawingAreaHeight - 120, index),
                    }
                    context.strokeStyle = index % 2 === 0 ? "#fdefe9" : "#c0dbea";
                    context.lineWidth = 5
                    context.beginPath();
                    context.moveTo(item.xCoordinate + borderRadius, item.yCoordinate);
                    context.lineTo(item.xCoordinate + item.width - borderRadius, item.yCoordinate);
                    context.quadraticCurveTo(item.xCoordinate + item.width, item.yCoordinate, item.xCoordinate + item.width, item.yCoordinate + borderRadius);
                    context.lineTo(item.xCoordinate + item.width, item.yCoordinate + item.height - borderRadius);
                    context.quadraticCurveTo(item.xCoordinate + item.width, item.yCoordinate + item.height, item.xCoordinate + item.width - borderRadius, item.yCoordinate + item.height);
                    context.lineTo(item.xCoordinate + borderRadius, item.yCoordinate + item.height);
                    context.quadraticCurveTo(item.xCoordinate, item.yCoordinate + item.height, item.xCoordinate, item.yCoordinate + item.height - borderRadius);
                    context.lineTo(item.xCoordinate, item.yCoordinate + borderRadius);
                    context.quadraticCurveTo(item.xCoordinate, item.yCoordinate, item.xCoordinate + borderRadius, item.yCoordinate);
                    context.closePath();
                    context.stroke();

                    context.drawImage(
                        image,
                        item.xCoordinate,
                        item.yCoordinate,
                        item.width,
                        item.height,
                    );

                    // Draw the border
                    // context.strokeRect(
                    //     item.xCoordinate,
                    //     item.yCoordinate,
                    //     item.width,
                    //     item.height
                    // );
                }
                image.src = itemObject?.data?.originalImage?.original + "?not-from-cache-please";


            }

        }
    }


    const handleDownload = async () => {
        setIsLoading(true)
        const canvas = canvasRef.current;
        const compressedImageBlob = await new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/png');
        });

        const options = {
            maxSizeMB: 1,
            useWebWorker: true
        }
        try {
            const compressedFile = await imageCompression(compressedImageBlob, options);

            const file = new File([compressedFile], 'canvas-image.png', { type: 'image/png' });

            let formData = new FormData()
            formData.append('image', file)
            try {
                const myInit = {
                    body: formData,
                };
                const response = await API_ENDPOINTS.uploadImage(myInit)
                props.updateImage(response)
                setIsLoading(false)
            } catch (error) {
                console.log('error', error)
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    };
    return (
        <>
            <canvas
                id="cardCanvas"
                ref={canvasRef}
                width={props?.canvasWidth}
                height={props?.canvasHeight}
                style={{display: 'none'}}
            />
            <CreateApplicationBtn
                onCreateApplicationBtnClick={() => handleDownload()}
                btnName="Create Card Image"
                disableCreateApp={isBtnDisabled}
                isLoading={isLoading}
                btnClassname="primary_btn"
            />
        </>

    )
}