/* DEALS */
export const GET_DEALS = "GET_DEALS"
export const GET_DEALS_SUCCESS = "GET_DEALS_SUCCESS"
export const GET_DEALS_FAIL = "GET_DEALS_FAIL"

/* DEALS DETAIL */
export const GET_DEAL_DETAIL = "GET_DEAL_DETAIL"
export const GET_DEAL_DETAIL_SUCCESS = "GET_DEAL_DETAIL_SUCCESS"
export const GET_DEAL_DETAIL_FAIL = "GET_DEAL_DETAIL_FAIL"

/**
 * CREATE DEAL RULE
 */
export const ADD_NEW_DEAL = "ADD_NEW_DEAL"
export const ADD_DEAL_SUCCESS = "ADD_DEAL_SUCCESS"
export const ADD_DEAL_FAIL = "ADD_DEAL_FAIL"

/**
 * Update DEAL RULE
 */
export const UPDATE_DEAL = "UPDATE_DEAL"
export const UPDATE_DEAL_SUCCESS = "UPDATE_DEAL_SUCCESS"
export const UPDATE_DEAL_FAIL = "UPDATE_DEAL_FAIL"