// icons
import ReadingPrgramIc from "assets/images/icons/sidebar_readingprogram.svg";
import CurriculumIc from "assets/images/icons/sidebar_curriculum.svg";
import WordIc from "assets/images/icons/sidebar_word.svg";
import WordiconPackIc from "assets/images/icons/sidebar_packs.svg";
import SheetIc from "assets/images/icons/sidebar_sheet.svg";
import CardIc from "assets/images/icons/sidebar_card.svg";

// utils
import { history } from "helpers/utils";

let roleBasedAllLinks = {
  1: {
    contents: [
      {
        name: "Tags Master",
        icon: CurriculumIc,
        routeName: "/tags",
        togglerName: "/tags",
        pathname: "tags",
      },
      {
        name: "User Listing",
        icon: ReadingPrgramIc,
        routeName: "/user-list",
        togglerName: "/user-list",
        pathname: "UserList",
      },
      {
        name: "Words Master",
        icon: WordIc,
        routeName: "/words",
        pathname: "words",
        togglerName: "/words",
        id: "1",
        showSubContent: false,
        subContent: [
          {
            name: "Word List",
            routeName: "/words",
            togglerName: "/words",
            pathname: "words",
          },
          {
            name: "Create New Word",
            routeName: "/word/detail",
            togglerName: "/word/detail",
            pathname: "detail",
          },
          {
            name: "Deleted Words List",
            routeName: "/word/deleted/list",
            togglerName: "/word/deleted/list",
            pathname: "DeletedList",
          },
        ],
      },
      {
        name: "Reading Program",
        icon: ReadingPrgramIc,
        routeName: "/readingprograms",
        pathname: "readingprograms",
        togglerName: "/readingprograms",
        id: "2",
        showSubContent: false,
        subContent: [
          {
            name: "Reading Programs List",
            routeName: "/readingprograms",
            togglerName: "/readingprograms",
            pathname: "readingprograms",
          },
          {
            name: "Create Reading Program",
            routeName: "/readingprogram/detail",
            togglerName: "/readingprogram/detail",
            pathname: "detail",
          },
        ],
      },
      {
        name: "Curriculums",
        icon: CurriculumIc,
        routeName: "/curriculum",
        togglerName: "/curriculum",
        pathname: "curriculum",
      },
      {
        name: "Wordicon Packs",
        icon: WordiconPackIc,
        routeName: "/packs",
        pathname: "packs",
        togglerName: "/packs",
        id: "3",
        showSubContent: false,
        subContent: [
          {
            name: "Pack List",
            routeName: "/packs",
            togglerName: "/packs",
            pathname: "packs",
          },
          {
            name: "Categories",
            icon: CurriculumIc,
            routeName: "/category",
            togglerName: "/category",
            pathname: "category",
          },
          {
            name: "Add New Pack",
            routeName: "/pack/detail",
            togglerName: "/pack/detail",
            pathname: "detail",
          },
        ],
      },
      {
        name: "TUB Sheets",
        icon: SheetIc,
        routeName: "/tubsheets",
        pathname: "tubsheets",
        togglerName: "/tubsheets",
        id: "4",
        showSubContent: false,
        subContent: [
          {
            name: "TUB Sheet List",
            routeName: "/tubsheets",
            togglerName: "/tubsheets",
            pathname: "tubsheets",
          },
          {
            name: "Add New TUB Sheet",
            routeName: "/sheet/detail",
            togglerName: "/sheet/detail",
            pathname: "detail",
          },
        ],
      },
      {
        name: "TUB Cards",
        icon: CardIc,
        routeName: "/tubcards",
        pathname: "tubcards",
        togglerName: "/tubcards",
        id: "5",
        showSubContent: false,
        subContent: [
          {
            name: "TUB Cards List",
            routeName: "/tubcards",
            togglerName: "/tubcards",
            pathname: "tubcards",
          },
          {
            name: "Add New TUB Card",
            routeName: "/card/detail",
            togglerName: "/card/detail",
            pathname: "detail",
          },
        ],
      },
      {
        name: "School",
        icon: CardIc,
        routeName: "/school",
        pathname: "school",
        togglerName: "/school",
        id: "6",
        showSubContent: false,
        subContent: [
          {
            name: "School Listing",
            routeName: "/school",
            togglerName: "/school",
            pathname: "school",
          },
          {
            name: "Admin Listing",
            routeName: "/user",
            togglerName: "/user",
            pathname: "user",
          },
        ],
      },
      {
        name: "Directors",
        icon: CardIc,
        routeName: "/director",
        pathname: "director",
        togglerName: "/director",
        id: "7",
        showSubContent: false,
        subContent: [
          {
            name: "Director Listing",
            routeName: "/director",
            togglerName: "/director",
            pathname: "director",
          },
          {
            name: "Hierarchy",
            routeName: "/hierarchy",
            togglerName: "/hierarchy",
            pathname: "hierarchy",
          },
          // {
          //   name: "User Listing",
          //   routeName: "/user",
          //   togglerName: "/user",
          //   pathname: "user",
          // }
        ],
      },
      {
        name: "Map Contents To User",
        icon: CurriculumIc,
        routeName: "/mapcontent/user",
        togglerName: "/mapcontent/user",
        pathname: "mapcontent",
      },
      {
        name: "Subscription Rule",
        icon: CardIc,
        routeName: "/subscriptions/rules",
        togglerName: "/subscriptions/rules",
        pathname: "subscriptions",
      },
      {
        name: "Deals",
        icon: ReadingPrgramIc,
        routeName: "/deals/list",
        togglerName: "/deals/list",
        pathname: "deals",
      },
      {
        name: "Combo",
        icon: CurriculumIc,
        routeName: "/combo/list",
        togglerName: "/combo/list",
        pathname: "combo",
      },
    ],
    "E-COMMERCE": [
      {
        name: "Marketplace",
        icon: WordiconPackIc,
        routeName: "/orders",
        pathname: "orders",
        togglerName: "/orders",
        id: "7",
        showSubContent: false,
        subContent: [
          {
            name: "Orders",
            routeName: "/orders",
            togglerName: "/orders",
            pathname: "orders",
          },
          {
            name: "Customers",
            icon: CurriculumIc,
            routeName: "/customers",
            togglerName: "/customers",
            pathname: "customers",
          },
          {
            name: "Coupons",
            routeName: "/coupons",
            togglerName: "/coupons",
            pathname: "coupons",
          },
        ],
      },
    ],
  },
  // 3 : [
  //     {
  //       name: "Orders",
  //       iconClass: "icon-premium",
  //       routeName: "/assigned/orders",
  //       pathname: 'assigned'
  //     }
  // ],
  get: function (roleId) {
    console.log("this[roleId]", this[roleId]);
    if (this[roleId]) return this[roleId];
    else return [];
  },
};

// let roleBasedLinks = {
//     1 : ['profile','userManagement'],
//     // 5 : ['profile',"reports",'application'],
//     3 : ['profile',"reports",'application'],
//     "home":['profile','home',"settings"],
//     'default' : ['profile','dashboard','application','reports'],
//     get : function(roleId) {
//       // console.log(roleId,":" ,this[roleId])
//         if(this[roleId])
//             return this[roleId]
//         else return this['default']
//     }
// }

export const getLinksBasedOnRoleId = (roleId = 1) => {
  // eslint-disable-next-line no-unused-vars
  let path = history.location.pathname.split("/").pop();
  // console.log(roleId,"*************")
  // let links = roleBasedLinks.get(roleId)
  // console.log(links,"links")
  // let filteredLink = TotalLinks.filter(data => links.includes(data.activeFilterName))
  let filteredLinks;
  console.log("roleBasedAllLinks.get(roleId)", roleBasedAllLinks.get(roleId));
  if (roleBasedAllLinks.get(roleId)) {
    // let CADApplicationIndex = filteredLink.findIndex(data => data.activeFilterName === 'application')
    // if(CADApplicationIndex >= 0){
    filteredLinks = roleBasedAllLinks.get(roleId);
    // let checkPathInLinks = filteredLinks.findIndex(data => data.pathname == path)
    // if(checkPathInLinks == -1)
    // filteredLink[CADApplicationIndex]['routeName'] = filteredLink[CADApplicationIndex].subContent[0].routeName
    // }
  }
  return filteredLinks;
};

//END SIDEBAR
