import {
  GET_SUBSCRIPTION_RULES_SUCCESS,
  GET_SUBSCRIPTION_RULE_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  subscriptionRulesList: [],
  subscriptionRuleDetail: {},
  error: {},
}

const wordiconPack = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_RULES_SUCCESS:
      return {
        ...state,
        subscriptionRulesList: action.payload,
      }
    case GET_SUBSCRIPTION_RULE_DETAIL_SUCCESS:
      return {
        ...state,
        subscriptionRuleDetail: action.payload,
      }


    default:
      return state
  }
}

export default wordiconPack
