import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
  Label,
} from "reactstrap";
import { API_ENDPOINTS } from "api/endpoints";
import FormInput from "components/FormInput";
import ReactSelect from "components/ReactSelect";
import BackBtn from "components/Button/backBtn";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Toast } from "helpers/Toast";
import {
  CONTENT_RATING_DROPDOWN,
  TUB_CARD_MOCK_DATA,
  TUB_SHEET_MOCK_DATA,
} from "helpers/variables";
import validate from "helpers/validation";
import { addNewTubCard } from "store/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ContentViewCard from "helpers/ContentViewCard";

import CreateApplicationBtn from "components/Button/createApplicationBtn";

const animatedComponents = makeAnimated();

const defaultData = {
  ObjectName: "",
  desc: "",
  isActive: true,
  ObjectImage: "",
  createdBy: "",
  tags: [],
  cardData: [],
};
const TubcardDetail = (props) => {
  const {
    match: { params },
  } = props;

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [tagsDropdown, setTagsDropdown] = useState([]);
  const [isCardImageCreated, setIsCardImageCreated] = useState(false);
  const [contentRatingsDropdown] = useState(CONTENT_RATING_DROPDOWN);
  const [cardDetail, setDetail] = useState({
    ObjectName: null,
    desc: "",
    isActive: true,
    ObjectImage: {
      original: "",
    },
    createdBy: "",
    tags: [],
    cardData: [],

    // categories: [
    //     {
    //         label: 'Food',
    //         value: 'Food'
    //     },
    //     {
    //         label: 'Nature',
    //         value: 'Nature'
    //     }
    // ],
    // offer_StartDate: null,
    // offer_EndDate: null,
    // offer_Price: null,
    // price: null
  });
  const [errors, setErrors] = useState({
    ObjectName: null,
    ObjectImage: "",
    tags: [],
    // offer_StartDate: null,
  });
  const [wordsDropdown, setWordDropdown] = useState([]);
  const [template, setTemplateData] = useState({});
  // const [categoriesDropdown] = useState([
  //     {
  //         label: 'Food',
  //         value: 'Food'
  //     },
  //     {
  //         label: 'Nature',
  //         value: 'Nature'
  //     }
  // ])
  const [canvasFile, saveCanvasFile] = useState();

  useEffect(() => {
    getApis();
  }, []);

  const getApis = async () => {
    setLoading(true);
    await getWords();
    await getTags();
    await getTemplate();
    if (params && params._id && !isEdit) {
      await getOneCardDetail(params._id);
    }
    setLoading(false);
  };

  const getTemplate = async () => {
    let res = await API_ENDPOINTS.template({
      languageId: "61d6d22f50e72435d4833026",
    });
    console.log("res", res);
    setTemplateData(res);
  };

  const getOneCardDetail = async (id) => {
    try {
      const res = await API_ENDPOINTS.getTubCardDetail(id);
      if (res && !_.isEmpty(res)) {
        setEdit(true);
        let cardDetails = {
          ...res,
          isActive: res?.isActive,
          tags: res.tags.map((dt) => ({ label: dt, value: dt })),
          // isDiscountAvailable: res?.isActive === 'Active' ? true : false,
          // content_For_Rating: { label: res.content_For_Rating, value: res.content_For_Rating },
          // categories: [
          //     {
          //         label: 'Food',
          //         value: 'Food'
          //     },
          //     {
          //         label: 'Nature',
          //         value: 'Nature'
          //     }
          // ],
          // offer_StartDate: res.offer_StartDate != null ? moment(res.offer_StartDate).format('YYYY-MM-DD') : null,
          // offer_EndDate: res.offer_EndDate != null ? moment(res.offer_EndDate).format('YYYY-MM-DD') : null,
        };
        let cardsData = [];
        if (!_.isEmpty(res.data)) {
          cardsData = res.data.map((prd) => ({
            label: prd?.imageTitle,
            value: prd._id,
            id: prd._id,
            data: { ...prd },
          }));
        }
        cardDetails = {
          ...cardDetails,
          cardData: cardsData,
        };
        setIsCardImageCreated(true);
        setDetail({ ...cardDetail, ...cardDetails });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getTags = async () => {
    let res = await API_ENDPOINTS.getTags(null);
    if (!_.isEmpty(res)) {
      let tagsd = res.map((r) => ({ label: r.tags, value: r._id }));
      setTagsDropdown(tagsd);
    }
  };

  const getWords = async () => {
    let res = await API_ENDPOINTS.getWords(null);
    if (!_.isEmpty(res)) {
      let wordsd = res.map((r) => ({
        label: r.title,
        value: r._id,
        data: {
          ...r,
          originalImageId: r._id,
          alphabetName: r.imageName,
          imageTitle: r.title,
          alphabetImage: {
            original: r.originalImage?.original,
            thumbnail: r.originalImage?.thumbnail,
          },
        },
      }));
      setWordDropdown(wordsd);
    }
  };

  const onChange = (name, value) => {
    if (name === "ObjectImage") {
      uploadImage(value);
    } else {
      setDetail({ ...cardDetail, [name]: value });
      setErrors({ ...errors, [name]: undefined });
    }
  };

  const saveCardDetail = async () => {
    // uploadImage(canvasFile)
    let validation = {
      ...inputValidations,
    };
    const notValid = validate(cardDetail, validation);

    let cardLength = cardDetail.cardData.length;
    if (notValid) {
      setErrors(notValid);
    } else {
      if (cardLength >= 2 && cardLength <= 9) {
        let payload = {
          ...cardDetail,
          ObjectType: "Card",
          tags: cardDetail?.tags.map((word) => word.label),
          isActive: cardDetail.isActive,
          AWS_UserName: localStorage.getItem("username"),
          data: cardDetail.cardData.map((dt) => ({ ...dt.data })),
          // offer_StartDate: cardDetail.offer_StartDate != null ? moment(cardDetail.offer_StartDate).format('YYYY-MM-DD') : null,
          // offer_EndDate: cardDetail.offer_EndDate != null ? moment(cardDetail.offer_EndDate).format('YYYY-MM-DD') : null,
          // content_For_Rating: cardDetail?.content_For_Rating?.value,
          // categories: cardDetail?.categories.map(word => word.label),
          // isDiscountAvailable: cardDetail.isDiscountAvailable ? 'Active' : 'InActive',
        };
        if (isEdit) {
          payload["_id"] = cardDetail._id;
        }

        dispatch(addNewTubCard(payload, props.history));
        setDetail({ ...cardDetail, ...defaultData });
      } else {
        Toast("error", "You can add only upto 9 images in card");
      }
    }
  };

  const onChangePackData = (value, action) => {
    setIsCardImageCreated(false);
    setDetail({
      ...cardDetail,
      ObjectImage: {
        original: "",
      },
      cardData: value,
    });
  };

  async function uploadImage(files) {
    let formData = new FormData();
    formData.append("image", files);
    try {
      const myInit = {
        body: formData,
      };
      const response = await API_ENDPOINTS.uploadImage(myInit);
      setDetail({
        ...cardDetail,
        ObjectImage: {
          original: response?.originalImage?.original,
          thumbnail: response?.originalImage?.original,
        },
      });
      setErrors({ ...errors, ObjectImage: undefined });
    } catch (error) {
      console.log("error", error);
    }
  }

  const saveCanvasDataUrlInState = (image) => {
    setIsCardImageCreated(true);
    setDetail({
      ...cardDetail,
      ObjectImage: {
        original: image?.originalImage?.original,
        thumbnail: image?.originalImage?.original,
      },
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {isLoading ? (
            <div>...Data Loading.....</div>
          ) : (
            <>
              <Row>
                <Col xs="12">
                  <div className="page-title-box d-flex">
                    <BackBtn history={props.history} />
                    <h4 className="mb-0 font-size-18 px-2">
                      {isEdit ? "EDIT TUB CARD" : "CREATE NEW TUB CARD"}
                    </h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h6 mb-4">
                        Input TUB Card Data
                      </CardTitle>
                      <div className="wizard clearfix">
                        <div className="content clearfix mt-4">
                          <div>
                            <FormInput
                              placeholder="Enter TUB Card Name"
                              name="ObjectName"
                              type="text"
                              formGroupClassName="row"
                              label="TUB Card Name"
                              maxLength={160}
                              labelClassName="col-md-2 col-form-label"
                              value={cardDetail["ObjectName"]}
                              error={errors?.ObjectName}
                              onChange={(...params) => onChange(...params)}
                            />
                            <FormInput
                              placeholder="Enter Description"
                              name="desc"
                              type="textarea"
                              formGroupClassName="row"
                              label="Description"
                              maxLength={160}
                              labelClassName="col-md-2 col-form-label"
                              value={cardDetail["desc"]}
                              error={errors?.desc}
                              onChange={(...params) => onChange(...params)}
                            />
                            <ReactSelect
                              name="tags"
                              label="Tag"
                              type="react-select"
                              formGroupClassName="row"
                              labelClassName="col-md-2 col-form-label"
                              placeholder="search & Add a tag"
                              isMulti={true}
                              isClearable={false}
                              closeMenuOnSelect={false}
                              maxLimit={5}
                              value={cardDetail["tags"]}
                              options={tagsDropdown}
                              onChange={(...params) => onChange(...params)}
                              error={errors.tags ? errors.tags : ""}
                            />
                            <FormInput
                              name="isActive"
                              formGroupClassName="row"
                              label="Status"
                              type={"two-way-switch"}
                              leftLabel="Inactive"
                              rightLabel="Active"
                              labelClassName="col-md-2 col-form-label"
                              value={cardDetail["isActive"]}
                              onChange={(...params) => onChange(...params)}
                            />
                          </div>
                          <Form>
                            <div className="mt-4">
                              <Row>
                                <Col lg={10}>
                                  <Row>
                                    <Col lg={3}>
                                      <FormInput
                                        placeholder=""
                                        name="data_Level"
                                        type="text"
                                        formGroupClassName="row"
                                        label="Total Words:"
                                        labelClassName="col-6 col-form-label"
                                        value={`${cardDetail.cardData.length}`}
                                        disabled={true}
                                        onChange={(...params) => null}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={10}>
                                  <div
                                    className={`templating-select select2-container pb-4`}
                                  >
                                    <Select
                                      options={wordsDropdown}
                                      defaultValue={cardDetail.cardData}
                                      value={cardDetail.cardData}
                                      isMulti={true}
                                      isDisabled={false}
                                      isClearable={true}
                                      placeholder={"search & Add a word"}
                                      classNamePrefix={`select2-selection`}
                                      closeMenuOnSelect={false}
                                      onChange={(value, action) =>
                                        onChangePackData(value, action)
                                      }
                                      isOptionDisabled={(option) =>
                                        cardDetail.cardData.length >= 9
                                          ? true
                                          : false
                                      }
                                      components={animatedComponents}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={10}>
                                  <div className="d-flex mt-1 column-gap-20 no-gutters flex-wrap">
                                    {cardDetail.cardData &&
                                      cardDetail.cardData.map((ac) => (
                                        <div className="assignment_content_container cursor-pointer">
                                          <div className="assignment_content_inner">
                                            <img
                                              src={
                                                ac?.data?.alphabetImage
                                                  ?.original
                                              }
                                              className=""
                                              alt=""
                                            />
                                          </div>
                                          <p>{ac?.data?.imageTitle}</p>
                                        </div>
                                      ))}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                          {cardDetail["ObjectImage"]?.original != "" && (
                            <Row>
                              <Col lg={3}>
                                <Label className={`col-form-label`}>
                                  Card Image
                                </Label>
                                <div className="image_wrapper p-15 logo_img_wrapper text-center mb-3">
                                  <img
                                    data-dz-thumbnail=""
                                    className="avatar-md rounded bg-light"
                                    alt={""}
                                    src={cardDetail["ObjectImage"]?.original}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )}
                          <div className="d-flex justify-flex-end">
                            <ContentViewCard
                              canvasWidth={template?.sizeId?.length}
                              canvasHeight={template?.sizeId?.breadth}
                              selections={cardDetail.cardData}
                              updateImage={saveCanvasDataUrlInState}
                            />
                            <CreateApplicationBtn
                              onCreateApplicationBtnClick={() =>
                                saveCardDetail()
                              }
                              btnName="Save"
                              disableCreateApp={!isCardImageCreated}
                              btnClassname="primary_btn mx-2"
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {/* {isEdit &&
                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <p
                                                        className="danger"
                                                        onClick={() => deleteProgram()}
                                                    >Delete Reading Program</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            } */}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export { TubcardDetail };

let inputValidations = {
  ObjectName: {
    presence: {
      allowEmpty: false,
      message: "^Card Name can't be blank",
    },
  },
  desc: {
    presence: {
      allowEmpty: false,
      message: "^Description can't be blank",
    },
  },
  tags: {
    presence: {
      allowEmpty: false,
      message: "^Tags can't be blank",
    },
  },
  ObjectImage: {
    presence: {
      allowEmpty: false,
      message: "^Card Image can't be blank",
    },
  },
};
