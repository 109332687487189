import {
  GET_CUSTOMERS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  customersList: [],
  success: '',
  error: {},
}

const customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customersList: action.payload,
      }
    default:
      return state
  }
}

export default customers
