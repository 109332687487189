import { call, put, takeEvery } from "redux-saga/effects"
import _ from 'lodash';

// Crypto Redux States
import { GET_DEALS , ADD_NEW_DEAL , UPDATE_DEAL } from "./actionTypes"

import {
  getDealsSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"
import { history } from "helpers/utils"
import { Toast } from "helpers/Toast"


function* fetchDeals(data) {
  try {
    const response = yield call(API_ENDPOINTS.getDealsList, {queryStringParameters: data.payload})
    yield put(getDealsSuccess(response))
  } catch (error) {
    Toast('error', error)
  }
}


function* onAddNewDeal(data) {
  try {
    let res = yield call(API_ENDPOINTS.createOrEditDeal, {body: data.payload})
  } catch (error) {
    Toast('error', error)
  }
}

function* updateDealDetail(data) {
  try {
    let body = {
      body: data.payload,
      queryStringParameters: {
        imageId: data.payload._id
      }
    }
    yield call(API_ENDPOINTS.createOrEditDeal, body)

  } catch (error) {
    console.log('error', error)
    Toast('error', error)
  }
}

function* wordsSaga() {
  yield takeEvery(GET_DEALS, fetchDeals)
  yield takeEvery(ADD_NEW_DEAL, onAddNewDeal)
  yield takeEvery(UPDATE_DEAL, updateDealDetail)
}

export default wordsSaga;
