import { FORM_LOADER } from "./actionTypes";

const INIT_STATE = {
    isFormLoading: false
}

const loader = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FORM_LOADER:
            return {
                ...state,
                isFormLoading: action.payload
            }
        default: 
            return state;
    }
}

export default loader;