import {
  USER_VALIDATION_ERROR,
  USER_VALIDATION,
  USER_VALIDATION_SUCCESS
} from "./actionTypes"

export const userEmailValidation = (user, history) => {
  return {
    type: USER_VALIDATION,
    payload: { user, history },
  }
}

export const userEmailValidationSuccess = message => {
  return {
    type: USER_VALIDATION_SUCCESS,
    payload: message,
  }
}

export const userEmailValidationError = forgetError => {
  return {
    type: USER_VALIDATION_ERROR,
    payload: forgetError,
  }
}
