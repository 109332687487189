import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { SETUP_NEW_PASSWORD } from "./actionTypes"
import { userSetPasswordSuccess, userSetPasswordError } from "./actions"

import { AwsSubmitNewPasswordWithOtp } from "helpers/AWSHelper"
import { Toast } from "helpers/Toast";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* setNewPassword({ payload: { user, history } }) {
  console.log('user-----', user);
  try {
    let response = yield AwsSubmitNewPasswordWithOtp(user.userEmail, user.verificationOTP, user.newPassword)
    Toast('success', 'success')
    yield put(
      userSetPasswordSuccess(
        "Password Set Successfully"
      )
    )
    history.push('/auth/login')
  } catch (error) {
    yield put(userSetPasswordError(error))
  }
}

export function* watchSetPassword() {
  yield takeEvery(SETUP_NEW_PASSWORD, setNewPassword)
}

function* setNewPasswordSaga() {
  yield all([fork(watchSetPassword)])
}

export default setNewPasswordSaga