import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form,
    Label
} from "reactstrap";
import { API_ENDPOINTS } from "api/endpoints";
import FormInput from "components/FormInput";
import ReactSelect from "react-select";
import CreateApplication from 'components/Button/createApplicationBtn'
import BackBtn from "components/Button/backBtn";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import trash from 'assets/images/bxs-trash.svg';
import { useDispatch } from "react-redux";
import {
    addNewReadingProgram,
    updateReadingProgram,
    addNewReadingProgramData,
    updateReadingProgramData
} from "store/curriculum/actions";
import classnames from "classnames"
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import _ from 'lodash';
import { history } from "helpers/utils";
import { Toast } from "helpers/Toast";
import { MdEdit } from "react-icons/md";

let defaultPrData = [{
    title: '',
    reading_Program_Hierarachy: '',
    status: false,
    isEdit:true,
    data_Level: '',
    data: [],
}]

const ReadingProgramDetail = (props) => {
    const { match: { params } } = props

    const dispatch = useDispatch();

    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [isLoading, setLoading] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const [programDetail, setDetail] = useState({
        program_Name: null,
        description: '',
        status: true,
        logo: {
            original: '',
            thumbnail: ''
        },
        createdBy: ''
    })
    const [wordsDropdown, setWordDropdown] = useState([])
    const [programData, setProgramData] = useState([])
    const [isNextDisabled, setIsNextDisabled] = useState(true)

    useEffect(() => {
        getApis()
    }, []);

    const getApis = async () => {
        setLoading(true)
        await getWords()
        if (params && params._id && !isEdit) {
            await getProgramDetail(params._id)
        } else {
            let pgrData = [...defaultPrData]
            setProgramData(pgrData)
        }
        setLoading(false)
    }
    const getProgramDetail = async (id) => {
        try {
            const res = await API_ENDPOINTS.getReadingProgramDetail(id)
            if (res && !_.isEmpty(res)) {
                setEdit(true)
                setDetail({
                    ...res.ReadingProgram,
                    status: res.ReadingProgram.status === 'Active' ? true : false,
                })
                if(!_.isEmpty(res.ReadingProgramData)){
                    let resPrData = []
                    res.ReadingProgramData.map(prd => {
                        resPrData.push({
                            ...prd,
                            data: prd.data.map(dt => ({label: dt?.imageTitle, value: dt._id, id: dt._id,data: {...dt}}) ),
                            isEdit: false
                        })
                    })
                    setProgramData(resPrData)
                } else {
                    setProgramData(defaultPrData)
                }
            }
        } catch (error) {
            setLoading(false)
        }
    }
    const getWords = async () => {
        let res = await API_ENDPOINTS.getWords(null)
        if (!_.isEmpty(res)) {
            let wordsd = res.map(r => ({ label: r.title, value: r._id, data: { ...r,originalImageId: r._id, alphabetName: r.imageName, imageTitle: r.title,alphabetImage: { original: r.originalImage?.original, thumbnail: r.originalImage?.thumbnail} } }))
            setWordDropdown(wordsd)
        }
    }

    const onAddFormRow = () => {
        const modifiedRows = [...programData];
        modifiedRows.push({
            title: '',
            reading_Program_Hierarachy: '',
            status: false,
            data_Level: '',
            data: [],
            isEdit: true
        });
        setProgramData(modifiedRows);
    };

    const onDeleteFormRow = i => {
        var modifiedRows = [...programData];
        modifiedRows.splice(i, 1);
        setProgramData(modifiedRows);
    };

    const onChange = (name, value) => {
        if (name == 'logo') {
            uploadImage(value)
        }
        setDetail({ ...programDetail, [name]: value })
    }

    const onChangePrgramData = (name, value, i) => {
        let prData = [...programData]
        prData[i][name] = value
        setProgramData(prData)


    }
    const form1 = useFormik({
        enableReinitialize: true,
        initialValues: {
            program_Name: (programDetail && programDetail.program_Name) || "",
            description: (programDetail && programDetail.description) || "",
        },
        validationSchema: Yup.object({
            program_Name: Yup.string().required("Please Enter Program Name"),
            description: Yup.string().required("Please Enter Description"),
            // logo: Yup.mixed().required('File is required'),
        }),
        onSubmit: async (values) => {
            let body = {
                AWS_UserName: localStorage.getItem('username'),
            }
            if (isEdit) {
                body = {
                    ...values,
                    ...body,
                    _id: programDetail._id,
                    status: programDetail.status ? 'Active' : 'InActive',
                    program_Name: values.program_Name.charAt(0).toUpperCase() + values.program_Name.slice(1),
                    logo: programDetail.logo
                }
            } else {
                body = {
                    ...body,
                    ...values,
                    program_Name: values.program_Name.charAt(0).toUpperCase() + values.program_Name.slice(1),
                    _id: programDetail?._id,
                    status: programDetail.status ? 'Active' : 'InActive',
                    logo: programDetail.logo
                }
            }
            let payload = {
                body: body
            }
            let res = await API_ENDPOINTS.createOrEditReadingProgram(payload)
            setDetail({...programDetail, ...res})
            // setactiveTab(2)
        }
    });
    const deleteProgram = async () => {
        let payload = {
            body: {
                _id: programDetail._id,
                isDeleted: true
            }
        }
        let res = await API_ENDPOINTS.createOrEditReadingProgram(payload)
        // Toast('success', 'Program Deleted Successfully')
        history.goBack()
    }
    const saveProgramData = async (index) => {
        let programDataOfIndex = programData[index] 
        let isValid = programData.every(pr => pr.title && pr.reading_Program_Hierarachy && pr.data_Level && pr.data.length > 0)
        if(isValid){
            let body = {
                ...programDataOfIndex,
                reading_Program_Name: programDetail.program_Name,
                reading_Program_Id: programDetail._id,
                createdBy: localStorage.getItem('username'),
                data: programDataOfIndex.data.map(dt => ({...dt.data})),
                status: 'Active'
            }
            let payload = {
                body: body
            }
            let res = await API_ENDPOINTS.createOrEditReadingProgramInnerData(payload)
            let prData = [...programData]
            prData[index]["isEdit"] = false
            
            setProgramData(prData);

        } else Toast('error', 'Fill all details')
    }

    const deleteProgramData = async (index) => {
        let programDataOfIndex = programData[index] 
        let prData = [...programData]
        if(_.isEmpty(programDataOfIndex._id)){
            prData.splice(index, 1);
        } else {
            let body = {
                ...programDataOfIndex,
                reading_Program_Name: programDetail.program_Name,
                reading_Program_Id: programDetail._id,
                createdBy: localStorage.getItem('username'),
                data: programDataOfIndex.data.map(dt => ({...dt.data})),
                status: 'Active',
                isDeleted: true
            }
            let payload = {
                body: body
            }
            let res = await API_ENDPOINTS.createOrEditReadingProgramInnerData(payload)
            prData[index]["isEdit"] = false
            prData.splice(index, 1);
        }
        setProgramData(prData);
    }

    async function uploadImage(files) {
        let formData = new FormData()
        formData.append('image', files)
        try {
            const myInit = {
                body: formData,
            };
            const response = await API_ENDPOINTS.uploadImage(myInit)
            setDetail({
                ...programDetail,
                logo: {
                    original: response?.originalImage?.original,
                    thumbnail: response?.originalImage?.original
                }
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    const onEditCustomPrData = (index) => {
        let prData = [...programData]
        prData[index]["isEdit"] = true
        setProgramData(prData)
    }

    

    const setShowNext = () => {
        let disabeld = true
        if(isEdit){
            disabeld = false
        } else if(!_.isEmpty(programDetail._id)){
            disabeld = false
        }
        return disabeld
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (
                        <div>...Data Loading.....</div>
                    ) :
                        <>
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box d-flex">
                                        <BackBtn history={props.history} />
                                        <h4 className="mb-0 font-size-18 px-2">{isEdit ? "Edit Reading Program" : 'Add New Reading Program'}</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="h4 mb-4">Reading Program Details</CardTitle>
                                            <div className="wizard clearfix">
                                                <div className="steps clearfix">
                                                    <ul>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 1 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ current: activeTab === 1 })}
                                                                onClick={() => {
                                                                    setactiveTab(1)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(1)}
                                                            >
                                                                <span className="number">01</span> Basic Detail
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 2 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 2 })}
                                                                onClick={() => {
                                                                    setactiveTab(2)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(2)}
                                                            >
                                                                <span className="number ms-2">02</span> Reading Program Structure
                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                <div className="content clearfix mt-4">
                                                    <TabContent activeTab={activeTab}>
                                                        <TabPane tabId={1}>
                                                            <Form
                                                                onSubmit={e => {
                                                                    e.preventDefault();
                                                                    form1.handleSubmit();
                                                                    return false;
                                                                }}
                                                            >
                                                                <Row>
                                                                    <Col>
                                                                        <FormikFormInput
                                                                            placeholder="Enter Reading Program Name"
                                                                            name="program_Name"
                                                                            type='text'
                                                                            formGroupClassName="row mb-3"
                                                                            label='Program Name'
                                                                            maxLength = {160}
                                                                            labelClassName="col-md-2 col-form-label"
                                                                            value={programDetail['program_Name']}
                                                                            validation={form1}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <FormikFormInput
                                                                            placeholder="Enter Description"
                                                                            name="description"
                                                                            type='textarea'
                                                                            formGroupClassName="row mb-3"
                                                                            label='Description'
                                                                            maxLength = {160}
                                                                            labelClassName="col-md-2 col-form-label"
                                                                            value={programDetail['description']}
                                                                            validation={form1}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <LogoUpload
                                                                            title="Upload Logo"
                                                                            name="logo"
                                                                            groupClassName="row mb-3"
                                                                            labelClassName="col-md-2"
                                                                            preview={programDetail['logo']?.original}
                                                                            onChange={(...params) => onChange(...params)}
                                                                            validation={form1}
                                                                            removeImage={() => setDetail({ ...programDetail, logo: { original: '' } })}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <FormInput
                                                                            name="status"
                                                                            formGroupClassName="row"
                                                                            label='Status'
                                                                            type={"two-way-switch"}
                                                                            leftLabel="Inactive"
                                                                            rightLabel="Active"
                                                                            labelClassName="col-md-2 col-form-label"
                                                                            value={programDetail['status']}
                                                                            onChange={(...params) => onChange(...params)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                                <div className="d-flex justify-flex-end">
                                                                    <button
                                                                        type="submit"
                                                                        className="primary_btn create_button"
                                                                    >
                                                                        Save
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className={`mx-3 ${setShowNext() ? 'secondary_btn' : 'primary_btn'} create_button`}
                                                                        onClick={() => setactiveTab(2)}
                                                                        disabled={setShowNext()}
                                                                    >
                                                                        Next
                                                                    </button>
                                                                </div>
                                                            </Form>
                                                        </TabPane>
                                                        <TabPane tabId={2}>
                                                            <Form>
                                                                {programData && programData.map((data, i) => (
                                                                    <div>
                                                                        <Row>
                                                                            <Col lg={10}>
                                                                                <Row>
                                                                                    <Col lg={3}>
                                                                                        {
                                                                                            data["isEdit"] ?
                                                                                            <FormInput
                                                                                                placeholder=""
                                                                                                name="data_Level"
                                                                                                type='text'
                                                                                                formGroupClassName="row"
                                                                                                label='Placement #'
                                                                                                labelClassName="col-6 col-form-label"
                                                                                                value={data['data_Level']}
                                                                                                onChange={(...params) => onChangePrgramData(...params, i)}
                                                                                            />
                                                                                            :
                                                                                            <Label>Placement # {data['data_Level']}</Label>
                                                                                        }
                                                                                    </Col>
                                                                                    <Col>
                                                                                        {data['isEdit'] ?
                                                                                            <div className="d-flex justify-content-end align-items-center">                                                                                                
                                                                                                <CreateApplication
                                                                                                    onCreateApplicationBtnClick={() => saveProgramData(i)}
                                                                                                    btnName="Save Changes"
                                                                                                    btnClassname="px-2 mx-2 text-white primary_btn"
                                                                                                    disableCreateApp={!data?.isEdit}
                                                                                                />
                                                                                                {/* <div className="mx-1 cursor-pointer">
                                                                                                    <MdEdit size={20} onClick={() => onEditCustomPrData(i)} />
                                                                                                </div> */}
                                                                                                <div>
                                                                                                    <img src={trash} onClick={() => deleteProgramData(i)} />
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="d-flex justify-content-end align-items-center mx-1 cursor-pointer">
                                                                                                <MdEdit size={20} onClick={() => onEditCustomPrData(i)} />
                                                                                            </div>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={10}>
                                                                                {
                                                                                    data["isEdit"] ?
                                                                                    <FormInput
                                                                                        placeholder="Unit Segments"
                                                                                        name="reading_Program_Hierarachy"
                                                                                        type='text'
                                                                                        value={data['reading_Program_Hierarachy']}
                                                                                        onChange={(...params) => onChangePrgramData(...params, i)}
                                                                                    />
                                                                                    :
                                                                                    <Label>{data['reading_Program_Hierarachy']}</Label>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                        {data['isEdit'] &&
                                                                            <>
                                                                            <Row>
                                                                                <Col lg={10}>
                                                                                    <FormInput
                                                                                        placeholder="Topic"
                                                                                        name="title"
                                                                                        type='text'
                                                                                        value={data['title']}
                                                                                        onChange={(...params) => onChangePrgramData(...params, i)}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col lg={10} >
                                                                                    <ReactSelect
                                                                                        name="data"
                                                                                        formGroupClassName="mb-3"
                                                                                        placeholder="search & Add a word"
                                                                                        isMulti={true}
                                                                                        isClearable = {true}
                                                                                        value={data['data']}
                                                                                        maxLimit = {9}
                                                                                        closeMenuOnSelect = {false}
                                                                                        options={wordsDropdown}
                                                                                        onChange={(value) => onChangePrgramData('data', value, i)}
                                                                                        isOptionDisabled={(option) => data['data'].length >= 9}
                                                                                    />
                                                                                </Col>
                                                                            </Row>
                                                                        </>
                                                                        }
                                                                        <Row >
                                                                            <Col lg={10}>
                                                                                <div className='d-flex mt-1 column-gap-20 no-gutters'>
                                                                                {data['data'] && data['data'].map(ac => (
                                                                                    <div
                                                                                        className='assignment_content_container cursor-pointer'
                                                                                    >
                                                                                        <div className='assignment_content_inner'>
                                                                                            <img src={ac?.data?.alphabetImage?.original} className="" />
                                                                                        </div>
                                                                                        <p>{ac?.data?.imageTitle}</p>
                                                                                    </div>
                                                                                ))}
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                )
                                                                )}
                                                            </Form>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className="primary_btn mt-3 create_button"
                                                                    onClick={() => onAddFormRow()}
                                                                >Add</button>
                                                            </div>
                                                            
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {isEdit &&
                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <p 
                                                        className="danger"
                                                        onClick={() => deleteProgram()}
                                                    >Delete Reading Program</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </>}
                </Container>
            </div>
        </React.Fragment>
    )
}

export { ReadingProgramDetail }