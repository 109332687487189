import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form,
    Label
} from "reactstrap";
import { API_ENDPOINTS } from "api/endpoints";
import FormInput from "components/FormInput";
import ReactSelect from "components/ReactSelect";
import CreateApplication from 'components/Button/createApplicationBtn'
import BackBtn from "components/Button/backBtn";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import trash from 'assets/images/bxs-trash.svg';
import { useDispatch } from "react-redux";
import classnames from "classnames"
import _ from 'lodash';
import { history } from "helpers/utils";
import { Toast } from "helpers/Toast";
import { MdEdit } from "react-icons/md";
import { CONTENT_RATING_DROPDOWN } from "helpers/variables";
import validate from "helpers/validation";
import { addNewWordiconPack } from "store/wordiconPacks/actions";
import moment from "moment";
import Select from 'react-select'

let defaultPackDetail = {
    pack_Name: '',
    description: '',
    status: true,
    isPublished: false,
    isFree: false,
    pack_Image: '',
    createdBy: '',
    tags: [],
    offer_StartDate: '',
    categories: [
        {
            label: 'Food',
            value: 'Food'
        },
        {
            label: 'Nature',
            value: 'Nature'
        }
    ]
}
const PackDetail = (props) => {
    const { match: { params } } = props

    const dispatch = useDispatch();

    const [activeTab, setactiveTab] = useState(1)
    const [passedSteps, setPassedSteps] = useState([1])
    const [isLoading, setLoading] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const [tagsDropdown, setTagsDropdown] = useState([])
    const [categoriesDropdown, setCategoriesDropdown] = useState()
    const [contentRatingsDropdown] = useState(CONTENT_RATING_DROPDOWN)
    const [packDetail, setDetail] = useState({
        pack_Name: null,
        description: '',
        status: false,
        isPublished: false,
        isFree: false,
        pack_Image: '',
        createdBy: '',
        tags: [],
        categories: [],
        offer_StartDate: null,
        offer_EndDate: null,
        offer_Price: null,
        price: null
    })
    const [errors, setErrors] = useState({
        pack_Name: null,
        pack_Image: '',
        tags: [],
        offer_StartDate: null,
    })
    const [wordsDropdown, setWordDropdown] = useState([])
    const [packData, setPackData] = useState([])

    useEffect(() => {
        getApis()
    }, []);

    const getApis = async () => {
        setLoading(true)
        await getCategorie()
        if (params && params._id && !isEdit) {
            await getOnePackDetail(params._id)
        }
        await getWords()
        await getTags()
        
        setLoading(false)
    }

    const getCategorie = async () => {
        let res = await API_ENDPOINTS.getCategories(null)
        if (!_.isEmpty(res)) {
            let cats = res.map(r => ({ label: r.category_Name, value: r._id }))
            setCategoriesDropdown(cats)
            return  cats
        }
    }
    const getOnePackDetail = async (id) => {
        try {
            const res = await API_ENDPOINTS.getPackDetail(id)
            if (res && !_.isEmpty(res)) {
                setEdit(true)
                setDetail({
                    ...res,
                    status: res?.status === 'Active' ? true : false,
                    isPublished: res?.isPublished,
                    isFree: res?.isFree,
                    isDiscountAvailable: res?.status === 'Active' ? true : false,
                    content_For_Rating: { label: res.content_For_Rating, value: res.content_For_Rating },
                    categories: res.categories.map(dt => ({ label: dt, value: dt })),
                    tags: res.tags.map(dt => ({ label: dt, value: dt })),
                    offer_StartDate: res.offer_StartDate != null ? moment(res.offer_StartDate).format('YYYY-MM-DD') : null,
                    offer_EndDate: res.offer_EndDate != null ? moment(res.offer_EndDate).format('YYYY-MM-DD') : null,
                })

                if (!_.isEmpty(res.Wordicons)) {
                    let resPrData = []
                    resPrData = res.Wordicons[0]["wordicon_Data"].map(prd => ({ label: prd?.imageTitle, value: prd._id, _id: res.Wordicons[0]["_id"], data: { ...prd } }))
                    setPackData(resPrData)
                } else {
                    setPackData([])
                }
            }
        } catch (error) {
        }
    }

    const getTags = async () => {
        let res = await API_ENDPOINTS.getTags(null)
        if (!_.isEmpty(res)) {
            let tagsd = res.map(r => ({ label: r.tags, value: r._id }))
            setTagsDropdown(tagsd)
        }
    }

    const getWords = async () => {
        let res = await API_ENDPOINTS.getWords(null)
        if (!_.isEmpty(res)) {
            let wordsd = res.map(r => ({ label: r.title, value: r._id, data: { ...r, originalImageId: r._id, alphabetName: r.imageName, imageTitle: r.title, alphabetImage: { original: r.originalImage?.original, thumbnail: r.originalImage?.thumbnail } } }))
            setWordDropdown(wordsd)
        }
    }

    const onChange = (name, value) => {
        if (name === 'pack_Image') {
            uploadImage(value)
        } else {
            setDetail({ ...packDetail, [name]: value })
            setErrors({ ...errors, [name]: undefined })
        }
    }

    const savePackDetail = async () => {
        let validation = {
            ...inputValidations
        }
        const notValid = validate(packDetail, validation);
        if (notValid) {
            setErrors(notValid)
        } else {
            let newtags = packDetail?.tags.map(word => word.label)
            let newcategories =  packDetail?.categories.map(word => word.label)
            let payload = {
                ...packDetail,
                offer_StartDate: packDetail.offer_StartDate != null ? moment(packDetail.offer_StartDate).format('YYYY-MM-DD') : null,
                offer_EndDate: packDetail.offer_EndDate != null ? moment(packDetail.offer_EndDate).format('YYYY-MM-DD') : null,
                content_For_Rating: packDetail?.content_For_Rating?.value,
                tags: newtags,
                categories: newcategories,
                status: packDetail.status ? 'Active' : 'InActive',
                isPublished: packDetail.isPublished,
                isFree: packDetail.isFree,
                AWS_UserName: localStorage.getItem('username'),
                isDiscountAvailable: packDetail.isDiscountAvailable ? 'Active' : 'InActive',
            }
            if (isEdit) {
                payload["_id"] = packDetail._id
            }
            let res = await API_ENDPOINTS.createOrEditWordiconPack({body: payload})
            setDetail({ 
                ...packDetail, 
                ...res,
                content_For_Rating: packDetail?.content_For_Rating,
                offer_StartDate: packDetail.offer_StartDate,
                offer_EndDate: packDetail.offer_EndDate,
                categories: packDetail?.categories,
                tags: packDetail?.tags
            })
        }
    }

    const onChangePackData = ( value) => {
        setPackData(value)
    }

    const savePackData = async (index) => {
        if (packData.length > 1) {
            let body = {
                wordicon_Data: packData.map(dt => ({ ...dt.data })),
                tub_ObjectType: 'Wordicon',
                status: 'Active',
                AWS_UserName: localStorage.getItem('username'),
                tub_PackId: packDetail["_id"]
            }
            if(isEdit){
                body["_id"] = packData[0]["_id"]
            }
            let payload = {
                body: body
            }
            
            let res = await API_ENDPOINTS.createOrEditPackInnerData(payload)


        } else Toast('error', 'Choose at least two wordicon')
    }

    async function uploadImage(files) {
        let formData = new FormData()
        formData.append('image', files)
        try {
            const myInit = {
                body: formData,
            };
            const response = await API_ENDPOINTS.uploadImage(myInit)
            setDetail({
                ...packDetail,
                pack_Image: {
                    original: response?.originalImage?.original,
                    thumbnail: response?.originalImage?.original
                }
            })
            setErrors({ ...errors, pack_Image: undefined })
        } catch (error) {
            console.log('error', error)
        }
    }

    const setShowNext = () => {
        let disabeld = true
        if (isEdit) {
            disabeld = false
        } else if (!_.isEmpty(packDetail._id)) {
            disabeld = false
        }
        return disabeld
    }

    const setNextTab = (tab) => {
        setactiveTab(tab)
    }

    const deletePack = async () => {
        let newtags = packDetail?.tags.map(word => word.label)
        let newcategories =  packDetail?.categories.map(word => word.label)
        let payload = {
                ...packDetail,
                offer_StartDate: packDetail.offer_StartDate != null ? moment(packDetail.offer_StartDate).format('YYYY-MM-DD') : null,
                offer_EndDate: packDetail.offer_EndDate != null ? moment(packDetail.offer_EndDate).format('YYYY-MM-DD') : null,
                content_For_Rating: packDetail?.content_For_Rating?.value,
                tags: newtags,
                categories: newcategories,
                status: packDetail.status ? 'Active' : 'InActive',
                isPublished: false,
                isFree: packDetail.isFree,
                AWS_UserName: localStorage.getItem('username'),
                isDiscountAvailable: packDetail.isDiscountAvailable ? 'Active' : 'InActive',
                _id: packDetail._id,
                isDeleted: true,
        }
        let res = await API_ENDPOINTS.createOrEditWordiconPack({body: payload})
        // Toast('success', 'Program Deleted Successfully')
        history.goBack()
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (
                        <div>...Data Loading.....</div>
                    ) :
                        <>
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box d-flex">
                                        <BackBtn history={props.history} />
                                        <h4 className="mb-0 font-size-18 px-2">{isEdit ? "EDIT WORDICON PACK" : 'ADD NEW WORDICON PACK'}</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="h4 mb-4">Input Wordicon Pack Data</CardTitle>
                                            <div className="wizard clearfix">
                                                <div className="steps clearfix">
                                                    <ul>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 1 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ current: activeTab === 1 })}
                                                                onClick={() => {
                                                                    setactiveTab(1)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(1)}
                                                            >
                                                                <span className="number">01</span> Basic Detail
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem
                                                            className={classnames({ current: activeTab === 2 })}
                                                        >
                                                            <NavLink
                                                                className={classnames({ active: activeTab === 2 })}
                                                                onClick={() => {
                                                                    setactiveTab(2)
                                                                }}
                                                                disabled={!(passedSteps || []).includes(2)}
                                                            >
                                                                <span className="number ms-2">02</span>Pack Data
                                                            </NavLink>
                                                        </NavItem>
                                                    </ul>
                                                </div>
                                                <div className="content clearfix mt-4">
                                                    <TabContent activeTab={activeTab}>
                                                        <TabPane tabId={1}>
                                                            <FormInput
                                                                placeholder="Enter Pack Name"
                                                                name="pack_Name"
                                                                type='text'
                                                                formGroupClassName="row"
                                                                label='Wordicon Pack Name'
                                                                maxLength={70}
                                                                labelClassName="col-md-2 col-form-label"
                                                                value={packDetail['pack_Name']}
                                                                error={errors?.pack_Name}
                                                                onChange={(...params) => onChange(...params)}
                                                            />
                                                            <FormInput
                                                                placeholder="Enter Description"
                                                                name="description"
                                                                type='textarea'
                                                                formGroupClassName="row"
                                                                label='Description'
                                                                maxLength={160}
                                                                labelClassName="col-md-2 col-form-label"
                                                                value={packDetail['description']}
                                                                error={errors?.description}
                                                                onChange={(...params) => onChange(...params)}
                                                            />
                                                             <FormInput
                                                                name="isFree"
                                                                formGroupClassName="row"
                                                                label='Is Content Free'
                                                                type={"two-way-switch"}
                                                                leftLabel="False"
                                                                rightLabel="True"
                                                                labelClassName="col-md-2 col-form-label"
                                                                value={packDetail['isFree']}
                                                                onChange={(...params) => onChange(...params)}
                                                            />
                                                            {!packDetail['isFree'] &&
                                                                <>
                                                                    <FormInput
                                                                        placeholder=""
                                                                        name="price"
                                                                        type='price'
                                                                        formGroupClassName="row"
                                                                        label='Price'
                                                                        labelClassName="col-md-2 col-form-label"
                                                                        value={packDetail['price']}
                                                                        onChange={(...params) => onChange(...params)}
                                                                    />
                                                                    <FormInput
                                                                        placeholder=""
                                                                        name="offer_Price"
                                                                        type='price'
                                                                        formGroupClassName="row"
                                                                        label='Offer Price'
                                                                        labelClassName="col-md-2 col-form-label"
                                                                        value={packDetail['offer_Price']}
                                                                        onChange={(...params) => onChange(...params)}
                                                                    />
                                                                    <FormInput
                                                                        name1="offer_StartDate"
                                                                        name2="offer_EndDate"
                                                                        type='flat-datepicker'
                                                                        formGroupClassName="row"
                                                                        label='Offer Dates'
                                                                        labelClassName="col-md-2 col-form-label"
                                                                        value1={packDetail['offer_StartDate']}
                                                                        value2={packDetail['offer_EndDate']}
                                                                        onChangeDate1={(...params) => onChange(...params)}
                                                                        onChangeDate2={(...params) => onChange(...params)}
                                                                    />
                                                                </>
                                                                
                                                            }
                                                            
                                                            
                                                            <ReactSelect
                                                                name="tags"
                                                                label='Tag'
                                                                type="react-select"
                                                                formGroupClassName="row"
                                                                labelClassName="col-md-2 col-form-label"
                                                                placeholder="search & Add a tag"
                                                                isMulti={true}
                                                                isClearable={false}
                                                                closeMenuOnSelect={false}
                                                                maxLimit={5}
                                                                value={packDetail['tags']}
                                                                options={tagsDropdown}
                                                                onChange={(...params) => onChange(...params)}
                                                                error={errors.tags ? errors.tags : ''}
                                                            />
                                                           
                                                            {!packDetail['isFree'] &&
                                                                <FormInput
                                                                    name="isDiscountAvailable"
                                                                    formGroupClassName="row"
                                                                    label='Discount'
                                                                    type={"two-way-switch"}
                                                                    leftLabel="No"
                                                                    rightLabel="Yes"
                                                                    labelClassName="col-md-2 col-form-label"
                                                                    value={packDetail['isDiscountAvailable']}
                                                                    onChange={(...params) => onChange(...params)}
                                                                />
                                                            }
                                                            <FormInput
                                                                name="status"
                                                                formGroupClassName="row"
                                                                label='Status'
                                                                type={"two-way-switch"}
                                                                leftLabel="Inactive"
                                                                rightLabel="Active"
                                                                labelClassName="col-md-2 col-form-label"
                                                                value={packDetail['status']}
                                                                onChange={(...params) => onChange(...params)}
                                                            />
                                                            <FormInput
                                                                name="isPublished"
                                                                formGroupClassName="row"
                                                                label='Publish'
                                                                type={"two-way-switch"}
                                                                leftLabel="No"
                                                                rightLabel="Yes"
                                                                labelClassName="col-md-2 col-form-label"
                                                                value={packDetail['isPublished']}
                                                                onChange={(...params) => onChange(...params)}
                                                            />
                                                            
                                                            <ReactSelect
                                                                name="categories"
                                                                label='Categories'
                                                                formGroupClassName="row"
                                                                labelClassName="col-md-2 col-form-label"
                                                                placeholder="search & Add a Category"
                                                                isMulti={true}
                                                                isClearable={false}
                                                                isDisabled={false}
                                                                closeMenuOnSelect={false}
                                                                maxLimit={3}
                                                                value={packDetail['categories']}
                                                                options={categoriesDropdown}
                                                                onChange={(...params) => onChange(...params)}
                                                                error={''}
                                                            />
                                                            <ReactSelect
                                                                name="content_For_Rating"
                                                                label='Content Rating'
                                                                formGroupClassName="row"
                                                                labelClassName="col-md-2 col-form-label"
                                                                placeholder="Select Rating"
                                                                isMulti={false}
                                                                isClearable={false}
                                                                closeMenuOnSelect={true}
                                                                maxLimit={1}
                                                                value={packDetail['content_For_Rating']}
                                                                options={contentRatingsDropdown}
                                                                onChange={(...params) => onChange(...params)}
                                                                error={errors?.content_For_Rating}

                                                            />
                                                            <LogoUpload
                                                                title="Pack Cover Image"
                                                                name="pack_Image"
                                                                groupClassName="row"
                                                                labelClassName="col-md-2"
                                                                preview={packDetail['pack_Image']?.original}
                                                                onChange={(...params) => onChange(...params)}
                                                                error={errors?.pack_Image ? errors?.pack_Image[0] : ''}
                                                                removeImage={() => setDetail({ ...packDetail, pack_Image: { original: '' } })}
                                                            />
                                                            <div className="d-flex justify-flex-end">
                                                                <button
                                                                    type="submit"
                                                                    className="primary_btn create_button"
                                                                    onClick={() => savePackDetail()}
                                                                >
                                                                    Save
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className={`mx-3 ${setShowNext() ? 'secondary_btn' : 'primary_btn'} create_button`}
                                                                    onClick={() => {
                                                                        setNextTab(2)
                                                                    }}
                                                                    disabled={setShowNext()}
                                                                >
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </TabPane>
                                                        <TabPane tabId={2}>
                                                            <Form>
                                                                <div>
                                                                    <Row>
                                                                        <Col lg={10}>
                                                                            <Row>
                                                                                <Col lg={3}>
                                                                                    <FormInput
                                                                                        placeholder=""
                                                                                        name="data_Level"
                                                                                        type='text'
                                                                                        formGroupClassName="row"
                                                                                        label='Total Words:'
                                                                                        labelClassName="col-6 col-form-label"
                                                                                        value={packData.length}
                                                                                        disabled={true}
                                                                                        onChange={(...params) => null}
                                                                                    />
                                                                                </Col>
                                                                                {!packDetail["isPublished"] &&
                                                                                    <Col>
                                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                                            <CreateApplication
                                                                                                onCreateApplicationBtnClick={() => savePackData()}
                                                                                                btnName="Save Changes"
                                                                                                btnClassname="px-2 mx-2 text-white primary_btn"
                                                                                                disableCreateApp={false}
                                                                                            />
                                                                                            {/* <div>
                                                                                                <img src={trash} onClick={() => deleteProgramData()} alt="" />
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </Col>
                                                                                    }
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg={10} >
                                                                            <Select
                                                                                name="packData"
                                                                                formGroupClassName=""
                                                                                placeholder="search & Add a word"
                                                                                isMulti={true}
                                                                                isClearable={true}
                                                                                // maxLimit={100}
                                                                                closeMenuOnSelect={false}
                                                                                // isDisabled = {packDetail["isPublished"] ? true : false}
                                                                                options={wordsDropdown}
                                                                                onChange={(value) => onChangePackData(value)}
                                                                                value={packData}
                                                                                error={''}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row >
                                                                        <Col lg={10}>
                                                                            <div className='d-flex mt-1 column-gap-20 no-gutters flex-wrap'>
                                                                                {packData && packData.map(ac => (
                                                                                    <div
                                                                                        className='assignment_content_container cursor-pointer'
                                                                                    >
                                                                                        <div className='assignment_content_inner'>
                                                                                            <img src={ac?.data?.alphabetImage?.original} className="" alt="" />
                                                                                        </div>
                                                                                        <p>{ac?.data?.imageTitle}</p>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Form>
                                                        </TabPane>
                                                    </TabContent>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {isEdit &&
                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <p
                                                        className="danger"
                                                        onClick={() => deletePack()}
                                                    >Delete Pack</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </>}
                </Container>
            </div>
        </React.Fragment >
    )
}

export { PackDetail }

let inputValidations = {
    pack_Name: {
        presence: {
            allowEmpty: false,
            message: "^Pack Name can't be blank"
        }
    },
    description: {
        presence: {
            allowEmpty: false,
            message: "^Description can't be blank"
        }
    },
    tags: {
        presence: {
            allowEmpty: false,
            message: "^Tags can't be blank"
        }
    },
    content_For_Rating: {
        presence: {
            allowEmpty: false,
            message: "^Content Rating can't be blank"
        }
    },
    pack_Image: {
        presence: {
            allowEmpty: false,
            message: "^Pack Image can't be blank"
        }
    },
}