import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form,
    Label
} from "reactstrap";
import { API_ENDPOINTS } from "api/endpoints";
import FormInput from "components/FormInput";
import ReactSelect from "components/ReactSelect";
import CreateApplication from 'components/Button/createApplicationBtn'
import BackBtn from "components/Button/backBtn";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import trash from 'assets/images/bxs-trash.svg';
import { useDispatch } from "react-redux";
import classnames from "classnames"
import _ from 'lodash';
import { history } from "helpers/utils";
import { Toast } from "helpers/Toast";
import { MdEdit } from "react-icons/md";
import { CONTENT_RATING_DROPDOWN } from "helpers/variables";
import validate from "helpers/validation";
import { addNewDeal } from "store/deals/actions";
import moment from "moment";
import Select from 'react-select'

const DealDetail = (props) => {
    const { match: { params } } = props

    const [isLoading, setLoading] = useState(false)
    const [isBtnLoading, setBtnLoading] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const [dealDetail, setDetail] = useState({
        deal_code: null,
        Validity: null,
        status: false,
        isPublished: false,
        StartDate: null,
        expiry_date: null,
        createdBy: '',
        available_Discount: null,
    })
    const [errors, setErrors] = useState({
        deal_code: null,
        Validity: null,
        StartDate: null,
        expiry_date: null,
        createdBy: '',
        available_Discount: null,
    })

    useEffect(() => {
        getApis()
    }, []);

    const getApis = async () => {
        setLoading(true)
        if (params && params._id && !isEdit) {
            await getOneDealDetail(params._id)
        }
        setLoading(false)
    }

 
    const getOneDealDetail = async (id) => {
        try {
            const res = await API_ENDPOINTS.getDealDetail(id)
            if (res && !_.isEmpty(res)) {
                setEdit(true)
                setDetail({
                    ...res,
                    status: res?.status === 'Active' ? true : false,
                    isPublished: res?.isPublished,
                    StartDate: res.StartDate != null ? moment(res.StartDate).format('YYYY-MM-DD') : null,
                    expiry_date: res.expiry_date != null ? moment(res.expiry_date).format('YYYY-MM-DD') : null,
                })

            }
        } catch (error) {
        }
    }

    const onChange = (name, value) => {
            setDetail({ ...dealDetail, [name]: value })
            setErrors({ ...errors, [name]: undefined })
    }

    const saveDealDetail = async () => {
        let validation = {
            ...inputValidations
        }
        const notValid = validate(dealDetail, validation);
        if (notValid) {
            setErrors(notValid)
        } else {
            setBtnLoading(true)
            let payload = {
                ...dealDetail,
                StartDate: dealDetail.StartDate != null ? moment(dealDetail.StartDate).format('YYYY-MM-DD') : null,
                expiry_date: dealDetail.expiry_date != null ? moment(dealDetail.expiry_date).format('YYYY-MM-DD') : null,
                status: dealDetail.status ? 'Active' : 'InActive',
            }
            if (isEdit) {
                payload["_id"] = dealDetail._id
            }
            let res = await API_ENDPOINTS.createOrEditDeal({ body: payload })
            setDetail({
                ...dealDetail,
                ...res,
                StartDate: dealDetail.StartDate,
                expiry_date: dealDetail.expiry_date
            })
            setBtnLoading(false)
        }
    }

    const deleteDeal = async () => {
        let payload = {
            body: {
                ...dealDetail,
                isDeleted: true,
                isPublished: false
            }
        }
        let res = await API_ENDPOINTS.createOrEditDeal(payload)
        // Toast('success', 'Program Deleted Successfully')
        history.goBack()
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (
                        <div>...Data Loading.....</div>
                    ) :
                        <>
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box d-flex">
                                        <BackBtn history={props.history} />
                                        <h4 className="mb-0 font-size-18 px-2">{isEdit ? "Edit Deal" : 'Add new Deal'}</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <div className="content clearfix mt-4">
                                                <FormInput
                                                    placeholder="Enter Deal Code"
                                                    name="deal_code"
                                                    type='text'
                                                    formGroupClassName="row"
                                                    label='Deal Code'
                                                    maxLength={70}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={dealDetail['deal_code']}
                                                    error={errors?.deal_code}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    name1="StartDate"
                                                    name2="expiry_date"
                                                    type='flat-datepicker'
                                                    formGroupClassName="row"
                                                    label='Deal Date'
                                                    labelClassName="col-md-2 col-form-label"
                                                    value1={dealDetail['StartDate']}
                                                    value2={dealDetail['expiry_date']}
                                                    onChangeDate1={(...params) => onChange(...params)}
                                                    onChangeDate2={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    placeholder="Validity"
                                                    name="Validity"
                                                    type='number'
                                                    formGroupClassName="row"
                                                    label='Validity'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={dealDetail['Validity']}
                                                    error={errors?.Validity}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    placeholder="Discount"
                                                    name="available_Discount"
                                                    type='number'
                                                    formGroupClassName="row"
                                                    label='Discount'
                                                    maxLength={160}
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={dealDetail['available_Discount']}
                                                    error={errors?.available_Discount}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    name="status"
                                                    formGroupClassName="row"
                                                    label='Status'
                                                    type={"two-way-switch"}
                                                    leftLabel="Inactive"
                                                    rightLabel="Active"
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={dealDetail['status']}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <FormInput
                                                    name="isPublished"
                                                    formGroupClassName="row"
                                                    label='Publish'
                                                    type={"two-way-switch"}
                                                    leftLabel="No"
                                                    rightLabel="Yes"
                                                    labelClassName="col-md-2 col-form-label"
                                                    value={dealDetail['isPublished']}
                                                    onChange={(...params) => onChange(...params)}
                                                />
                                                <div className="d-flex justify-flex-end">
                                                    {!isBtnLoading ?
                                                    <button
                                                        type="submit"
                                                        className="primary_btn create_button"
                                                        onClick={() => saveDealDetail()}
                                                    >
                                                        Save
                                                    </button>
                                                    :
                                                    <button
                                                        type="submit"
                                                        className="primary_btn create_button"
                                                        onClick={() => null}
                                                    >
                                                        Loading
                                                    </button>
                                                }
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            {isEdit &&
                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={3}>
                                                <div>
                                                    <p
                                                        className="danger"
                                                        onClick={() => deleteDeal()}
                                                    >Delete Deal</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                        </>}
                </Container>
            </div>
        </React.Fragment >
    )
}

export { DealDetail }

let inputValidations = {
    deal_code: {
        presence: {
            allowEmpty: false,
            message: "^Deal Code can't be blank"
        }
    },
    Validity: {
        presence: {
            allowEmpty: false,
            message: "^Validity can't be blank"
        }
    },
    StartDate: {
        presence: {
            allowEmpty: false,
            message: "^Start Date can't be blank"
        }
    },
    available_Discount: {
        presence: {
            allowEmpty: false,
            message: "^Discount can't be blank"
        }
    }
}