import React from 'react';
import { Spinner } from 'reactstrap';

const CreateApplicationBtn = (props) => {
    const { btnName, onCreateApplicationBtnClick, btnClassname, disableCreateApp, isLoading} = props
    return (
        <>
            {disableCreateApp ?
                <div className='disable_create_button'>
                    <span className="ml-2">{btnName}</span>
                </div>
                :
            isLoading ?
                <div className='disable_create_button'>
                    <Spinner 
                        size={4}
                    />
                </div>
            :
                <div
                    className={`create_button ${btnClassname}`}
                    onClick={onCreateApplicationBtnClick}
                >   
                    <span className="ml-2">{btnName}</span>
                </div>
            }
        </>
     );
}

export default CreateApplicationBtn;

CreateApplicationBtn.defaultProps = {
    onCreateApplicationBtnClick : () => {}
}