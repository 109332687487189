import {
  GET_CATEGORIES,
  GET_CATEGORY_FAIL,
  GET_CATEGORIES_SUCCESS,
  ADD_NEW_CATEGORY,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAIL,
  GET_PACKS_IN_CATEGORY,
  GET_PACKS_IN_CATEGORY_SUCCESS,
} from "./actionTypes"

export const getCategories = (queryPayload) => ({
  type: GET_CATEGORIES,
  payload: queryPayload
})

export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})

export const createOrEditCategory = data => ({
  type: ADD_NEW_CATEGORY,
  payload: data,
})

export const addCategorySuccess = user => ({
  type: ADD_CATEGORY_SUCCESS,
  payload: user,
})

export const addCategoryFail = error => ({
  type: ADD_CATEGORY_FAIL,
  payload: error,
})

export const getCategoryFail = error => ({
  type: GET_CATEGORY_FAIL,
  payload: error,
})

export const updateCategory = user => ({
  type: UPDATE_CATEGORY,
  payload: user,
})

export const updateCategorySuccess = user => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: user,
})

export const updateCategoryFail = error => ({
  type: UPDATE_CATEGORY_FAIL,
  payload: error,
})

export const getPacksInCategory = (queryPayload) => ({
  type: GET_PACKS_IN_CATEGORY,
  payload: queryPayload
})

export const getPacksInCategorySuccess = packsInCategory => ({
  type: GET_PACKS_IN_CATEGORY_SUCCESS,
  payload: packsInCategory,
})