/* COUPONS */
export const GET_COUPONS = "GET_COUPONS"
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS"
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL"

/* COUPONS DETAIL */
export const GET_COUPON_DETAIL = "GET_COUPON_DETAIL"
export const GET_COUPON_DETAIL_SUCCESS = "GET_COUPON_DETAIL_SUCCESS"
export const GET_COUPON_DETAIL_FAIL = "GET_COUPON_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_COUPON = "ADD_NEW_COUPON"
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS"
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL"

/**
 * udpates user
 */
export const UPDATE_COUPON = "UPDATE_COUPON"
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS"
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL"