/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//   //   aws_project_region: "ap-south-1",
//   //   aws_cognito_region: "ap-south-1",
//   //   aws_user_pools_id: "ap-south-1_EEfCsvvSb",
//   //   aws_user_pools_web_client_id: "233do7jugdm80j1ijvg741h13t",
//   //   accessKeyId: "AKIAVQRISFHG73VLHCOY",
//   //   secretAccessKey: "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",
//   aws_cognito_region: process.env.REACT_APP_AWS_REGION,
//   aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
//   aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
//   //   IdentityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_ID,
//   oauth: {
//     domain: "alphatub-qa.auth.ap-south-1.amazoncognito.com",
//     scope: ["email", "openid"],
//     redirectSignIn:
//       "https://qa.tubclass.alphatub.com/,https://www.dev.tubclass.alphatub.com/",
//     redirectSignOut:
//       "https://qa.tubclass.alphatub.com/,https://www.dev.tubclass.alphatub.com/",
//     responseType: "code",
//   },
//   federationTarget: "COGNITO_USER_POOLS",
//   aws_cognito_username_attributes: ["EMAIL"],
//   aws_cognito_social_providers: [],
//   aws_cognito_signup_attributes: [
//     "NAME",
//     "MIDDLE_NAME",
//     "EMAIL",
//     "GENDER",
//     "PHONE_NUMBER",
//   ],
//   aws_cognito_mfa_configuration: "OFF",
//   aws_cognito_mfa_types: [],
//   aws_cognito_password_protection_settings: {
//     passwordPolicyMinLength: 6,
//     passwordPolicyCharacters: ["REQUIRES_NUMBERS"],
//   },
//   aws_cognito_verification_mechanisms: ["EMAIL"],
//   mandatorySignIn: true,
// };

// export default awsmobile;

const awsmobile = {
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  oauth: {},
};

export default awsmobile;
