import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { USER_VALIDATION } from "./actionTypes"
import { userEmailValidationSuccess, userEmailValidationError } from "./actions"

//Include Both Helper File with needed methods

import { AwsValidateUserAndSendOTP } from '../../../helpers/AWSHelper'



//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {

    const response = yield AwsValidateUserAndSendOTP(user.email)
    userEmailValidationSuccess("An OTP is sent to your mail")
    history.push("/auth/setnewpassword", { userMail: user.email })
  } catch (error) {
    yield put(userEmailValidationError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(USER_VALIDATION, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga