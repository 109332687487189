import {
  USER_VALIDATION_ERROR,
  USER_VALIDATION,
  USER_VALIDATION_SUCCESS
} from "./actionTypes"


const initialState = {
  forgetSuccessMsg: '',
  forgetError: '',
  loading: false
}

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case USER_VALIDATION:
      state = {
        ...state,
        validationSuccessMsg: '',
        validationError: '',
        loading: true
      }
      break
    case USER_VALIDATION_SUCCESS:
      state = {
        ...state,
        validationSuccessMsg: action.payload,
        loading: false
      }
      break
    case USER_VALIDATION_ERROR:
      state = {
        ...state,
        forgetError: action.payload,
        loading: false
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default forgetPassword
