/* COMBOS */
export const GET_COMBOS = "GET_COMBOS"
export const GET_COMBOS_SUCCESS = "GET_COMBOS_SUCCESS"
export const GET_COMBOS_FAIL = "GET_COMBOS_FAIL"

/* COMBOS DETAIL */
export const GET_COMBO_DETAIL = "GET_COMBO_DETAIL"
export const GET_COMBO_DETAIL_SUCCESS = "GET_COMBO_DETAIL_SUCCESS"
export const GET_COMBO_DETAIL_FAIL = "GET_COMBO_DETAIL_FAIL"

/**
 * CREATE COMBO RULE
 */
export const ADD_NEW_COMBO = "ADD_NEW_COMBO"
export const ADD_COMBO_SUCCESS = "ADD_COMBO_SUCCESS"
export const ADD_COMBO_FAIL = "ADD_COMBO_FAIL"

/**
 * Update COMBO RULE
 */
export const UPDATE_COMBO = "UPDATE_COMBO"
export const UPDATE_COMBO_SUCCESS = "UPDATE_COMBO_SUCCESS"
export const UPDATE_COMBO_FAIL = "UPDATE_COMBO_FAIL"