import {
  GET_ORDER_DETAIL,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
} from "./actionTypes"

export const getOrders = (queryPayload) => ({
  type: GET_ORDERS,
  payload: queryPayload
})
export const getOrdersSuccess = Orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: Orders,
})


export const getOrderDetail = (params) => ({
  type: GET_ORDER_DETAIL,
  payload: params
})
export const getOrderDetailSuccess = data => ({
  type: GET_ORDER_DETAIL_SUCCESS,
  payload: data,
})