import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { validateUploadSize } from "helpers/format_helper";
import { Toast } from "helpers/Toast";
import { Input, Label, Row, Col } from "reactstrap";
import _ from "lodash";
import RemoveImage from "assets/images/remove.svg";
import { TEMPLATE_DATA } from "helpers/variables";
// import {toPng} from 'html-to-image';
import { API_ENDPOINTS } from "api/endpoints";

export const Uploadfile = ({
  uploadedImageUrl,
  uploadedFileName,
  label,
  id,
  isOptional = false,
  onChange,
  name,
  isMult,
  error,
  isUploading,
  accept,
  size,
}) => {
  return (
    <Fragment>
      <div className={`upload-input ${!uploadedFileName ? "browse" : ""}`}>
        <div className="custom-upload-file">
          <span className="upload-icon">
            <i
              className={`${!uploadedFileName ? "fa fa-arrow-up" : "icon-doc"}`}
            ></i>
          </span>
          <div className="flex-column d-flex align-items-center">
            <img
              src={uploadedImageUrl}
              style={{ width: "100px", height: "100%" }}
            />
            <a>
              {isUploading
                ? "Uploading..."
                : uploadedFileName
                ? uploadedFileName
                : label}
            </a>
          </div>

          <div className="text-lighGrey-2 ml-auto">
            <span className="fw-300 mr-3">
              {isOptional ? "( Optional ) " : ""}
            </span>
            <span
              className={`cursor-pointer ${
                isUploading ? "text-lighGrey" : "text-primary"
              } browse`}
              onClick={() => {
                document.getElementById(id).click();
              }}
            >
              {!uploadedFileName ? "Browse" : "Change"}
            </span>
          </div>
        </div>
        <div className="text-lighGrey-2 d-flex justify-content-end align-items-end flex-column py-2 pr-2">
          <small>
            <strong>Maximum upload size</strong> : 5MB;
          </small>
          <small>
            <strong>Upload types</strong> : png / jpg;
          </small>
        </div>
        <input
          type="file"
          id={id}
          className="d-none"
          name={name}
          disabled={isUploading}
          onChange={({ target }) => {
            if (validateUploadSize(target)) onChange(name, target.files, id);
            else
              Toast({
                type: "error",
                message: "File upload size should be less than 5MB",
              });
          }}
          multiple={isMult}
          accept={accept}
        />
        <strong className="text-danger small">{error ? error[0] : ""}</strong>
      </div>
    </Fragment>
  );
};

Uploadfile.defaultProps = {
  accept:
    "image/png, image/jpeg, application/pdf,.xls, .doc, .docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export const UploadWordDetailFile = ({
  handleAcceptedFiles,
  selectedFiles,
  label,
  name,
  showMiniature,
  removeImage,
  setImage,
  stencil,
}) => {
  let imageRef = useRef();
  const [isUploadingImage, setIsLoading] = useState(false);

  function dataURLtoBlob(dataurl) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  const uploadImage = async (files) => {
    let formData = new FormData();
    formData.append("image", files);
    const myInit = {
      body: formData,
    };
    const response = await API_ENDPOINTS.uploadImage(myInit);
    Toast("success", "Stencil Uploaded successfully");
  };
  const onButtonClick = () => {
    if (imageRef.current === null) {
      return;
    }
    setIsLoading(true);
    // toPng(imageRef.current)
    //   .then(async (dataUrl) => {
    //     let imgs = dataURLtoBlob(dataUrl)
    //     await uploadImage(imgs)
    //     setIsLoading(false)
    //   })
    // .catch((err) => {
    //     console.log("error", err.response, err.request, err.message)
    //     setIsLoading(false)
    //     Toast('error', 'Error in stencil Image')
    //   })
  };

  return (
    <>
      <div className="mt-3">
        <Label htmlFor="formFile" className="form-label">
          {label}
        </Label>
        <Input
          className="form-control"
          type="file"
          accept="image/*"
          id="formFile"
          onChange={(e) => handleAcceptedFiles(e.target.files, name)}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
      </div>
      {!_.isEmpty(selectedFiles) && (
        <div className="mt-4">
          <Row className="align-items-center justify-content-center">
            <Col className="col-auto">
              <div
                className="image_wrapper p-15"
                style={{ width: "250px", height: "250px" }}
              >
                <img
                  data-dz-thumbnail=""
                  className="word_detail_up_img_pr"
                  src={selectedFiles}
                />
                <div className="remove_image_ic" onClick={() => removeImage()}>
                  <img src={RemoveImage} />
                </div>
              </div>
            </Col>
            {showMiniature && (
              <Col>
                <div
                  id="stencil_image_map"
                  className="image_wrapper"
                  style={{
                    width: "180px",
                    height: "180px",
                    margin: "0 auto",
                    border: "none",
                  }}
                >
                  <div className="parentImageContainer" ref={imageRef}>
                    <img
                      data-dz-thumbnail=""
                      className=""
                      src={stencil}
                      // src={require(`assets/images/stencils/${stencil}.png`)}
                      id="image1"
                      // crossorigin='anonymous'
                    />
                    <div className="child_image">
                      <img
                        data-dz-thumbnail=""
                        id="image1"
                        className="word_detail_up_img_pr"
                        src={selectedFiles}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className='d-flex justify-content-center'>
                                    <button
                                        type='button'
                                        onClick={onButtonClick}
                                        disabled={isUploadingImage}
                                        className={`${isUploadingImage ? 'secondary_btn' : 'primary_btn'} px-2 my-2 text-center`}
                                    >{isUploadingImage ? 'Uploading': 'Save Image'}</button>
                                </div> */}
              </Col>
            )}
          </Row>
        </div>
      )}
    </>
  );
};

// https://jsfiddle.net/6py3o28d/1/
