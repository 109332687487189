import {
  GET_TUB_CARDS_SUCCESS,
  GET_TUB_CARD_DETAIL_SUCCESS,

} from "./actionTypes"

const INIT_STATE = {
  tubCardList: [],
  tubCardDetail: {},
  error: {},
}

const tubCard = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TUB_CARDS_SUCCESS:
      return {
        ...state,
        tubCardList: action.payload,
      }

    case GET_TUB_CARD_DETAIL_SUCCESS:
      return {
        ...state,
        tubCardDetail: action.payload,
      }


    default:
      return state
  }
}

export default tubCard
