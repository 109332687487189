import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Label,
} from "reactstrap";
import { API_ENDPOINTS } from "api/endpoints";
import FormInput from "components/FormInput";
import ReactSelect from "components/ReactSelect";
import CreateApplication from "components/Button/createApplicationBtn";
import BackBtn from "components/Button/backBtn";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Toast } from "helpers/Toast";
import {
  CONTENT_RATING_DROPDOWN,
  TUB_SHEET_MOCK_DATA,
} from "helpers/variables";
import validate from "helpers/validation";
import moment from "moment";
import { addNewTubSheet } from "store/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Buffer } from "buffer";
import ContentViewSheet from "helpers/ContentViewSheet";
import CreateApplicationBtn from "components/Button/createApplicationBtn";

const animatedComponents = makeAnimated();

const defaultData = {
  ObjectName: "",
  desc: "",
  isActive: true,
  ObjectImage: "",
  createdBy: "",
  tags: [],
  sheetData: [],
};

const Canvas = ({ backgroundImage, overlayImage, rawData }) => {
  const canvasRef = useRef(null);
  console.log("rawData", rawData);

  useEffect(() => {
    convertToCanvas(backgroundImage, overlayImage);
  }, [backgroundImage, overlayImage]);

  function convertCanvasToBlob(canvas) {
    return new Promise((resolve, reject) => {
      canvasRef.current.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Could not convert canvas to Blob."));
        }
      }, "image/png");
    });
  }

  const convertToCanvas = async (backgroundImage, overlayImage) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const img1 = new Image();
    img1.src = backgroundImage;

    const img2 = new Image();
    img2.src = overlayImage;

    Promise.all(
      [img1, img2].map(
        (img) => new Promise((resolve) => (img.onload = resolve))
      )
    )
      .then(() => {
        canvas.width = rawData.width;
        canvas.height = rawData.height;

        // Draw the first image
        ctx.drawImage(
          img1,
          rawData.xCoordinate,
          rawData.yCoordinate,
          rawData.width,
          rawData.height
        );

        // Calculate the position to place image2 at the desired location
        const x = 0; // Adjust this as needed
        const y = 0; // Adjust this as needed

        // Draw the second image on top of the first at the specified position
        ctx.drawImage(img2, x, y, 100, 100);
      })
      .catch((error) => {
        console.error("Error loading images:", error);
      });
    // const blob = await convertCanvasToBlob(canvas);
    // let dataUrl = canvas.toDataURL('image/png')
    // const byteString = atob(dataUrl.split(',')[1]);
    // const arrayBuffer = new ArrayBuffer(byteString.length);
    // const uint8Array = new Uint8Array(arrayBuffer);

    // for (let i = 0; i < byteString.length; i++) {
    //     uint8Array[i] = byteString.charCodeAt(i);
    // }

    // const blob = new Blob([uint8Array], { type: 'image/png' });
    // const blob = await convertCanvasToBlob(canvas);

    // console.log('data', dataUrl)
    // let base64String = dataUrl.replace(/^data:image\/\w+;base64,/, '')
    // console.log('buffer',buffer)
    // const file  = new File([blob], 'canvas.png', { type: 'image/png' });
    // uploadToS3(file)
  };

  // const uploadToS3 = async (blob) => {
  //     try {
  //         const formData = new FormData();
  //         formData.append('image', blob);

  //         const myInit = {
  //             body: formData,
  //         };
  //         const response = await API_ENDPOINTS.uploadImage(myInit)
  //         console.log('response', response)
  //     } catch (error) {
  //         console.log('error', error)
  //     }
  // }

  return <canvas ref={canvasRef} />;
};

const TubsheetDetail = (props) => {
  const {
    match: { params },
  } = props;
  console.log(props, "props");
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [tagsDropdown, setTagsDropdown] = useState([]);
  const [isCardImageCreated, setIsCardImageCreated] = useState(false);

  const [contentRatingsDropdown] = useState(CONTENT_RATING_DROPDOWN);
  const [sheetDetail, setDetail] = useState({
    ObjectName: null,
    desc: "",
    isActive: true,
    ObjectImage: "",
    createdBy: "",
    tags: [],
    sheetData: [],
  });
  const [errors, setErrors] = useState({
    ObjectName: null,
    ObjectImage: "",
    tags: [],
    // offer_StartDate: null,
  });
  const [wordsDropdown, setWordDropdown] = useState([]);
  const [sheetMockData, setSheetMockData] = useState(TUB_SHEET_MOCK_DATA);
  const [template, setTemplateData] = useState({});

  useEffect(() => {
    getApis();
  }, []);

  const getApis = async () => {
    setLoading(true);
    await getWords();
    await getTags();
    await getTemplate();
    if (params && params._id && !isEdit) {
      await getOneSheetDetail(params._id);
    }
    setLoading(false);
  };

  const getTemplate = async () => {
    let res = await API_ENDPOINTS.template({
      languageId: "61d6d22f50e72435d4833026",
    });
    console.log("res", res);
    setTemplateData(res);
  };

  const getOneSheetDetail = async (id) => {
    console.log(id, "isId");
    try {
      const res = await API_ENDPOINTS.getTubSheetDetail(id);
      if (res && !_.isEmpty(res)) {
        setEdit(true);
        let sheetDetails = {
          ...res,
          isActive: res?.isActive,
          tags: res?.tags?.map((dt) => ({ label: dt, value: dt })),
          // isDiscountAvailable: res?.isActive === 'Active' ? true : false,
          // content_For_Rating: { label: res.content_For_Rating, value: res.content_For_Rating },
          // categories: [
          //     {
          //         label: 'Food',
          //         value: 'Food'
          //     },
          //     {
          //         label: 'Nature',
          //         value: 'Nature'
          //     }
          // ],
          // offer_StartDate: res.offer_StartDate != null ? moment(res.offer_StartDate).format('YYYY-MM-DD') : null,
          // offer_EndDate: res.offer_EndDate != null ? moment(res.offer_EndDate).format('YYYY-MM-DD') : null,
        };
        let sheetsData = [];
        if (!_.isEmpty(res?.data)) {
          sheetsData = res?.data?.map((prd) => ({
            label: prd?.imageTitle,
            value: prd._id,
            id: prd._id,
            data: { ...prd },
          }));
        }
        sheetDetails = {
          ...sheetDetails,
          sheetData: sheetsData,
        };
        setIsCardImageCreated(true);
        setDetail({ ...sheetDetail, ...sheetDetails });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getTags = async () => {
    let res = await API_ENDPOINTS.getTags(null);
    if (!_.isEmpty(res)) {
      let tagsd = res?.map((r) => ({ label: r.tags, value: r._id }));
      setTagsDropdown(tagsd);
    }
  };

  const getWords = async () => {
    let res = await API_ENDPOINTS.getWords(null);
    if (!_.isEmpty(res)) {
      let wordsd = res?.map((r) => ({
        label: r.title,
        value: r._id,
        data: {
          ...r,
          originalImageId: r._id,
          alphabetName: r.imageName,
          imageTitle: r.title,
          alphabetImage: {
            original: r.originalImage?.original,
            thumbnail: r.originalImage?.thumbnail,
          },
        },
      }));
      setWordDropdown(wordsd);
    }
  };

  const onChange = (name, value) => {
    if (name === "ObjectImage") {
      uploadImage(value);
    } else {
      setDetail({ ...sheetDetail, [name]: value });
      setErrors({ ...errors, [name]: undefined });
    }
  };

  const saveSheetDetail = async () => {
    let validation = {
      ...inputValidations,
    };
    const notValid = validate(sheetDetail, validation);
    let sheetLength = sheetDetail.sheetData?.length;

    if (notValid) {
      setErrors(notValid);
    } else {
      if (sheetLength == 26) {
        let payload = {
          ...sheetDetail,
          ObjectType: "Sheet",
          tags: sheetDetail?.tags.map((word) => word.label),
          isActive: sheetDetail.isActive,
          AWS_UserName: localStorage.getItem("username"),
          data: sheetDetail.sheetData.map((dt) => ({ ...dt.data })),
          // offer_StartDate: sheetDetail.offer_StartDate != null ? moment(sheetDetail.offer_StartDate).format('YYYY-MM-DD') : null,
          // offer_EndDate: sheetDetail.offer_EndDate != null ? moment(sheetDetail.offer_EndDate).format('YYYY-MM-DD') : null,
          // content_For_Rating: sheetDetail?.content_For_Rating?.value,
          // categories: sheetDetail?.categories.map(word => word.label),
          // isDiscountAvailable: sheetDetail.isDiscountAvailable ? 'Active' : 'InActive',
        };
        if (isEdit) {
          payload["_id"] = sheetDetail._id;
        }

        dispatch(addNewTubSheet(payload, props.history));
        setDetail({ ...sheetDetail, ...defaultData });
        setSheetMockData(TUB_SHEET_MOCK_DATA);
      } else {
        Toast("error", "Fill All 26 alphabets");
      }
    }
  };

  const onChangePackData = (value, action) => {
    setIsCardImageCreated(false);
    let sheetsData = [...sheetDetail.sheetData];
    let findSheet = sheetsData.find(
      (sd) => sd?.data?.alphabetName === action?.option?.data?.alphabetName
    );
    if (findSheet) {
      Toast("error", "Cannot add same word");
    } else {
      setDetail({ ...sheetDetail, sheetData: value });
    }
  };

  async function uploadImage(files) {
    let formData = new FormData();
    formData.append("image", files);
    try {
      const myInit = {
        body: formData,
      };
      const response = await API_ENDPOINTS.uploadImage(myInit);
      setDetail({
        ...sheetDetail,
        ObjectImage: {
          original: response?.originalImage?.original,
          thumbnail: response?.originalImage?.original,
        },
      });
      setErrors({ ...errors, ObjectImage: undefined });
    } catch (error) {
      console.log("error", error);
    }
  }

  const renderAlphabetImage = (alphabet) => {
    const sheets = [...sheetDetail.sheetData];
    let getAlphabetFromSheet = sheets.find(
      (sc) => sc?.data?.alphabetName === alphabet.alphabetName
    );
    console.log("getAlphabetFromSheet", getAlphabetFromSheet);
    if (getAlphabetFromSheet != undefined) {
      return (
        <>
          <div
            id="stencil_image_map"
            className="image_wrapper"
            style={{
              width: "100px",
              height: "100px",
              margin: "0 auto",
              border: "none",
            }}
          >
            <div className="parentImageContainer">
              <img
                data-dz-thumbnail=""
                className=""
                src={alphabet?.stencilImage?.original}
                id="image1"
              />
              <div className="child_image">
                <img
                  data-dz-thumbnail=""
                  id="image1"
                  className="word_detail_up_img_pr"
                  src={getAlphabetFromSheet?.data?.alphabetImage?.original}
                />
              </div>
            </div>
          </div>
          {/* <div>
                        <Canvas
                            rawData={alphabet}
                            backgroundImage={alphabet?.stencilImage?.original}
                            overlayImage={getAlphabetFromSheet?.data?.alphabetImage?.original}
                        />
                    </div> */}
        </>
      );
    } else {
      return (
        <div className="content_stencil">
          <img src={alphabet?.stencilImage?.original} className="" alt="" />
        </div>
      );
    }
  };

  const saveCanvasDataUrlInState = (image) => {
    setDetail({
      ...sheetDetail,
      ObjectImage: {
        original: image?.originalImage?.original,
        thumbnail: image?.originalImage?.original,
      },
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {isLoading ? (
            <div>...Data Loading.....</div>
          ) : (
            <>
              <Row>
                <Col xs="12">
                  <div className="page-title-box d-flex">
                    <BackBtn history={props.history} />
                    <h4 className="mb-0 font-size-18 px-2">
                      {isEdit ? "EDIT TUB SHEET" : "CREATE NEW TUB SHEET"}
                    </h4>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <CardTitle className="h6 mb-4">
                        Input TUB Sheet Data
                      </CardTitle>
                      <div className="wizard clearfix">
                        <div className="content clearfix mt-4">
                          <div>
                            <FormInput
                              placeholder="Enter TUB Sheet Name"
                              name="ObjectName"
                              type="text"
                              formGroupClassName="row"
                              label="TUB Sheet Name"
                              maxLength={160}
                              labelClassName="col-md-2 col-form-label"
                              value={sheetDetail["ObjectName"]}
                              error={errors?.ObjectName}
                              onChange={(...params) => onChange(...params)}
                            />
                            <FormInput
                              placeholder="Enter Description"
                              name="desc"
                              type="textarea"
                              formGroupClassName="row"
                              label="Description"
                              maxLength={160}
                              labelClassName="col-md-2 col-form-label"
                              value={sheetDetail["desc"]}
                              error={errors?.desc}
                              onChange={(...params) => onChange(...params)}
                            />
                            <ReactSelect
                              name="tags"
                              label="Tag"
                              type="react-select"
                              formGroupClassName="row"
                              labelClassName="col-md-2 col-form-label"
                              placeholder="search & Add a tag"
                              isMulti={true}
                              isClearable={false}
                              closeMenuOnSelect={false}
                              maxLimit={5}
                              value={sheetDetail["tags"]}
                              options={tagsDropdown}
                              onChange={(...params) => onChange(...params)}
                              error={errors.tags ? errors.tags : ""}
                            />

                            <FormInput
                              name="isActive"
                              formGroupClassName="row"
                              label="Status"
                              type={"two-way-switch"}
                              leftLabel="Inactive"
                              rightLabel="Active"
                              labelClassName="col-md-2 col-form-label"
                              value={sheetDetail["isActive"]}
                              onChange={(...params) => onChange(...params)}
                            />
                            <LogoUpload
                              title="TUB Sheet Cover Image"
                              name="ObjectImage"
                              groupClassName="row"
                              labelClassName="col-md-2"
                              preview={sheetDetail["ObjectImage"]?.original}
                              onChange={(...params) => onChange(...params)}
                              error={
                                errors?.ObjectImage
                                  ? errors?.ObjectImage[0]
                                  : ""
                              }
                              removeImage={() =>
                                setDetail({
                                  ...sheetDetail,
                                  ObjectImage: { original: "" },
                                })
                              }
                            />
                          </div>
                          <Form>
                            <div className="mt-4">
                              <Row>
                                <Col lg={10}>
                                  <Row>
                                    <Col lg={3}>
                                      <FormInput
                                        placeholder=""
                                        name="data_Level"
                                        type="text"
                                        formGroupClassName="row"
                                        label="Total Words:"
                                        labelClassName="col-6 col-form-label"
                                        value={`${sheetDetail.sheetData?.length} / 26`}
                                        disabled={true}
                                        onChange={(...params) => null}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={10}>
                                  <div
                                    className={`templating-select select2-container pb-4`}
                                  >
                                    <Select
                                      options={wordsDropdown}
                                      defaultValue={sheetDetail.sheetData}
                                      value={sheetDetail.sheetData}
                                      isMulti={true}
                                      isDisabled={false}
                                      isClearable={true}
                                      placeholder={"search & Add a word"}
                                      classNamePrefix={`select2-selection`}
                                      closeMenuOnSelect={false}
                                      onChange={(value, action) =>
                                        onChangePackData(value, action)
                                      }
                                      isOptionDisabled={(option) =>
                                        sheetDetail.sheetData?.length >= 26
                                          ? true
                                          : false
                                      }
                                      components={animatedComponents}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={10}>
                                  <div className="d-flex mt-1 column-gap-20 no-gutters flex-wrap">
                                    {template.templateData &&
                                      template.templateData.map((ac) => (
                                        <div className="assignment_content_container cursor-pointer">
                                          {renderAlphabetImage(ac)}
                                          <p>{ac?.data?.imageTitle}</p>
                                        </div>
                                      ))}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                          {/* {sheetDetail["ObjectImage"]?.original != "" && (
                            <Row>
                              <Col lg={3}>
                                <Label className={`col-form-label`}>
                                  Sheeet Image
                                </Label>
                                <div className="image_wrapper p-15 logo_img_wrapper text-center mb-3">
                                  <img
                                    data-dz-thumbnail=""
                                    className="avatar-md rounded bg-light"
                                    alt={""}
                                    src={sheetDetail["ObjectImage"]?.original}
                                  />
                                </div>
                              </Col>
                            </Row>
                          )} */}
                          <div className="d-flex justify-flex-end">
                            {/* <ContentViewSheet
                              templateData={template.templateData}
                              canvasWidth={template?.sizeId?.length}
                              canvasHeight={template?.sizeId?.breadth}
                              selections={sheetDetail.sheetData}
                              updateImage={saveCanvasDataUrlInState}
                            /> */}
                            <CreateApplicationBtn
                              onCreateApplicationBtnClick={() =>
                                saveSheetDetail()
                              }
                              btnName="Save"
                              //   disableCreateApp={!isCardImageCreated}
                              btnClassname="primary_btn mx-2"
                            />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export { TubsheetDetail };

let inputValidations = {
  ObjectName: {
    presence: {
      allowEmpty: false,
      message: "^Sheet Name can't be blank",
    },
  },
  desc: {
    presence: {
      allowEmpty: false,
      message: "^Description can't be blank",
    },
  },
  tags: {
    presence: {
      allowEmpty: false,
      message: "^Tags can't be blank",
    },
  },
  ObjectImage: {
    presence: {
      allowEmpty: false,
      message: "^Sheet Image can't be blank",
    },
  },
};
