import _ from "lodash";
import React from "react";
import {
  Col,
  FormFeedback,
  Label,
  Input,
} from "reactstrap"
import RemoveImage from 'assets/images/remove.svg';
import { validateFilesize } from "helpers/utils";

export const LogoUpload = ({title, preview, name,onChange, groupClassName, labelClassName, validation, removeImage, error}) => {

    const setChange = (name, files) => {
        let isValidFilesize = validateFilesize(files)
        if(isValidFilesize){
            // if(validation !== undefined){
            //     validation.setFieldValue(name, files[0])
            // }
            onChange(name, files[0])
        }
    }

    return (
        <div className={`pb-4 ${groupClassName} `}>
            <Label  className={`col-form-label ${labelClassName}`}>{title}</Label>
            <Col>
                {!_.isEmpty(preview) &&
                    <div className="image_wrapper p-15 logo_img_wrapper text-center mb-3">
                        <img
                            data-dz-thumbnail=""
                            className="avatar-md rounded bg-light"
                            alt={""}
                            src={preview}
                        />
                        <div className="remove_image_ic" onClick={() => removeImage()}>
                            <img src={RemoveImage} alt="" />
                        </div>
                    </div>
                } 
                <Input 
                    className="form-control form-control-md" 
                    type="file" 
                    name={name}
                    onClick={(event)=> { 
                        event.target.value = null
                    }}
                    // style={{ display: "none" }}
                    onChange={(e) => setChange(name, e.target.files)}
                />
                <div className='invalid_text'>
                    {error ? error : ''}
                </div>
            </Col>
        </div>
    )
}

                            