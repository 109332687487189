import awsConfig from "../aws-exports";
import { Amplify, Auth } from "aws-amplify";

// export const initializeAws = () => {
//     Amplify.configure(awsConfig);
// }

export const initializeAws = () => {
  Amplify.configure(awsConfig);
  Auth.configure(awsConfig);
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: process.env.REACT_APP_ENVIRONMENT,
          endpoint: process.env.REACT_APP_DEV_URL,
        },
      ],
    },
  });
};

export async function AwsAccountLogin(email, password, saveUserInfo = true) {
  const user = await Auth.signIn(email, password);
  return user;
}

export const AwsAccountForceChangePassword = async (user, newPassword) => {
  const changePasswordResponse = await Auth.completeNewPassword(user, newPassword);
  return changePasswordResponse;
}

export function AwsValidateUserAndSendOTP(email) {
  return new Promise(function (response, reject) {
    Auth.forgotPassword(email)
      .then((data) => response(data))
      .catch((err) => {
        if (err?.code === "UserNotFoundException") {
          reject("This user is not know to system.");
        }
        console.log(err);
      });
  });
}

export function AwsSubmitNewPasswordWithOtp(userMail, userOTP, newPassword) {
  return new Promise(function (response, reject) {
    Auth.forgotPasswordSubmit(userMail, userOTP, newPassword)
      .then((data) => response(data))
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
}
