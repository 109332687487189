import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
} from "reactstrap";
import FormInput from "components/FormInput";
import ReactSelect from "components/ReactSelect";
import CreateApplication from 'components/Button/createApplicationBtn'
import { UploadWordDetailFile } from "components/Uploadfile";
import _ from "lodash";
import BackBtn from "components/Button/backBtn";
import { API_ENDPOINTS } from "api/endpoints";
import { STENCIL_DROPDOWN, TEMPLATE_DATA } from "helpers/variables";
import { useDispatch } from "react-redux";
import {
    addNewWord,
    updateWord
} from "store/words/actions";
import { validateFilesize } from "helpers/utils";
import { Toast } from "helpers/Toast";
import { ModalContainer } from "components/Modal";

const WordDetail = (props) => {
    const { match: { params } } = props
    const dispatch = useDispatch();

    const [isOpen, setModal] = useState(false)
    const [isEdit, setEdit] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [selectedAlphabet, setSelectedAlphabet] = useState(null)
    const [wordClassificationOptions, setWordClassification] = useState([
        {
            label: 'Noun',
            value: 'noun',
            isChecked: false,
        },
        {
            label: 'Pronoun',
            value: 'pronoun',
            isChecked: false,
        },
        {
            label: 'Verb',
            value: 'verb',
            isChecked: false,
        },
        {
            label: 'Adverb',
            value: 'adverb',
            isChecked: false,
        },
        {
            label: 'Singular',
            value: 'singular',
            isChecked: false,
        },
        {
            label: 'Plural',
            value: 'plural',
            isChecked: false,
        },
        {
            label: 'Adjective',
            value: 'adjective',
            isChecked: false,
        },
        {
            label: 'Preposition',
            value: 'preposition',
            isChecked: false,
        },


    ])
    const [tagsDropdown, setTagsDropdown] = useState([])
    const [wordData, setDetail] = useState({
        "languageId": "61d6d22f50e72435d4833026",
        tags: [],
        title: null,
        imageName: null,
        originalImage: {
            original: '',
            thumbnail: ''
        },
        stencilImage: {
            original: '',
            thumbnail: ''
        },
        UserName: '',
        imageOf: 'iconic_gallery_images',
        word_Category: 'createdBy_Alphatub',
        word_Classification: [],
        description: '',
        category: false,
        status: true
    })

    useEffect(() => {
        getApis()
    }, []);

    const getApis = async () => {
        setLoading(true)
        await getTags()
        if (params && params._id && !isEdit) {
            await getWordDetail(params._id)
        }
        setLoading(false)
    }
    const getTags = async () => {
        let res = await API_ENDPOINTS.getTags(null)
        if (!_.isEmpty(res)) {
            let tagsd = res.map(r => ({ label: r.tags, value: r._id}))
            setTagsDropdown(tagsd)
        }
    }
    const getWordDetail = async (id) => {
        try {
            const res = await API_ENDPOINTS.getWordDetail(id)
            if (res && !_.isEmpty(res)) {
                setEdit(true)
                setDetail({
                    ...res,
                    tags: res.tags && res.tags.map(tg => ({label: tg, value: tagsDropdown.find(tgd => tgd.tags == tg)?.value} )),
                    status: res.status == 'Active' ? true : false,
                    category: res.category == 'Default' ? true : false,
                })
                wordClassificationOptions.map(wc => {
                    wc.isChecked = res.word_Classification.includes(wc.value)
                })
                setWordClassification(wordClassificationOptions)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const onChange = (name, value) => {
        let data = {...wordData}
        if(name == 'title' && value != ''){
            let getStencil = TEMPLATE_DATA.find(td => td.alphabetName == value.charAt(0).toUpperCase())
            data["stencilImage"] = {
                original: getStencil?.stencilImage?.original,
                thumbnail: getStencil?.stencilImage?.original
            }
        }
        data[name] = value

        setDetail(data)
    }

    const validateFields = () => {
        let { tags, title, description, originalImage} = wordData
        if(_.isEmpty(tags) || _.isEmpty(title) || _.isEmpty(description) || _.isEmpty(originalImage["original"]) ){
            return false
        } else return true
    }

    const save = (type) => {
        let isValid = validateFields(wordData)
        if(isValid){
            let tags = []
            wordData.tags && wordData.tags.map(tg => tags.push(tg.label))
            let body = {
                ...wordData,
                UserName: localStorage.getItem('username'),
                tags: tags,
                title: wordData.title.charAt(0).toUpperCase() + wordData.title.slice(1),
                word_Classification: wordClassificationOptions.filter(word => word.isChecked).map(w => w.value),
                status: wordData.status ? 'Active' : 'InActive',
                category: wordData.category ? 'Default' : '',
                imageName: wordData.title.charAt(0).toUpperCase()
            }
            if(type == 3){
                body["isDeleted"] = true
            }
            if (isEdit) {
                dispatch(updateWord(body, props.history))
            } else {
                dispatch(addNewWord(body, props.history))
            }
        } else {
            Toast('error', 'Please Fill all details')
        }
    }

    async function handleAcceptedFiles(files, name) {
        let isValidFilesize = validateFilesize(files)
        if(isValidFilesize ){
            let formData = new FormData()
            formData.append('image', files[0])
            try {
                const myInit = {
                    body: formData,
                };
                const response = await API_ENDPOINTS.uploadImage(myInit)
                let getStencil = TEMPLATE_DATA.find(td => td.alphabetName == wordData.title.charAt(0).toUpperCase())
                setDetail({
                    ...wordData,
                    originalImage: {
                        original: response?.originalImage?.original,
                        thumbnail: response?.originalImage?.original
                    },
                    stencilImage: {
                        original: getStencil?.stencilImage?.original,
                        thumbnail: getStencil?.stencilImage?.original
                    }
                })

            } catch (error) {
                console.log('error', error)
            }
        }
    }

    const removeImage = () => {
        setDetail({
            ...wordData,
            originalImage: {
                original: ''
            },
            stencilImage: {
                original: ''
            }
        })
    }

    return (
        <div className="page-content">
            <Container fluid>
                {isLoading ? (
                    <div>...Data Loading.....</div>
                ) :
                    <>
                        <Row>
                            <Col xs="12">
                                <div className="page-title-box d-flex">
                                    <BackBtn history={props.history} />
                                    <h4 className="mb-0 font-size-18 px-2">{isEdit ? 'Edit Word' : 'Add New Word'}</h4>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="h4 mb-4">Input Word Data</CardTitle>
                                        <FormInput
                                            placeholder="Enter Word Name"
                                            name="title"
                                            type='text'
                                            formGroupClassName="row "
                                            label='Word'
                                            labelClassName="col-md-2 col-form-label"
                                            value={wordData['title']}
                                            maxLength={70}
                                            onChange={(...params) => onChange(...params)}
                                        />
                                        <ReactSelect
                                            name="tags"
                                            label='Tag'
                                            formGroupClassName="row "
                                            labelClassName="col-md-2 col-form-label"
                                            placeholder="search & Add a tag"
                                            isMulti={true}
                                            isClearable={false}
                                            closeMenuOnSelect={false}
                                            maxLimit = {5}
                                            value={wordData['tags']}
                                            options={tagsDropdown}
                                            onChange={(...params) => onChange(...params)}
                                        />
                                        <FormInput
                                            placeholder="Enter Description"
                                            name="description"
                                            type='textarea'
                                            formGroupClassName="row "
                                            label='Description'
                                            labelClassName="col-md-2 col-form-label"
                                            value={wordData['description']}
                                            maxLength={160}
                                            onChange={(...params) => onChange(...params)}
                                        />
                                        <FormInput
                                            name="word_Classification"
                                            type='radio'
                                            formGroupClassName="row "
                                            label='Word Classifications'
                                            labelClassName="col-md-2 col-form-label"
                                            value={wordData['word_Classification']}
                                            options={wordClassificationOptions}
                                            onChange={(item) => {
                                                setWordClassification(wordClassificationOptions.map((d) => (d.value === item.value ? item : d)));
                                            }}
                                        />
                                        <FormInput
                                            name="category"
                                            formGroupClassName="row "
                                            label='Default'
                                            type={"two-way-switch"}
                                            leftLabel=""
                                            rightLabel=""
                                            labelClassName="col-md-2 col-form-label"
                                            value={wordData['category']}
                                            onChange={(...params) => onChange(...params)}
                                        />
                                        <FormInput
                                            name="status"
                                            formGroupClassName="row "
                                            label='Status'
                                            type={"two-way-switch"}
                                            leftLabel="Inactive"
                                            rightLabel="Active"
                                            labelClassName="col-md-2 col-form-label"
                                            value={wordData['status']}
                                            onChange={(...params) => onChange(...params)}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {!_.isEmpty(wordData['title']) &&
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="h4">English Stencil and Icon</CardTitle>
                                            <div>
                                                <Row>
                                                    <Col lg={6}>
                                                        <UploadWordDetailFile
                                                            name="originalImage"
                                                            label="Upload Icon"
                                                            selectedFiles={wordData["originalImage"]['original']}
                                                            showMiniature={true}
                                                            stencil={wordData["stencilImage"]['original']}
                                                            handleAcceptedFiles={handleAcceptedFiles}
                                                            removeImage={removeImage}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>

                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col lg={12}>
                                <div className="d-flex align-items-center justify-space-between">
                                    {isEdit &&
                                        <div>
                                            <p className="danger" onClick={() => setModal(true)}>Delete Word</p>
                                        </div>
                                    }
                                    <div className='d-flex justify-content-end'>
                                        <CreateApplication
                                            onCreateApplicationBtnClick={() => save(1)}
                                            btnName="Save"
                                            btnClassname="px-4 text-white primary_bg"
                                            disableCreateApp={false}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>}
            </Container>
            <ModalContainer
                isOpen={isOpen}
                toggle={() => setModal(false)}
                title={'Delete Word'}
            >
                <div>
                    <p>Are you sure you want to delete this word ?</p>
                </div>
                <div className="d-flex justify-content-end">
                    <CreateApplication
                        onCreateApplicationBtnClick={() => save(3)}
                        btnName="Confirm"
                        btnClassname="px-4 mx-4 text-white primary_btn"
                        disableCreateApp={false}
                    />
                </div>
            </ModalContainer>
        </div>
    )
}

export { WordDetail }