/* WORDS */
export const GET_WORDS = "GET_WORDS"
export const GET_WORDS_SUCCESS = "GET_WORDS_SUCCESS"
export const GET_WORDS_FAIL = "GET_WORDS_FAIL"

/* WORDS DETAIL */
export const GET_WORD_DETAIL = "GET_WORD_DETAIL"
export const GET_WORD_DETAIL_SUCCESS = "GET_WORD_DETAIL_SUCCESS"
export const GET_WORD_DETAIL_FAIL = "GET_WORD_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_WORD = "ADD_NEW_WORD"
export const ADD_WORD_SUCCESS = "ADD_WORD_SUCCESS"
export const ADD_WORD_FAIL = "ADD_WORD_FAIL"

/**
 * udpates user
 */
export const UPDATE_WORD = "UPDATE_WORD"
export const UPDATE_WORD_SUCCESS = "UPDATE_WORD_SUCCESS"
export const UPDATE_WORD_FAIL = "UPDATE_WORD_FAIL"
