import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import SetUpNewPwd from "./auth/setUpNewPwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import wordsSaga from "./words/saga"
import tagsSaga from "./tags/saga"
import CurriculumsSaga from "./curriculum/saga"
import loaderSaga from "./loader/sagas"
import wordiconPackSaga from "./wordiconPacks/saga"
import tubShetSaga from "./tubSheet/saga"
import tubCardSaga from "./tubCard/saga"
import categoriesSaga from "./categories/saga"
import ordersSaga from "./orders/saga"
import customersSaga from "./customers/saga"
import couponsSaga from "./coupons/saga"
import subscriptionRuleSaga from "./subscriptionRule/saga"
import dealsSaga from "./deals/saga"
import comboSaga from "./combo/saga"


export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(SetUpNewPwd),
    fork(wordsSaga),
    fork(tagsSaga),
    fork(CurriculumsSaga),
    fork(loaderSaga),
    fork(wordiconPackSaga),
    fork(tubShetSaga),
    fork(tubCardSaga),
    fork(categoriesSaga),
    fork(ordersSaga),
    fork(customersSaga),
    fork(couponsSaga),
    fork(subscriptionRuleSaga),
    fork(dealsSaga),
    fork(comboSaga)
  ])
}
