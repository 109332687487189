import {
  GET_COMBOS_SUCCESS,
  GET_COMBO_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  combosList: [],
  comboDetail: {},
  error: {},
}

const combo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMBOS_SUCCESS:
      return {
        ...state,
        combosList: action.payload,
      }
    case GET_COMBO_DETAIL_SUCCESS:
      return {
        ...state,
        comboDetail: action.payload,
      }


    default:
      return state
  }
}

export default combo
