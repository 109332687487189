import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Row,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Form,
    Label
} from "reactstrap";
import { API_ENDPOINTS } from "api/endpoints";
import FormInput from "components/FormInput";
import ReactSelect from "components/ReactSelect";
import CreateApplication from 'components/Button/createApplicationBtn'
import BackBtn from "components/Button/backBtn";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import trash from 'assets/images/bxs-trash.svg';
import { useDispatch } from "react-redux";
import classnames from "classnames"
import _ from 'lodash';
import { history } from "helpers/utils";
import { Toast } from "helpers/Toast";
import { MdEdit } from "react-icons/md";
import { CONTENT_RATING_DROPDOWN } from "helpers/variables";
import validate from "helpers/validation";
import { addNewWordiconPack } from "store/wordiconPacks/actions";
import moment from "moment";
import Select from 'react-select'

const OrderDetail = (props) => {
    const { match: { params } } = props
    const [isLoading, setLoading] = useState(false)
    const [orderDetail, setDetail] = useState({
        userName: "Eshwar",
        date: "2023-04-03T06:41:36.436Z",
        status: "Completed",
        mobile: "8482828282",
        email: "esh@gmail.com",
        CustomerIP: '21309813',
        Country: 'Antartica',
    })
   
    useEffect(() => {
        getApis()
    }, []);

    const getApis = async () => {
        if (params && params._id) {
            await getOnePackDetail(params._id)
        }
    }

    const getOnePackDetail = async (id) => {
        try {
            setLoading(true)
            const res = await API_ENDPOINTS.getPackDetail(id)
            if (res && !_.isEmpty(res)) {
                setDetail({
                    ...res,
                    status: res?.status === 'Active' ? true : false,
                    isPublished: res?.isPublished,
                    isFree: res?.isFree,
                    isDiscountAvailable: res?.status === 'Active' ? true : false,
                    content_For_Rating: { label: res.content_For_Rating, value: res.content_For_Rating },
                    categories: [
                        {
                            label: 'Food',
                            value: 'Food'
                        },
                        {
                            label: 'Nature',
                            value: 'Nature'
                        }
                    ],
                    tags: res.tags.map(dt => ({ label: dt, value: dt })),
                    offer_StartDate: res.offer_StartDate != null ? moment(res.offer_StartDate).format('YYYY-MM-DD') : null,
                    offer_EndDate: res.offer_EndDate != null ? moment(res.offer_EndDate).format('YYYY-MM-DD') : null,
                })
                setLoading(false)
            }
        } catch (error) {
        }
    }
 
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {isLoading ? (
                        <div>...Data Loading.....</div>
                    ) :
                        <>
                            <Row>
                                <Col xs="12">
                                    <div className="page-title-box d-flex">
                                        <BackBtn history={props.history} />
                                        <h4 className="mb-0 font-size-18 px-2">ORDER {"#0001"}</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="h4 mb-4">Order Details</CardTitle>
                                            <Row>
                                                <Col lg={4}>
                                                    <Label>User</Label>
                                                </Col>
                                                <Col lg={8}>
                                                    <p>{orderDetail["userName"]}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <Label>Paid On</Label>
                                                </Col>
                                                <Col lg={8}>
                                                    <p>{orderDetail["date"]}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <Label>Customer IP</Label>
                                                </Col>
                                                <Col lg={8}>
                                                    <p>{orderDetail["CustomerIP"]}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <Label>Email Address</Label>
                                                </Col>
                                                <Col lg={8}>
                                                    <p>{orderDetail["email"]}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <Label>Phone</Label>
                                                </Col>
                                                <Col lg={8}>
                                                    <p>{orderDetail["mobile"]}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <Label>Country</Label>
                                                </Col>
                                                <Col lg={8}>
                                                    <p>{orderDetail["Country"]}</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={4}>
                                                    <Label>Status</Label>
                                                </Col>
                                                <Col lg={8}>
                                                    <p>{orderDetail["status"]}</p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <table className="orders_detail_table">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Item
                                                        </th>
                                                        <th>
                                                            Cost
                                                        </th>
                                                        <th>
                                                            Qty
                                                        </th>
                                                        <th>
                                                            Total
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img 
                                                                src={require('assets/images/sample_order_pack.png')}
                                                                alt="order"
                                                            />
                                                            <p>
                                                                Pack
                                                            </p>
                                                        </td>
                                                        <td>$2.0</td>
                                                        <td>1</td>
                                                        <td>$2.0</td>
                                                    </tr>
                                                </tbody>
                                            </table>                                            
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="justify-flex-end">
                                <Col className="col-5 ml-auto">
                                    <div className="totalContent">
                                        <p className="key">Item Subtotal :</p>
                                        <p className="value">$2.00</p>
                                    </div>
                                    <div className="totalContent">
                                        <p className="key">Shipping :</p>
                                        <p className="value">$0.00</p>
                                    </div>
                                    <div className="totalContent">
                                        <p className="key">Order Total :</p>
                                        <p className="value">$2.00</p>
                                    </div>
                                    <div className="total_content_border mb-3"></div>
                                    <div className="totalContent">
                                        <p className="key">Paid :</p>
                                        <p className="value">$2.00</p>
                                    </div>
                                    <div className="totalContent mt-">
                                        <p className="key">Feb 01, 2023 via Credit Card</p>
                                        <p  className="value"></p>
                                    </div>
                                    <div className="totalContent">
                                        <p className="key">Gateway Fee :</p>
                                        <p className="value">-$0.15</p>
                                    </div>
                                    <div className="totalContent">
                                        <p className="key">Gateway Payout :</p>
                                        <p className="value">$1.85</p>
                                    </div>
                                </Col>
                            </Row>
                        </>}
                </Container>
            </div>
        </React.Fragment >
    )
}

export { OrderDetail }