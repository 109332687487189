import { Patch, del, get, post, put } from "./api_helper";

export const ENDPOINTS = {
  // COMMON
  UPLOAD_IMAGE: "/user/uploadImage",
  USER_PROFILE: "/admin_panel/v3.8/user/userDetail",
  TEMPLATE: "/common/getTemplate",
  // WORDS
  CREATE_EDIT_WORD: "/admin_panel/v3.7/curriculum/addEditGallery",
  GET_WORDS: "/admin_panel/v3.7/curriculum/wordsList",
  GET_WORD_DETAIL: "/admin_panel/v3.7/curriculum/wordData",

  // TAGS
  GET_TAGS: "/admin_panel/v3.7/curriculum/tagsList",
  CREATE_EDIT_TAG: "/admin_panel/v3.7/curriculum/addEditTag",

  // CURRICULUM
  GET_CURRICULUMS: "/admin_panel/v3.7/curriculum/curriculumList",
  CREATE_EDIT_CURRICULUM: "/admin_panel/v3.7/curriculum/createEditCurriculum",
  GET_CURRICULUM_READING_PROGRAMS:
    "/admin_panel/v3.7/curriculum/getProgramsList",
  MAP_CURRICULUM_TO_READING_PROGRAM:
    "/admin_panel/v3.7/curriculum/createEditProgramList",

  //READING PROGRAM
  CREATE_EDIT_READING_PROGRAM:
    "/admin_panel/v3.7/curriculum/createEditReadingProgram",
  CREATE_EDIT_READING_PROGRAM_INNER_DATA:
    "/admin_panel/v3.7/curriculum/createEditReadingProgramData",
  GET_READING_PROGRAMS: "/admin_panel/v3.7/curriculum/readingProgramList",
  GET_READING_PROGRAM_DETAIL:
    "/admin_panel/v3.7/curriculum/readingProgramDetail",

  // MAP_CONTENTS_TO_USER
  MAP_CONTENTS_TO_USER: "/common/addContentToUserAccount",

  // WORDICON PACKS
  GET_WORDICON_PACKS_LIST: "/market_place/v3.9/getAllTubPacks",
  CREATE_EDIT_WORDICON_PACK: "/market_place/v3.9/createEditTubPacks",
  GET_WORDICON_PACK_DETAIL: "/market_place/v3.9/getPackDetail",
  CREATE_EDIT_WORDICON_PACK_INNER_DATA: "/market_place/v3.9/addWordiconsToPack",
  ADD_CONTENTS_TO_PACK: "/market_place/v3.9/addContentToPack",
  GET_WORDICON_PACK_DETAIL_LIST: "/market_place/v3.9/getPackContentDetail",
  GET_CONTENTS_DROPDOWN_LIST: "/market_place/v3.9/getcontentdropdown",

  //TUB SHEET
  GET_TUB_SHEETS_LIST: "/market_place/v3.9/getContentListApi",
  CREATE_EDIT_TUB_SHEET: "/market_place/v3.9/createAlphatubContent",
  GET_TUB_SHEET_DETAIL: "/market_place/v3.9/getContentDetailApi",

  //TUB CARD
  GET_TUB_CARDS_LIST: "/market_place/v3.9/getContentListApi",
  CREATE_EDIT_TUB_CARD: "/market_place/v3.9/createAlphatubContent",
  GET_TUB_CARD_DETAIL: "/market_place/v3.9/getContentDetailApi",

  // CATEGORIES
  GET_CATEGORIES: "/market_place/v3.9/getPcakCategoryListApi",
  CREATE_EDIT_CATEGORY: "/market_place/v3.9/createTubPackCategory",
  GET_PACKS_IN_CATEGORY: "/market_place/v3.9/getAllTubPacks",

  // SUBSCRIPTION RULE
  GET_SUBSCRIPTION_RULES_LIST: "/subscriptions/getSubscriptionRuleList",
  CREATE_EDIT_SUBSCRIPTION_RULE: "/subscriptions/createEditSubscriptionRule",
  GET_SUBSCRIPTION_DETAIL: "/subscriptions/getSubscriptionRuleDetail",

  // DEALS
  GET_DEALS_LIST: "/subscriptions/getDealMasterList",
  CREATE_EDIT_DEAL: "/subscriptions/createEditDealMaster",
  GET_DEAL_DETAIL: "/subscriptions/getDealMasterDetail",

  // COMBO
  GET_COMBO_LIST: "/subscriptions/getComboMasterList",
  CREATE_EDIT_COMBO: "/subscriptions/createEditComboMaster",
  GET_COMBO_DETAIL: "/subscriptions/getComboMasterDetail",
  //  school

  GET_SCHOOL_LISTING: "/admin_panel/v3.8/user/admins/schools",
  // GET_SCHOOL_LISTING: "/admin_panel/v3.8/user/admins/schools",
  //  user
  USER_ADMIN: "/admin_panel/v3.8/user/admins",
  Edit_USER_ADMIN: "/admin_panel/v3.8/user/subscription-type",
  USER_ALL_User: "/admin_panel/v3.8/user/all-users",
  //directors
  Create_Director: "/admin_panel/v3.8/user/directors",
  GET_Director: "/admin_panel/v3.8/user/directors",
  assignSchools: "/admin_panel/v3.8/user/directors/schools",
  Get_Countries: "/locations/countries",
  Get_States: "/locations/states",
  Get_Cities: "/locations/cities",
  Get_Director_Detail: "/admin_panel/v3.8/user/directors",
};

const accessHeaders = () => {
  const accessToken = localStorage.getItem("accessToken");
  return {
    headers: {
      Authorization: `${accessToken}`,
    },
  };
};

export const API_ENDPOINTS = {
  // template api
  template: (data) =>
    get(ENDPOINTS.TEMPLATE, {
      queryStringParameters: data,
      ...accessHeaders(),
    }),
  // upload image api
  uploadImage: (data) => {
    const headers = {
      headers: {
        Authorization: localStorage.getItem("accessToken"),
        // "origin": 'http://localhost:4200/'
      },
    };
    return post(ENDPOINTS.UPLOAD_IMAGE, { ...data, ...headers });
  },
  userProfile: (data) =>
    get(ENDPOINTS.USER_PROFILE, { ...data, ...accessHeaders() }),

  // words
  getWords: (data) => get(ENDPOINTS.GET_WORDS, { ...data, ...accessHeaders() }),
  createOrEditWord: (data) =>
    post(ENDPOINTS.CREATE_EDIT_WORD, { ...data, ...accessHeaders() }),
  getWordDetail: (id) =>
    get(ENDPOINTS.GET_WORD_DETAIL, {
      queryStringParameters: { _id: id },
      ...accessHeaders(),
    }),

  // tags
  getTags: (data) => get(ENDPOINTS.GET_TAGS, { ...data, ...accessHeaders() }),
  createOrEditTag: (data) =>
    post(ENDPOINTS.CREATE_EDIT_TAG, { ...data, ...accessHeaders() }),

  // curriculums
  getCurriculums: (data) =>
    get(ENDPOINTS.GET_CURRICULUMS, { ...data, ...accessHeaders() }),
  createOrEditCurriculum: (data) =>
    post(ENDPOINTS.CREATE_EDIT_CURRICULUM, { ...data, ...accessHeaders() }),
  getCurriculumReadingPrograms: (data) =>
    get(ENDPOINTS.GET_CURRICULUM_READING_PROGRAMS, {
      ...data,
      ...accessHeaders(),
    }),
  mapCurriculumToReadingProgram: (data) =>
    post(ENDPOINTS.MAP_CURRICULUM_TO_READING_PROGRAM, {
      ...data,
      ...accessHeaders(),
    }),

  // reading program
  getReadingPrograms: (data) =>
    get(ENDPOINTS.GET_READING_PROGRAMS, { ...data, ...accessHeaders() }),
  createOrEditReadingProgram: (data) =>
    post(ENDPOINTS.CREATE_EDIT_READING_PROGRAM, {
      ...data,
      ...accessHeaders(),
    }),
  createOrEditReadingProgramInnerData: (data) =>
    post(ENDPOINTS.CREATE_EDIT_READING_PROGRAM_INNER_DATA, {
      ...data,
      ...accessHeaders(),
    }),
  getReadingProgramDetail: (id) =>
    get(ENDPOINTS.GET_READING_PROGRAM_DETAIL, {
      queryStringParameters: { _id: id },
      ...accessHeaders(),
    }),

  // Map Contents To user
  mapContentsToUser: (emailId) =>
    get(ENDPOINTS.MAP_CONTENTS_TO_USER, {
      queryStringParameters: { emailId: emailId },
      ...accessHeaders(),
    }),

  // wordicon packs
  getTubPacks: (data) =>
    get(ENDPOINTS.GET_WORDICON_PACKS_LIST, { ...data, ...accessHeaders() }),
  getPackDetail: (id) =>
    get(ENDPOINTS.GET_WORDICON_PACK_DETAIL, {
      queryStringParameters: { id: id },
      ...accessHeaders(),
    }),
  createOrEditWordiconPack: (data) =>
    post(ENDPOINTS.CREATE_EDIT_WORDICON_PACK, { ...data, ...accessHeaders() }),
  createOrEditPackInnerData: (data) =>
    post(ENDPOINTS.CREATE_EDIT_WORDICON_PACK_INNER_DATA, {
      ...data,
      ...accessHeaders(),
    }),
  getWordiconPackDetailList: (id) =>
    get(ENDPOINTS.GET_WORDICON_PACK_DETAIL_LIST, {
      queryStringParameters: { id: id },
      ...accessHeaders(),
    }),
  addContentsToPack: (data) =>
    post(ENDPOINTS.ADD_CONTENTS_TO_PACK, { ...data, ...accessHeaders() }),
  getContentsDropdownData: (data) =>
    get(ENDPOINTS.GET_CONTENTS_DROPDOWN_LIST, { ...data, ...accessHeaders() }),

  // tub sheets
  getTubSheets: (query) =>
    get(ENDPOINTS.GET_TUB_SHEETS_LIST, { ...query, ...accessHeaders() }),
  getTubSheetDetail: (id) =>
    get(ENDPOINTS.GET_TUB_SHEET_DETAIL, {
      queryStringParameters: { id: id },
      ...accessHeaders(),
    }),
  createOrEditTubSheet: (data) =>
    post(ENDPOINTS.CREATE_EDIT_TUB_SHEET, { ...data, ...accessHeaders() }),

  // tub card
  getTubCards: (query) =>
    get(ENDPOINTS.GET_TUB_CARDS_LIST, { ...query, ...accessHeaders() }),
  getTubCardDetail: (id) =>
    get(ENDPOINTS.GET_TUB_CARD_DETAIL, {
      queryStringParameters: { id: id },
      ...accessHeaders(),
    }),
  createOrEditTubCard: (data) =>
    post(ENDPOINTS.CREATE_EDIT_TUB_CARD, { ...data, ...accessHeaders() }),

  // categories
  getCategories: (query) =>
    get(ENDPOINTS.GET_CATEGORIES, { ...query, ...accessHeaders() }),
  createOrEditCategory: (data) =>
    post(ENDPOINTS.CREATE_EDIT_CATEGORY, { ...data, ...accessHeaders() }),
  getPacksInCategory: (query) =>
    get(ENDPOINTS.GET_PACKS_IN_CATEGORY, { ...query, ...accessHeaders() }),

  // Subscription rules
  getSubscriptionRulesList: (query) =>
    get(ENDPOINTS.GET_SUBSCRIPTION_RULES_LIST, {
      ...query,
      ...accessHeaders(),
    }),
  createOrEditSubscriptionRule: (data) =>
    post(ENDPOINTS.CREATE_EDIT_SUBSCRIPTION_RULE, {
      ...data,
      ...accessHeaders(),
    }),
  getSubscriptionRuleDetail: (id) =>
    get(ENDPOINTS.GET_SUBSCRIPTION_DETAIL, {
      queryStringParameters: { id: id },
      ...accessHeaders(),
    }),

  // Deals
  getDealsList: (query) =>
    get(ENDPOINTS.GET_DEALS_LIST, { ...query, ...accessHeaders() }),
  createOrEditDeal: (data) =>
    post(ENDPOINTS.CREATE_EDIT_DEAL, { ...data, ...accessHeaders() }),
  getDealDetail: (id) =>
    get(ENDPOINTS.GET_DEAL_DETAIL, {
      queryStringParameters: { id: id },
      ...accessHeaders(),
    }),

  // Combo
  getCombosList: (query) =>
    get(ENDPOINTS.GET_COMBO_LIST, { ...query, ...accessHeaders() }),
  createOrEditCombo: (data) =>
    post(ENDPOINTS.CREATE_EDIT_COMBO, { ...data, ...accessHeaders() }),
  getComboDetail: (id) =>
    get(ENDPOINTS.GET_COMBO_DETAIL, {
      queryStringParameters: { id: id },
      ...accessHeaders(),
    }),
  // SCHOOL
  getSchool: (data) =>
    get(ENDPOINTS.GET_SCHOOL_LISTING, {
      queryStringParameters: { ...data },
      ...accessHeaders(),
    }),

  createSchool: (data) =>
    post(ENDPOINTS.GET_SCHOOL_LISTING, {
      body: { ...data },
      ...accessHeaders(),
    }),
  UpdateSchool: (id, data) =>
    Patch(`${ENDPOINTS.GET_SCHOOL_LISTING}/${id}`, {
      body: { ...data },
      ...accessHeaders(),
    }),
  // USER
  CreateUser: (data) =>
    post(ENDPOINTS.USER_ADMIN, {
      body: { ...data },
      ...accessHeaders(),
    }),
  EditUser: (data) =>
    Patch(ENDPOINTS.Edit_USER_ADMIN, {
      body: { ...data },
      ...accessHeaders(),
    }),
  getUser: (data) =>
    get(ENDPOINTS.USER_ALL_User, {
      queryStringParameters: { ...data },
      ...accessHeaders(),
    }),
  // DIRECTOR

  createDirector: (data) =>
    post(ENDPOINTS.Create_Director, {
      body: { ...data },
      ...accessHeaders(),
    }),
  GET_Director: (data) =>
    get(ENDPOINTS.GET_Director, {
      queryStringParameters: { ...data },
      ...accessHeaders(),
    }),
  assignSchools: (data) =>
    post(ENDPOINTS.assignSchools, {
      body: { ...data },
      ...accessHeaders(),
    }),

  getCountries: (data) =>
    get(ENDPOINTS.Get_Countries, {
      queryStringParameters: { ...data },
      ...accessHeaders(),
    }),

  getStates: (data) =>
    get(ENDPOINTS.Get_States, {
      queryStringParameters: { ...data },
      ...accessHeaders(),
    }),

  getCities: (data) =>
    get(ENDPOINTS.Get_Cities, {
      queryStringParameters: { ...data },
      ...accessHeaders(),
    }),

  getdirectorDetail: (data) =>
    get(ENDPOINTS.Get_Director_Detail, {
      queryStringParameters: { ...data },
      ...accessHeaders(),
    }),
};
