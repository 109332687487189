import {
  GET_TUB_SHEETS,
  GET_TUB_SHEETS_SUCCESS,

  ADD_NEW_TUB_SHEET,
  ADD_TUB_SHEET_SUCCESS,

  GET_TUB_SHEET_DETAIL,
  GET_TUB_SHEET_DETAIL_SUCCESS,

  UPDATE_TUB_SHEET,
  UPDATE_TUB_SHEET_SUCCESS
  
} from "./actionTypes"

export const getTubSheets = (queryPayload) => ({
  type: GET_TUB_SHEETS,
  payload: queryPayload
})
export const getTubSheetsSuccess = tubSheets => ({
  type: GET_TUB_SHEETS_SUCCESS,
  payload: tubSheets,
})


export const addNewTubSheet = data => ({
  type: ADD_NEW_TUB_SHEET,
  payload: data,
})
export const addTubSheetSuccess = () => ({
  type: ADD_TUB_SHEET_SUCCESS,
  payload: null,
})


export const getTubSheetDetail = (params) => ({
  type: GET_TUB_SHEET_DETAIL,
  payload: params
})
export const getTubSheetDetailSuccess = data => ({
  type: GET_TUB_SHEET_DETAIL_SUCCESS,
  payload: data,
})


export const updateTubSheet = data => ({
  type: UPDATE_TUB_SHEET,
  payload: data,
})
export const updateTubSheetSuccess = () => ({
  type: UPDATE_TUB_SHEET_SUCCESS,
  payload: null,
})