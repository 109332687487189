import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import SetNewPassword from "./auth/setUpNewPwd/reducer"
import words from "./words/reducer"
import tags from "./tags/reducer"
import curriculums from './curriculum/reducer'
import loader from "./loader/reducer"
import wordiconPack from './wordiconPacks/reducer'
import tubSheet from './tubSheet/reducer'
import tubCard from './tubCard/reducer'
import categories from './categories/reducer'
import orders from './orders/reducer'
import customers from './customers/reducer';
import coupons from './coupons/reducer';
import subscriptionRule from './subscriptionRule/reducer';
import deals from './deals/reducer';
import combo from './combo/reducer';


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  SetNewPassword,
  words,
  tags,
  curriculums,
  loader,
  wordiconPack,
  tubSheet,
  tubCard,
  categories,
  orders,
  customers,
  coupons,
  subscriptionRule,
  deals,
  combo
})

export default rootReducer
