import React, { useEffect } from "react";
import {
  Container,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import {  OrdersColumn } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import { getOrders } from "store/actions";
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { history } from "helpers/utils";

const OrdersList = (props) => {
    const dispatch = useDispatch();

    const { ordersList } = useSelector(state => ({
        ordersList: state.orders.ordersList,
    }));
    
    useEffect(() => {
        let queryPayload = {
            search: '',
        }
        // dispatch(getOrders(queryPayload));
    }, [dispatch, props]);

    const getApplicationDetails = (row) => {
        props.history.push(`/staff/order/detail/${row[9]}`)
    }
    const filterList = (queryPayload) => {
        let payload = {
            ...queryPayload,
        }
        // dispatch(getOrders(payload))
    }


    const getTableColumns = () => {
        let editObj = {
            label: "Actions",
            name: '',
            options: { 
                filter: true,
                sort: false, 
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className='d-flex align-items-center justify-content-center'>
                        <div
                            className="ms-1"
                            onClick={() => getApplicationDetails(tableMeta?.rowData, true)}
                        >
                            <MdEdit size={16}/>
                        </div>  
                    </div>
                )
            }
        }
        let newtable = [...OrdersColumn,editObj]
         return newtable
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Marketplace" breadcrumbItem="Orders" />
                    <CustomBootstrapTable 
                        rows = {ordersList}
                        columns = {getTableColumns()}
                        isLoading={false}
                        showCreateBtn = {false}
                        keyField={'_id'}
                        createBtnLabel=""
                        onCreateRedirect = {`/staff/order/detail`}
                        getApplicationDetails={(row) => null}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                        filterList = {(...params) => filterList(...params)}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export { OrdersList }