import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
} from "./actionTypes"

export const getCustomers = (queryPayload) => ({
  type: GET_CUSTOMERS,
  payload: queryPayload
})

export const getCustomersSuccess = categories => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: categories,
})