import { formatDate } from "./format_helper";
import placeholderIc from "assets/images/icons/Placeholder.png";
import _ from "lodash";
import { createTheme } from "@mui/material/styles";
import descriptionIc from "assets/images/icons/description.png";

export const getMuiTheme = () =>
  createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            boxShadow: "none !important",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            "& .MuiTableCell-paddingCheckbox": {
              display: "none !important",
            },
            "& .MuiTableCell-root": {
              textAlign: "center",
            },
            "& .MuiTableCell-root span": {
              justifyContent: "flex-start",
            },
            "& .MuiTableCell-root span .MuiButton-root": {
              padding: "0px",
              justifyContent: "flex-start",
            },
            "& .MuiTableCell-root div": {
              fontFamily: "Poppins, sans-serif",
              fontSize: "11px",
              fontWeight: 600,
              textTransform: "capitalize",
            },
          },
        },
      },

      MuiTableBody: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-paddingCheckbox": {
              display: "none !important",
            },
            "& .MuiTableRow-root": {
              cursor: "pointer",
            },
            "& .MuiTableRow-root .MuiTableCell-root div": {
              fontFamily: "Poppins, sans-serif",
              fontSize: "11px",
              textAlign: "center",
            },
            "& .MuiTableCell-root div p": {
              margin: "0px",
            },
          },
          MuiCheckbox: {
            display: "none",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
          },
          head: {
            borderTop: "1px solid #EFF2F7",
            borderBottom: "1px solid #EFF2F7",
            backgroundColor: "#eff2f7 !important",
            padding: "8px",
            color: "#000000",
            fontWeight: 600,
          },
          body: {
            padding: "8px",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            margin: "5px 0px !important",
            minHeight: "30px !important",
            padding: "0px !important",
            "& .MuiTypography-h6": {
              fontSize: "19px !important",
            },
            "& .MuiTablePagination-selectLabel": {
              margin: "0 !important",
            },
            "& .MuiTablePagination-displayedRows": {
              margin: "0 !important",
            },
          },
        },
      },
      //   MUIDataTableSelectCell: {
      //     styleOverrides: {
      //         root: {
      //             '& .MuiCheckbox-root': {
      //                 display: 'none !important'
      //             }
      //         },
      //         headerCell: {
      //             backgroundColor: 'blue',
      //         },
      //     },
      //   },
      MuiPaper: {
        styleOverrides: {
          root: {
            "& fieldset": {
              padding: "16px 24px 16px 24px !important",
            },
            "& .MuiList-root .MuiTablePagination-menuItem": {
              display: "block",
            },
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            "& .MuiToolbar-root": {
              padding: "0px !important",
            },
            "& .MuiTableCell-footer": {
              padding: "0px !important",
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "& .MuiSvgIcon-root": {
              opacity: 1,
            },
          },
        },
      },
    },
  });

export const WordsColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Word Id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Icon",
    name: "originalImage",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return (
          <img
            src={
              !_.isEmpty(rowData[2]?.original)
                ? rowData[2]?.original
                : placeholderIc
            }
            style={{ width: "40px", height: "40px" }} alt=""
          />
        );
      },
    },
  },
  {
    label: "Word",
    name: "title",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="primary m-0 text-left">{value}</p>
      ),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },
  {
    label: "Tags",
    sort: true,
    name: "tags",
    options: {
      filter: true,
      sort: false,
      setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
      setCellProps: () => ({ style: { whiteSpace: "wrap" } }),
      customBodyRender: (value) => (
        <p className="text-left">{value && value.join(",")}</p>
      ),
    },
  },
  {
    label: "Description",
    name: "description",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="description_ic">
          <img src={descriptionIc} alt=""/>
          <p className="table_description">{value}</p>
        </div>
      ),
    },
  },
  // {
  //   label: "Reading Program Count",
  //   name: "readingProgram_Count",
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       <p>{value}</p>
  //     )
  //   }
  // },
];

export const TagsColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Tag Id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Tags",
    name: "tags",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="primary m-0 text-left">{value}</p>
      ),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },
  {
    label: "Word count",
    name: "word_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return <p>{rowData[4]}</p>;
      },
    },
  },
  // {
  //   label: "Modified At",
  //   name: 'updatedAt',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       formatDate("DD-MM-YYYY", value)
  //     )
  //   }
  // },
  // {
  //   label: "Created At",
  //   name: 'createdAt',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       formatDate("DD-MM-YYYY", value)
  //     )
  //   }
  // }
];

export const CurriculumColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Curriculum Id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Logo",
    name: "logo",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return (
          <img
            src={
              !_.isEmpty(rowData[2]?.original)
                ? rowData[2]?.original
                : placeholderIc
            }
            style={{ width: "40px", height: "40px" }} alt=""
          />
        );
      },
    },
  },
  {
    label: "Curriculum",
    name: "curriculum_Name",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="primary m-0 text-left">{value}</p>
      ),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },
  // {
  //   text: "Reading Programs",
  //   dataField: 'readingPrograms'
  // },
  // {
  //   text: "Regions",
  //   dataField: "regions"
  // },
  // {
  //   label: "Modified At",
  //   name: 'updatedAt',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       formatDate("DD-MM-YYYY", value)
  //     )
  //   }
  // },
  // {
  //   label: "Created At",
  //   name: 'createdAt',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       formatDate("DD-MM-YYYY", value)
  //     )
  //   }
  // }
];

export const ReadingProgramsColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Program Id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Logo",
    name: "logo",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return (
          <img
            src={
              !_.isEmpty(rowData[2]?.original)
                ? rowData[2]?.original
                : placeholderIc
            }
            style={{ width: "40px", height: "40px" }} alt=""
          />
        );
      },
    },
  },
  {
    label: "Reading Programs",
    name: "program_Name",
    options: {
      filter: true,
      sort: true,
      setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="primary m-0 text-left">{value}</p>
      ),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },
  {
    label: "Words",
    name: "word_count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return (
          <p className="m-0">
            {!_.isEmpty(rowData[5]) ? rowData[5]?.wordCount : 0}
          </p>
        );
      },
    },
  },
  // {
  //   text: "Regions",
  //   dataField: "regions"
  // },
  // {
  //   label: "Modified At",
  //   name: 'updatedAt',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       formatDate("DD-MM-YYYY", value)
  //     )
  //   }
  // },
  // {
  //   label: "Created At",
  //   name: 'createdAt',
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       formatDate("DD-MM-YYYY", value)
  //     )
  //   }
  // }
];

export const CurriculumWiseReadingProgramsColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Program Id",
    name: "reading_Program_Id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Logo",
    name: "logo",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return (
          <img
            src={
              !_.isEmpty(rowData[2]?.original)
                ? rowData[2]?.original
                : placeholderIc
            }
            style={{ width: "40px", height: "40px" }} alt=""
          />
        );
      },
    },
  },
  {
    label: "Reading Programs",
    name: "program_Name",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="primary m-0 text-left">{value}</p>
      ),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },
  {
    label: "Words",
    name: "word_count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return <p className="m-0">{rowData[5]}</p>;
      },
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const WordiconPacksColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Pack",
    name: "pack_Name",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },
  {
    label: "Rating",
    name: "content_For_Rating",
    options: {
      filter: true,
      sort: false,
      setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => <p className="m-0">{value}</p>,
    },
  },

  {
    label: "Users",
    name: "word_count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return (
          <p className="m-0">
            {!_.isEmpty(rowData[5]) ? rowData[5]?.wordCount : 0}
          </p>
        );
      },
    },
  },
  {
    label: "Wordicons",
    name: "Wordicon_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "TUB Sheets",
    name: "Sheet_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "TUB Cards",
    name: "Card_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Price",
    name: "price",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="m-0">{value !== null ? `$ ${value}` : "$ 0"}</p>
      ),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const CategoriesColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Category Image",
    name: "category_Image",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta) => {
        const tableData = tableMeta.tableData;
        const index = tableMeta.rowIndex;
        const rowData = tableData[index];
        return (
          <img
            src={
              !_.isEmpty(rowData[1]?.original)
                ? rowData[1]?.original
                : placeholderIc
            }
            style={{ width: "40px", height: "40px" }} alt=""
          />
        );
      },
    },
  },
  {
    label: "Name",
    name: "category_Name",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },

  {
    label: "Packs",
    name: "Pack_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      // customBodyRender: (value, tableMeta) => {
      //   const tableData = tableMeta.tableData;
      //   const index = tableMeta.rowIndex;
      //   const rowData = tableData[index];
      //   return <p>{rowData[4]}</p>
      // }
    },
  },
  {
    label: "colour",
    name: "colour",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];
export const WordiconPacksDetailListColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Item Name",
    name: "ObjectName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },
  {
    label: "Type",
    name: "tub_ObjectType",
    options: {
      filter: true,
      sort: true,
      setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="primary m-0 text-left">{value}</p>
      ),
    },
  },
  {
    label: "Wordicons",
    name: "Wordicons",
    options: {
      filter: false,
      sort: false,
      setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
      setCellProps: () => ({ style: { whiteSpace: "wrap" } }),
      customBodyRender: (value) => (
        <p className="text-left">{value ? value.length : 0}</p>
      ),
    },
  },
  {
    label: "Tags",
    sort: true,
    name: "tags",
    options: {
      filter: true,
      sort: false,
      setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
      setCellProps: () => ({ style: { whiteSpace: "wrap" } }),
      customBodyRender: (value) => (
        <p className="text-left">
          {Array.isArray(value) ? value.slice(0, 4).join(",") : "-"}
        </p>
      ),
    },
  },
  {
    label: "Price",
    name: "price",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="m-0">{value ? `$ ${value}` : "$ 0"}</p>
      ),
    },
  },
  {
    label: "_id",
    name: "tub_ObjectId",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const TubsheetListColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Name",
    name: "ObjectName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Status",
    name: "isActive",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p className={`${value ? "primary_bg" : "danger_bg"}`}></p>
        </div>
      ),
    },
  },
  {
    label: "Packs",
    name: "rating",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Tags",
    sort: true,
    name: "tags",
    options: {
      filter: true,
      sort: false,
      setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
      setCellProps: () => ({ style: { whiteSpace: "wrap" } }),
      customBodyRender: (value) => (
        <p className="text-left">{value && value.join(",")}</p>
      ),
    },
  },
  {
    label: "Description",
    name: "desc",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="description_ic">
          <img src={descriptionIc} alt="" />
          <p className="table_description">{value}</p>
        </div>
      ),
    },
  },
  // {
  //   label: "Price",
  //   name: "price",
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       <p className="m-0">{ value !== null ? `$ ${value}` : '$ 0' }</p>
  //     )
  //   }
  // },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const TubcardListColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Name",
    name: "ObjectName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Status",
    name: "isActive",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p className={`${value ? "primary_bg" : "danger_bg"}`}></p>
        </div>
      ),
    },
  },
  {
    label: "Packs",
    name: "rating",
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Tags",
    sort: true,
    name: "tags",
    options: {
      filter: true,
      sort: false,
      setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
      setCellProps: () => ({ style: { whiteSpace: "wrap" } }),
      customBodyRender: (value) => (
        <p className="text-left">{value && value.join(",")}</p>
      ),
    },
  },
  {
    label: "Description",
    name: "desc",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="description_ic">
          <img src={descriptionIc} alt="" />
          <p className="table_description">{value}</p>
        </div>
      ),
    },
  },
  // {
  //   label: "Price",
  //   name: "price",
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //     customBodyRender: (value) => (
  //       <p className="m-0">{ value !== null ? `$ ${value}` : '$ 0' }</p>
  //     )
  //   }
  // },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const OrdersColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Order",
    name: "pack_Name",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Date",
    name: "date",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => formatDate("DD-MM-YYYY", value),
    },
  },
  {
    label: "Status",
    name: "status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="status_dot">
          <p
            className={`${value === "Active" ? "primary_bg" : "danger_bg"}`}
          ></p>
        </div>
      ),
    },
  },
  {
    label: "Price",
    name: "price",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <p className="m-0">{value !== null ? `$ ${value}` : "$ 0"}</p>
      ),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const CustomersColumns = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Name",
    name: "name",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Username",
    name: "username",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Email",
    name: "email",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Orders",
    name: "orders",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Total Spend",
    name: "totalsPEND",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Average Spend",
    name: "Average Spend",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Country",
    name: "Country",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const CouponsColumn = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Code",
    name: "code",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "couponType",
    name: "couponType",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "CouponAmount",
    name: "CouponAmount",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Description",
    name: "description",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => (
        <div className="description_ic">
          <img src={descriptionIc} alt="" />
          <p className="table_description">{value}</p>
        </div>
      ),
    },
  },
  {
    label: "Usage / Limit",
    name: "usage",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Created At",
    name: "createdAt",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => formatDate("DD-MM-YYYY", value),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const subscriptionruLESlist = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Subscrption Name",
    name: "subscrption_Name",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Subscrption Time Period",
    name: "subscrption_Time_Period",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "players Count",
    name: "players_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },

  {
    label: "Game Play Count",
    name: "game_Play_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "social Circle Count",
    name: "social_Circle_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Free Packs Count",
    name: "free_Packs_Count",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const DealsColumnList = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Deal Code",
    name: "deal_code",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Start Date",
    name: "StartDate",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => formatDate("DD-MM-YYYY", value),
    },
  },
  {
    label: "Expiry Date",
    name: "expiry_date",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => formatDate("DD-MM-YYYY", value),
    },
  },
  {
    label: "Validity",
    name: "Validity",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },

  {
    label: "Discount on this Deal ",
    name: "available_Discount",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Status",
    name: "Status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const ComboColumnList = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "combo code",
    name: "ComboCode",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Deal code",
    name: "deal_code",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Start Date",
    name: "StartDate",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => formatDate("DD-MM-YYYY", value),
    },
  },
  {
    label: "Expiry date",
    name: "expiry_date",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => formatDate("DD-MM-YYYY", value),
    },
  },
  {
    label: "Validity",
    name: "Validity",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },

  {
    label: "Discount",
    name: "available_Discount",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },

  {
    label: "Status",
    name: "Status",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];

export const SchoolList = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Admin Name",
    name: "PrincipalFirstName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value ? value : " -"}</p>
      ),
    },
  },
  {
    label: "School Name ",
    name: "SchoolName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value ? value : " -"}</p>
      ),
    },
  },
  {
    label: "Creation Date",
    name: "createdAt",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      // customBodyRender: (value, tableMeta, updateValue) => (
      //   <p>{value ? value : " -"}</p>
      // ),
      customBodyRender: (value, tableMeta, updateValue) => {
        // Check if value exists and is not null or undefined
        if (value) {
          // Convert value to a Date object
          const date = new Date(value);
          // Array of month names
          const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];
          // Get the month index from the Date object
          const monthIndex = date.getMonth();
          // Get the month name from the array
          const monthName = monthNames[monthIndex];
          // Format the date as desired (e.g., "January 1, 2024 12:00:00")
          const formattedDate = `${monthName} ${date.getDate()}, ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
          return <p>{formattedDate}</p>;
        } else {
          return <p>-</p>; // If value is null or undefined, display "-"
        }
      },
    },
  },

  // {
  //   label: "School Email ID",
  //   name: "SchoolEmailID",
  //   options: {
  //     filter: true,
  //     sort: false,
  //     setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
  //   },
  // },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "SchoolAddr1",
    name: "SchoolAddr1",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "SchoolAddr2",
    name: "SchoolAddr2",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "AffiliatedID",
    name: "AffiliatedID",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "ZipCode",
    name: "ZipCode",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "subscriptions",
    name: "subscriptions",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Country",
    name: "Country",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "State",
    name: "State",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "City",
    name: "City",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "license",
    name: "license",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];
export const UserList = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "First Name",
    name: "First_name",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "School Name",
    name: "teams",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>
          {tableMeta?.rowData[2]?.[0]?.schools?.SchoolName
            ? tableMeta?.rowData[2]?.[0]?.schools?.SchoolName
            : " -"}
        </p>
      ),
    },
  },
  {
    label: "Role Name",
    name: "RoleName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },

  {
    label: " Email ID",
    name: "Email",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "subscriptions",
    name: "subscriptions",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "Mobile",
    name: "Mobile",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "lastName",
    name: "Last_name",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];
export const DirectorSchoolListing = [
  // {
  //   name: "checkbox",
  //   label: <input type="checkbox" />,
  //   options: {
  //     filter: false,
  //     sort: false,
  //     customBodyRenderLite: (dataIndex) => {
  //       return <input type="checkbox" />;
  //     },
  //   },
  // },
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Principal Name",
    name: "PrincipalFirstName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value ? value : " -"}</p>
      ),
    },
  },
  {
    label: "School Name ",
    name: "SchoolName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value ? value : " -"}</p>
      ),
    },
  },

  {
    label: "School Email ID",
    name: "SchoolEmailID",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },

  {
    label: "Address ",
    name: "SchoolAddr2",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value ? value : " -"}</p>
      ),
    },
  },

  {
    label: "Country",
    name: "Country",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value && value.label ? value.label : " -"}</p>
      ),
    },
  },
  {
    label: "State",
    name: "State",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value && value.label ? value.label : " -"}</p>
      ),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "subscriptions",
    name: "subscriptions",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];
export const DirectorDetails = [
  {
    name: "S.No",
    label: "S.No.",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{tableMeta.rowIndex + 1}</p>
      ),
    },
  },
  {
    label: "Admin Name",
    name: "PrincipalFirstName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value ? value : " -"}</p>
      ),
    },
  },
  {
    label: "School Name ",
    name: "SchoolName",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value ? value : " -"}</p>
      ),
    },
  },

  {
    label: "Address ",
    name: "SchoolAddr1",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value ? value : " -"}</p>
      ),
    },
  },

  {
    label: "Country",
    name: "Country",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value && value.label ? value.label : " -"}</p>
      ),
    },
  },
  {
    label: "State",
    name: "State",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value && value.label ? value.label : " -"}</p>
      ),
    },
  },
  {
    label: "City",
    name: "City",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value, tableMeta, updateValue) => (
        <p>{value && value.label ? value.label : " -"}</p>
      ),
    },
  },
  {
    label: "Created Date",
    name: "CreatedDate",
    options: {
      filter: true,
      sort: false,
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
      customBodyRender: (value) => formatDate("DD-MM-YYYY", value),
    },
  },
  {
    label: "_id",
    name: "_id",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
  {
    label: "subscriptions",
    name: "subscriptions",
    options: {
      filter: true,
      sort: false,
      display: "excluded",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
  },
];
