/* TUB_SHEETS */
export const GET_TUB_SHEETS = "GET_TUB_SHEETS"
export const GET_TUB_SHEETS_SUCCESS = "GET_TUB_SHEETS_SUCCESS"
export const GET_TUB_SHEETS_FAIL = "GET_TUB_SHEETS_FAIL"

/* TUB_SHEETS DETAIL */
export const GET_TUB_SHEET_DETAIL = "GET_TUB_SHEET_DETAIL"
export const GET_TUB_SHEET_DETAIL_SUCCESS = "GET_TUB_SHEET_DETAIL_SUCCESS"
export const GET_TUB_SHEET_DETAIL_FAIL = "GET_TUB_SHEET_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_TUB_SHEET = "ADD_NEW_TUB_SHEET"
export const ADD_TUB_SHEET_SUCCESS = "ADD_TUB_SHEET_SUCCESS"
export const ADD_TUB_SHEET_FAIL = "ADD_TUB_SHEET_FAIL"

/**
 * udpates user
 */
export const UPDATE_TUB_SHEET = "UPDATE_TUB_SHEET"
export const UPDATE_TUB_SHEET_SUCCESS = "UPDATE_TUB_SHEET_SUCCESS"
export const UPDATE_TUB_SHEET_FAIL = "UPDATE_TUB_SHEET_FAIL"