import React, { useEffect, useState, useCallback } from "react";
import { withRouter } from "react-router-dom";
import {
    Container,
    Row,
    Form,
    Col,
    CardTitle,
    Label
} from "reactstrap";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { CategoriesColumn } from "helpers/tableDataMapper";
import { ModalContainer } from "components/Modal";
import FormInput from "components/FormInput";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import { useSelector, useDispatch } from "react-redux";
import {
    getCategories,
    createOrEditCategory
} from "store/categories/actions";
import { API_ENDPOINTS } from "api/endpoints";
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import _ from 'lodash'
import { ChromePicker } from 'react-color';
import { history } from "helpers/utils";

const Category = (props) => {
    const defaultCategoryData = {
        category_Name: '',
        category_Image: {
            original: ''
        },
        colour: {
            hex: '#000000'
        },
        status: true
    }

    const dispatch = useDispatch();

    const [isOpen, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false);

    const [categoryDetail, setDetail] = useState({
        category_Name: null,
        category_Image: {
            original: ''
        },
        colour: {
            hex: '#000000'
        },
        status: true
    })
    const { categoriesList } = useSelector(state => ({
        categoriesList: state.categories.categoriesList,
    }));

    useEffect(() => {
        let queryPayload = {
            search: ''
        }
        dispatch(getCategories(queryPayload));
    }, [dispatch]);

    const onChange = (name, value) => {
        if (name == 'category_Image') {
            uploadImage(value)
        } else {
            setDetail({ ...categoryDetail, [name]: value })
        }
    }

    const uploadImage = async (value) => {
        let formData = new FormData()
        formData.append('image', value)
        try {
            const myInit = {
                body: formData,
            };
            const response = await API_ENDPOINTS.uploadImage(myInit)
            setDetail({
                ...categoryDetail,
                category_Image: {
                    original: response?.originalImage?.original,
                }
            })
            validation.setFieldValue("category_Image", response?.originalImage?.original)
        } catch (error) {
            console.log('error', error)
        }
    }

    const getApplicationDetails = (row, edit) => {
        setIsEdit(edit)
        if (edit) {
            setDetail({
                ...categoryDetail,
                _id: row[6],
                category_Name: row[2],
                category_Image: {
                    original: row[1]?.props?.src
                },
                status: row[3]?.props?.children?.props?.className === 'primary_bg' ? true : false,
                colour: {
                    hex: row[5]
                }
            })
            validation.setFieldValue('category_Image', row[1]?.props?.src)
            validation.setFieldValue('category_Name', row[2])
        }
        setModal(true)
    }

    const save = async (isDelete) => {
        let body = {
            ...categoryDetail,
            category_Name: categoryDetail.category_Name,
            AWS_UserName: localStorage.getItem('username'),
            status: categoryDetail.status ? 'Active' : 'InActive',
            colour: categoryDetail.colour?.hex,

        }

        if (isDelete) {
            body = {
                ...body,
                isDeleted: true
            }
        }

        dispatch(createOrEditCategory(body))
        setDetail({ ...categoryDetail, ...defaultCategoryData })
        setModal(false)
    }

    const filterList = (queryPayload) => {
        dispatch(getCategories(queryPayload))
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            category_Name:  "",
            category_Image: "",
        },
        validationSchema: Yup.object({
            category_Name: Yup.string().required("Please Enter Category Name"),
            category_Image: Yup.string().required('Category Image is required'),
        }),
        onSubmit: (values) => {
            let body = {
                AWS_UserName: localStorage.getItem('username'),
            }
            if (isEdit) {
                body = {
                    ...body,
                    _id: categoryDetail._id,
                    category_Name: values.category_Name,
                    colour: categoryDetail.colour?.hex,
                    status: categoryDetail.status ? 'Active' : 'InActive',
                    category_Image: {
                        original: values.category_Image
                    }
                }
            } else {
                body = {
                    ...values,
                    category_Name: values.category_Name,
                    colour: categoryDetail.colour?.hex,
                    status: categoryDetail.status ? 'Active' : 'InActive',
                    category_Image: {
                        original: values.category_Image
                    }
                }
            }
            dispatch(createOrEditCategory(body));
            validation.resetForm();

            setModal(false)
        }
    });

    const toggle = (type) => {
        validation.resetForm();
        setDetail({ ...categoryDetail, ...defaultCategoryData })
        setModal(type)
    }
    const redirectTo = (row) => {
        console.log('row', row)
        history.push(`/staff/category/pack/list?categoryName=${row[2]}`)
    }

    const getTableColumns = () => {
        let editObj = {
            label: "Actions",
            name: '',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className='d-flex align-items-center justify-content-center'>
                        <div
                            onClick={() => redirectTo(tableMeta?.rowData)}
                        >
                            <MdOutlineRemoveRedEye size={18}/>
                        </div>
                        <div
                            className="ms-1"
                            onClick={() => getApplicationDetails(tableMeta.rowData, true)}
                        >
                            <MdEdit size={16} />
                        </div>
                    </div>
                )
            }
        }
        let newtable = [...CategoriesColumn, editObj]
        return newtable
    }

    const useFormField = (fieldName, setFieldValue) => {
        const handleChange = useCallback(
          (event) => {
            const { name, value } = event.target;
            setFieldValue(name, value);
          },
          [setFieldValue]
        );
      
        const handleBlur = useCallback(() => {
            validation.setFieldTouched(fieldName, true);
        }, [fieldName, validation.setFieldTouched]);
      
        return {
          onChange: handleChange,
          onBlur: handleBlur,
        };
    };

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <CardTitle className="page-title-box">CATEGORIES</CardTitle>
                    <CustomBootstrapTable
                        rows={categoriesList}
                        columns={getTableColumns()}
                        showCreateBtn={true}
                        isLoading={false}
                        keyField={'_id'}
                        createBtnLabel="Add New Category"
                        onCreateRedirect={null}
                        filterList={(...params) => filterList(...params)}
                        triggerPopup={() => getApplicationDetails(defaultCategoryData, false)}
                        getApplicationDetails={(row) => getApplicationDetails(row, true)}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history={props.history}
                    />
                </Container>
            </div>
            <ModalContainer
                isOpen={isOpen}
                toggle={() => toggle(false)}
                title={isEdit ? 'Edit Category' : 'Add New Category'}
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <FormikFormInput
                        placeholder="Enter Category Name"
                        name="category_Name"
                        type='text'
                        formGroupClassName=""
                        maxLength = {20}
                        label='Category Name'
                        labelClassName="col-form-label"
                        value={validation.values['category_Name']}
                        {...useFormField("category_Name", validation.setFieldValue)}
                        validation={validation}
                    />
                    <LogoUpload
                        title="Upload Category Image"
                        name="category_Image"
                        preview={validation.values['category_Image']}
                        onChange={(...params) => onChange(...params)}
                        validation={validation}
                        removeImage={() => validation.setFieldValue("category_Image", '' )}
                        error = { _.isEmpty(validation.values['category_Image']) ? validation?.errors?.category_Image : ''}
                    />
                    <div>
                        <Label>Choose Color</Label>
                        <ChromePicker 
                            color={categoryDetail["colour"]} 
                            onChange={(color) => onChange("colour", color)} 
                        />
                    </div>
                    <FormInput
                        name="status"
                        formGroupClassName="row mt-3"
                        label='Status'
                        type={"two-way-switch"}
                        leftLabel="Inactive"
                        rightLabel="Active"
                        labelClassName="col-md-2 col-form-label"
                        value={categoryDetail['status']}
                        onChange={(...params) => onChange(...params)}
                    />

                    <Row className="align-items-center">
                        {isEdit &&
                            <Col>
                                <div>
                                    <p className="danger" onClick={() => save(true)}>Delete Category</p>
                                </div>
                            </Col>
                        }
                        <Col>
                            <div className='d-flex justify-content-end'>
                                <button
                                    type="submit"
                                    className="px-4 primary_btn"
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalContainer>
        </>
    )
}

export { Category }