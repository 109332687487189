import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Col, FormFeedback } from "reactstrap";

const animatedComponents = makeAnimated();

const ReactSelect = (props) => {
  return (
    <>
      <div
        className={`templating-select select2-container pb-4 ${props.formGroupClassName}`}
      >
        {props.label && (
          <label className={`control-label ${props.labelClassName}`}>
            {props.label}
            {props.spnText && (
              <span className="spn-text">&nbsp; ( {props.spnText} )</span>
            )}
          </label>
        )}
        <Col>
          <Select
            options={props.options}
            defaultValue={props.value}
            value={props.value}
            styles={props.styles}
            isMulti={props.isMulti}
            isDisabled={props.isDisabled}
            isClearable={props.isClearable}
            placeholder={props.placeholder}
            classNamePrefix={`select2-selection ${
              props.error !== "" ? "select_error" : ""
            }`}
            closeMenuOnSelect={props.closeMenuOnSelect}
            onChange={(val) => props.onChange(props.name, val)}
            isOptionDisabled={(option) =>
              props?.isMulti ? props?.value?.length >= props?.maxLimit : false
            }
            components={animatedComponents}
          />
          <div className="invalid_text">{props.error ? props.error : ""}</div>
        </Col>
        {props.spanText !== "" && props.spanText !== undefined ? (
          <label>{props.spanText}</label>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

ReactSelect.defaultProps = {
  onChange: () => {},
  options: [],
  value: "",
  classNamePrefix: "form-group",
  isMulti: false,
};
export default ReactSelect;
