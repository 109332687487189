import { Modal } from "reactstrap";

export const ModalContainer = ({ isOpen, toggle, children, title }, props) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0 text-center">{title}</h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{children}</div>
    </Modal>
  );
};
