import { call, put, take, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CUSTOMERS } from "./actionTypes"

import {
  getCustomersSuccess,
  getCustomers,
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"

function* fetchCustomers(data) {
  try {
    const response = yield call(API_ENDPOINTS.getCustomers, {queryStringParameters: data.payload})
    yield put(getCustomersSuccess(response))
  } catch (error) {

  }
}

function* tagsSaga() {
  yield takeEvery(GET_CUSTOMERS, fetchCustomers)
}

export default tagsSaga;
