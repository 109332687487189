import { call, put, takeEvery } from "redux-saga/effects"
import _ from 'lodash';

// Crypto Redux States
import { GET_ORDERS } from "./actionTypes"

import {
  getOrdersSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"
import { history } from "helpers/utils"
import { Toast } from "helpers/Toast"


function* fetchOrders(data) {
  try {
    const response = yield call(API_ENDPOINTS.getOrders, {queryStringParameters: data.payload})
    yield put(getOrdersSuccess(response))
  } catch (error) {
    Toast('error', error)
  }
}

function* wordsSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders)
}

export default wordsSaga;
