import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SET_FORCE_CHANGE_PASSWORD, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess, forceNewPassword } from "./actions";

import { AwsAccountLogin, AwsAccountForceChangePassword } from '../../../helpers/AWSHelper'
import { Toast } from "helpers/Toast";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield AwsAccountLogin(user.email, user.password)
    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      yield put(forceNewPassword(response));
      localStorage.setItem('username', response?.username);
      history.push('/auth/setnewpassword');
    } else {
      // console.log('response--', response);
      localStorage.setItem('accessToken', response?.signInUserSession?.idToken?.jwtToken)
      localStorage.setItem('username', response?.username);
      yield put(loginSuccess(response));
      history.push("/staff/words");
    }
  } catch (error) {
    Toast('error', error?.message)
  }
}

function* forceChangePasswordResponse({
  user,
  newPassword,
  history
}) {
  try {
    yield AwsAccountForceChangePassword(user, newPassword);
    history.push('/auth/login');
  } catch (error) {
    Toast('error', error?.message);
  }
}






function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout);
    yield put(logoutUserSuccess("response"));
    // }
    history.push("/auth/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(SET_FORCE_CHANGE_PASSWORD, forceChangePasswordResponse);
}

export default authSaga;