import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/profile_thumbnail.png";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
import { API_ENDPOINTS } from "api/endpoints";

const UserProfile = () => {
  const dispatch = useDispatch();

  const [userProfile, setUserProfile ] = useState({
    First_name: null
  })
  let username = localStorage.getItem('username')

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  useEffect(() => {
    getProfile()  
  }, [username])

  const getProfile = async () => {
    let payload = {
      queryStringParameters: {
        AWS_UserName: username
      }
    }
    let res = await API_ENDPOINTS.userProfile(payload)
    if(res){
      setUserProfile({...userProfile, ...res.user_Details[0]})
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      First_name: userProfile.First_name || '',
      // idx : idx || '',
    },
    validationSchema: Yup.object({
      First_name: Yup.string().required("Please Enter Your First Name"),
    }),
    onSubmit: (values) => {
      dispatch(editProfile(values));
    }
  });

  console.log('userProfile',userProfile)
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userProfile.First_name}</h5>
                        <p className="mb-1">{userProfile.Email}</p>
                        {/* <p className="mb-0">Id no: #{userProfile._id}</p> */}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Name</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="form-group">
                  <Label className="form-label">User Name</Label>
                  <Input
                    name="First_name"
                    className="form-control"
                    placeholder="Enter User Name"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.First_name || ""}
                    invalid={
                      validation.touched.First_name && validation.errors.First_name ? true : false
                    }
                  />
                  {validation.touched.First_name && validation.errors.First_name ? (
                    <FormFeedback type="invalid">{validation.errors.First_name}</FormFeedback>
                  ) : null}
                  {/* <Input name="idx" value={idx} type="hidden" /> */}
                </div>
                {/* <div className="mt-4">
                  <Button type="submit" color="danger">
                    Update User Name
                  </Button>
                </div> */}
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export { UserProfile };
