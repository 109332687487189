import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap"; // Import Table from Reactstrap
import axios from "axios";
import moment from "moment";
import GetUserDetail from "./GetuserDetail";

const UserList = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10); // Default limit value
  const [page, setPage] = useState(1); // Default page number
  const [maxRow, setMaxRow] = useState(0); // max number of rows
  console.log(apiData[0], "apiData");
  useEffect(() => {
    const Token = localStorage.getItem("accessToken");

    axios
      .get(
        `${process.env.REACT_APP_DEV_URL}/admin_panel/v3.8/user/getAllUsers?limit=${limit}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response) => {
        setApiData(response?.data?.data?.Schools);
        setMaxRow(Math.ceil(response?.data?.data?.length / limit));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [limit, page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Gender</th>
              <th>Role Name</th>
              <th>Active Subscription Plan</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {apiData.map((user, index) => (
              <tr key={user?._id}>
                <td>{`${user?.First_name} ${user?.Last_name}`}</td>
                <td>{user?.Email}</td>
                <td>{user?.Gender}</td>
                <td>{user?.RoleName}</td>
                <td>
                  <span className="text-capitalize">
                    {" "}
                    {(user?.subscription[0]?.SubscriptionPlan &&
                      user?.subscription[0]?.SubscriptionPlan) ||
                      user?.subscription?.SubscriptionPlan ||
                      "Freemium"}
                  </span>{" "}
                  {/* {user?.subscription[0]?.isTubClassPremium ||
                  user?.subscription?.isTubClassPremium === true
                    ? "(TUB Class user)"
                    : null} */}
                  {/* {user?.teams[0]?.subscriptions[0]?.SubscriptionPlan ||
                    "Freemium"} */}
                </td>
                <td>{moment(user?.createdAt).format("MMMM Do YYYY")}</td>
                <td>
                  <GetUserDetail user={user} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          <PaginationItem disabled={page === 1}>
            <PaginationLink
              onClick={() => handlePageChange(page - 1)}
              previous
            />
          </PaginationItem>
          {[...Array(maxRow).keys()].map(
            (pageNumber) =>
              (pageNumber === page ||
                pageNumber === page - 1 ||
                pageNumber === page + 1) && (
                <PaginationItem key={pageNumber} active={pageNumber === page}>
                  <PaginationLink
                    onClick={() => handlePageChange(pageNumber + 1)}
                  >
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              )
          )}
          <PaginationItem disabled={page === maxRow}>
            <PaginationLink onClick={() => handlePageChange(page + 1)} next />
          </PaginationItem>
        </Pagination>
      </Container>
    </div>
  );
};

export { UserList };
