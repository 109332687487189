import {
  SETUP_NEW_PASSWORD,
  SETUP_NEW_PASSWORD_SUCCESS,
  SETUP_NEW_PASSWORD_ERROR,
} from "./actionTypes"

export const userSetPasswordSuccess = message => {
  return {
    type: SETUP_NEW_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userSetPasswordError = message => {
  return {
    type: SETUP_NEW_PASSWORD_ERROR,
    payload: message,
  }
}

export const setNewPassword = (user, history) => {
  return {
    type: SETUP_NEW_PASSWORD,
    payload: { user, history },
  }
}