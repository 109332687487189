/* WORDICON_PACKS */
export const GET_WORDICON_PACKS = "GET_WORDICON_PACKS"
export const GET_WORDICON_PACKS_SUCCESS = "GET_WORDICON_PACKS_SUCCESS"
export const GET_WORDICON_PACKS_FAIL = "GET_WORDICON_PACKS_FAIL"

/* WORDICON_PACKS DETAIL */
export const GET_WORDICON_PACK_DETAIL = "GET_WORDICON_PACK_DETAIL"
export const GET_WORDICON_PACK_DETAIL_SUCCESS = "GET_WORDICON_PACK_DETAIL_SUCCESS"
export const GET_WORDICON_PACK_DETAIL_FAIL = "GET_WORDICON_PACK_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_WORDICON_PACK = "ADD_NEW_WORDICON_PACK"
export const ADD_WORDICON_PACK_SUCCESS = "ADD_WORDICON_PACK_SUCCESS"
export const ADD_WORDICON_PACK_FAIL = "ADD_WORDICON_PACK_FAIL"

/**
 * udpates user
 */
export const UPDATE_WORDICON_PACK = "UPDATE_WORDICON_PACK"
export const UPDATE_WORDICON_PACK_SUCCESS = "UPDATE_WORDICON_PACK_SUCCESS"
export const UPDATE_WORDICON_PACK_FAIL = "UPDATE_WORDICON_PACK_FAIL"


/* WORDICON_PACKS Detial List */
export const GET_WORDICON_PACK_DETAIL_LIST = "GET_WORDICON_PACK_DETAIL_LIST"
export const GET_WORDICON_PACK_DETAIL_LIST_SUCCESS = "GET_WORDICON_PACK_DETAIL_LIST_SUCCESS"
export const GET_WORDICON_PACK_DETAIL_LIST_FAIL = "GET_WORDICON_PACK_DETAIL_LIST_FAIL"

// ADD_CONTENTS_TO_PACK
export const ADD_CONTENTS_TO_PACK = "ADD_CONTENTS_TO_PACK"
export const ADD_CONTENTS_TO_PACK_SUCCESS = "ADD_CONTENTS_TO_PACK_SUCCESS"
export const ADD_CONTENTS_TO_PACK_FAIL = "ADD_CONTENTS_TO_PACK_FAIL"