import React, { useEffect } from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
import { TubcardListColumn } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import { getTubCards } from "store/actions";
import { MdEdit } from "react-icons/md";

const TubcardList = (props) => {
  const dispatch = useDispatch();

  const { tubCardList } = useSelector((state) => ({
    tubCardList: state.tubCard.tubCardList,
  }));

  useEffect(() => {
    let queryPayload = {
      ObjectType: "Card",
    };
    dispatch(getTubCards(queryPayload));
  }, [dispatch, props]);

  const getApplicationDetails = (row) => {
    props.history.push(`/staff/card/detail/${row[6]}`);
  };
  const filterList = (queryPayload) => {
    let payload = {
      ...queryPayload,
      ObjectType: "Card",
    };
    dispatch(getTubCards(payload));
  };

  const getTableColumns = () => {
    let editObj = {
      label: "Actions",
      name: "",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="d-flex align-items-center justify-content-center">
            <div
              className="ms-1"
              onClick={() => getApplicationDetails(tableMeta?.rowData, true)}
            >
              <MdEdit size={16} />
            </div>
          </div>
        ),
      },
    };
    let newtable = [...TubcardListColumn, editObj];
    return newtable;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="TUB Cards" breadcrumbItem="TUB Card List" />
          <CustomBootstrapTable
            rows={tubCardList}
            columns={getTableColumns()}
            isLoading={false}
            showCreateBtn={true}
            keyField={"_id"}
            createBtnLabel="Create New TUB Card"
            onCreateRedirect={`/staff/card/detail`}
            getApplicationDetails={(row) => null}
            showStatusFilter={false}
            showDateFilter={false}
            history={props.history}
            filterList={(...params) => filterList(...params)}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export { TubcardList };
