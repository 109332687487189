import {
  GET_WORDS_SUCCESS,
  GET_WORDS_FAIL,
  ADD_WORD_FAIL,
  GET_WORD_DETAIL_SUCCESS,
  GET_WORD_DETAIL_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  wordsList: [],
  wordDetail: {},
  error: {},
}

const words = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WORDS_SUCCESS:
      return {
        ...state,
        wordsList: action.payload,
      }

    case GET_WORDS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_WORD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_WORD_DETAIL_SUCCESS:
      return {
        ...state,
        wordDetail: action.payload,
      }

    case GET_WORD_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default words
