import { API } from "aws-amplify";
import { Toast } from "helpers/Toast";
import { history } from "helpers/utils";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export async function get(url, config = {}) {
  return API.get(ENVIRONMENT, url, config)
    .then((response) => response.data)
    .catch((error) => {
      Toast("error", error?.response?.data?.message);
      if (error?.response?.status === 500) {
        history.push("/auth/500");
      }
    });
}

export async function post(url, config) {
  return API.post(ENVIRONMENT, url, config)
    .then((response) => {
      Toast("success", response?.message);
      return response.data;
    })
    .catch((error) => {
      Toast("error", error?.response?.data?.message);
      if (error?.response?.status == 500) {
        // history.push('/auth/500')
      }
    });
}

export async function put(url, config = {}) {
  return API.put(ENVIRONMENT, url, config)
    .then((response) => response.data)
    .catch((error) => {
      Toast("error", error?.response?.data?.message);
      if (error?.response?.status == 500) {
        history.push("/auth/500");
      }
    });
}
export async function Patch(url, config = {}) {
  return API.patch(ENVIRONMENT, url, config)
    .then((response) => response)
    .catch((error) => {
      Toast("error", error?.response?.data?.message);
      if (error?.response?.status == 500) {
        history.push("/auth/500");
      }
    });
}

export async function del(url, config = {}) {
  return await API.delete(ENVIRONMENT, url, config)
    .then((response) => response.data)
    .catch((error) => {
      Toast("error", error?.response?.data?.message);
      if (error?.response?.status == 500) {
        history.push("/auth/500");
      }
    });
}
