import {
  GET_WORDICON_PACK_DETAIL,
  GET_WORDICON_PACK_DETAIL_SUCCESS,
  GET_WORDICON_PACKS,
  GET_WORDICON_PACKS_SUCCESS,
  ADD_NEW_WORDICON_PACK,
  ADD_WORDICON_PACK_SUCCESS,
  UPDATE_WORDICON_PACK,
  UPDATE_WORDICON_PACK_SUCCESS,
  GET_WORDICON_PACK_DETAIL_LIST,
  GET_WORDICON_PACK_DETAIL_LIST_SUCCESS,
  ADD_CONTENTS_TO_PACK,
  ADD_CONTENTS_TO_PACK_SUCCESS,
} from "./actionTypes"

export const getWordiconPacks = (queryPayload) => ({
  type: GET_WORDICON_PACKS,
  payload: queryPayload
})
export const getWordiconPacksSuccess = WordiconPacks => ({
  type: GET_WORDICON_PACKS_SUCCESS,
  payload: WordiconPacks,
})


export const addNewWordiconPack = data => ({
  type: ADD_NEW_WORDICON_PACK,
  payload: data,
})
export const addWordiconPackSuccess = () => ({
  type: ADD_WORDICON_PACK_SUCCESS,
  payload: null,
})


export const getWordiconPackDetail = (params) => ({
  type: GET_WORDICON_PACK_DETAIL,
  payload: params
})
export const getWordiconPackDetailSuccess = data => ({
  type: GET_WORDICON_PACK_DETAIL_SUCCESS,
  payload: data,
})


export const updateWordiconPack = data => ({
  type: UPDATE_WORDICON_PACK,
  payload: data,
})
export const updateWordiconPackSuccess = () => ({
  type: UPDATE_WORDICON_PACK_SUCCESS,
  payload: null,
})

// get pack detail list 
export const getWordiconPackDetailList = (params) => ({
  type: GET_WORDICON_PACK_DETAIL_LIST,
  payload: params
})
export const getWordiconPackDetailListSuccess = data => ({
  type: GET_WORDICON_PACK_DETAIL_LIST_SUCCESS,
  payload: data,
})

// add contents to pack
export const addContentsToPack = data => ({
  type: ADD_CONTENTS_TO_PACK,
  payload: data,
})
export const addContentsToPackSuccess = () => ({
  type: ADD_CONTENTS_TO_PACK_SUCCESS,
  payload: null,
})