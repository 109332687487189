import React, { useState } from "react";
import {
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { API_ENDPOINTS } from "api/endpoints";
import FormikFormInput from "components/FormInput/formikForInput";
import { Toast } from "helpers/Toast";

const MapContentsToUser = (props) => {
    const [userDetail, setDetail] = useState({
        emailId: null,
    })

    const onChange = (name, value) => {
        setDetail({...userDetail, [name]: value})
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            emailId: (userDetail && userDetail.emailId) || "",
        },
        validationSchema: Yup.object({
            emailId: Yup.string().required("Please Enter Email Id"),
        }),
        onSubmit: async (values) => {
        
            let res = await API_ENDPOINTS.mapContentsToUser(values.emailId)
            if(res == 0){
                Toast('success', 'successfully sent the contents to user')
            } else {
                Toast('error', 'Content Mapped previously to this user')
            }
            validation.resetForm();
        }
    });

    return (
        <>
            <div className="page-content page_bg">
                <Form
                    className="px-3"
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <FormikFormInput 
                        placeholder="Enter User Email Id"
                        name="emailId" 
                        type = 'text'
                        formGroupClassName = "row"
                        colClassName = "col-xs-auto"
                        label = 'Enter User Email Id'
                        labelClassName = "col-form-label"
                        value={userDetail['emailId']}
                        onChange = {(...params) => onChange(...params)}
                        validation = {validation}
                    />
                    <div >
                        <button
                            type="submit"
                            className="px-4 primary_btn"
                        >
                            Save
                        </button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export { MapContentsToUser }