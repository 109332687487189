import {
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORY_FAIL,
  ADD_CATEGORY_SUCCESS,
  ADD_CATEGORY_FAIL,
  UPDATE_CATEGORY_FAIL,
  GET_PACKS_IN_CATEGORY_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  categoriesList: [],
  categoryDetail: {},
  success: '',
  error: {},
  packsInCategory: []
}

const categories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categoriesList: action.payload,
      }

    case GET_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        success: 'success'
      }

    case ADD_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      }

      case UPDATE_CATEGORY_FAIL:
        return {
          ...state,
          error: action.payload,
        }

      case GET_PACKS_IN_CATEGORY_SUCCESS:
        return {
          ...state,
          packsInCategory: action.payload,
        }
    default:
      return state
  }
}

export default categories
