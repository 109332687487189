// import FormLoader from 'components/loader/formLoader';
import React, { useState } from "react";
// import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import BootstrapTable from "react-bootstrap-table-next";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import ToolkitProvider, {
  ColumnToggle,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import ReactSelect from "components/ReactSelect";
import moment from "moment";
import Pagination from "./pagination";
import FormInput from "components/FormInput";
import useValidationResolver from "hooks/useValidationResolver";
import { FormProvider, useForm } from "react-hook-form";
import ButtonType1 from "components/Button/ButtonType1";
import _ from "lodash";
import CreateApplication from "components/Button/createApplicationBtn";
import { NormalButton } from "components/BootstrapButton";
// import ButtonType1 from 'components/buttons/ButtonType1';
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { getMuiTheme } from "helpers/tableDataMapper";

export const CustomBootstrapTable = (
  {
    keyField,
    rows,
    columns,
    onSelectionOfDate,
    sort,
    isLoading,
    onCreateRedirect,
    history,
    triggerPopup,
    createBtnLabel,
    filterList,
    getApplicationDetails,
    showCreateBtn,
    showCustomComponent,
    customComponent,
    isSearch
  },
  props
) => {
  const [value, setDate] = useState([null, null]);
  const [customFields, setCustomFields] = useState(false);
  const [queryPayload, setQueryPayload] = useState({
    search: "",
  });
  const [tableColumns, setTableColumns] = useState(columns);

  const onChange = (name, value) => {
    setQueryPayload({ ...queryPayload, [name]: value });
    if (name == "search" && value == "") {
      filterList({ ...queryPayload, search: "" });
    }
  };
  const onChangeDate = (value) => {
    setDate(value);
    let dateQuery = {
      startDate: moment(value[0]).format("YYYY-MM-DD"),
      endDate: moment(value[1]).format("YYYY-MM-DD"),
    };
    onSelectionOfDate(dateQuery);
  };

  const onRowClick = (rowData, rowMeta) => {
    getApplicationDetails(rowData);
  };

  const isShowConfirmBtn = () => {
    let show = true;

    if (_.isEmpty(queryPayload.search)) {
      show = false;
    }

    return show;
  };

  const createNew = () => {
    if (onCreateRedirect != null) {
      history.push(onCreateRedirect);
    } else {
      triggerPopup();
    }
  };
  const myColumnToggle = (df) => {
    var newTableColumns = tableColumns.map((val) => {
      if (val.dataField === df) {
        val.hidden = !val.hidden;
      }
      return val;
    });
    setTableColumns(newTableColumns);
  };

  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <div className="table_wrapper">
          <div className="row table_selectors  align-items-center">
            <div className="col-lg-5">
              <div className="row">
                {!isSearch && (  // Conditionally render based on isSearch
                  <div className="col-lg-6 col-12">
                    <FormInput
                      placeholder="Search"
                      name="search"
                      type="search"
                      helperBeforeIcon="search"
                      inputClassName="search"
                      value={queryPayload["search"]}
                      onChange={(...params) => onChange(...params)}
                    />
                  </div>
                )}

                {isShowConfirmBtn() && (
                  <div className="col-xl-6 col-lg-6 col-md-6 col-6">
                    <ButtonType1
                      onClick={() => filterList(queryPayload)}
                      btnName="Confirm"
                      isLoading={false}
                    />
                  </div>
                )}
              </div>
            </div>

            {showCustomComponent ? customComponent : <></>}
            {showCreateBtn && (
              <div className="col-lg-7 col-12 py-2">
                <div className="d-flex justify-content-end">
                  <CreateApplication
                    onCreateApplicationBtnClick={createNew}
                    btnName={createBtnLabel}
                    btnClassname="px-4 text-white primary_bg"
                    disableCreateApp={false}
                  />
                </div>
              </div>
            )}
          </div>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={""}
              data={rows}
              columns={columns}
              options={{
                filter: false,
                search: false,
                print: false,
                download: false,
                viewColumns: true,
                jumpToPage: false,
                pagination: true,
                rowHover: true,
                onRowClick: (rowData, rowMeta) => onRowClick(rowData, rowMeta),
                responsive: "scroll",
                // rowsPerPage: 500,
                // rowsPerPageOptions: [100, 500, 1000],
              }}
            />
          </ThemeProvider>

          {/* <BootstrapTable 
                        bootstrap4 
                        keyField={keyField} 
                        data={rows}  
                        columns={columns} 
                        defaultSorted={sort} 
                        hover
                        bordered={ false }
                        // selectRow={selectRow}
                        rowEvents={ rowEvents()}
                        wrapperClasses="table-responsive custom-striped-table"
                        // { ...props.baseProps }
                /> */}
          {/* <div className="pt-3">
                    <Pagination 
                        pageCount={1} 
                        page={1}
                        onPageChange={() => nextPage()}
                    />
                </div> */}
        </div>
      )}
    </>
  );
};
