import {
  GET_TAGS,
  GET_TAG_FAIL,
  GET_TAGS_SUCCESS,
  ADD_NEW_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAIL,
  UPDATE_TAG,
  UPDATE_TAG_SUCCESS,
  UPDATE_TAG_FAIL,
} from "./actionTypes"

export const getTags = (queryPayload) => ({
  type: GET_TAGS,
  payload: queryPayload
})

export const getTagsSuccess = tags => ({
  type: GET_TAGS_SUCCESS,
  payload: tags,
})

export const createOrEditTag = data => ({
  type: ADD_NEW_TAG,
  payload: data,
})

export const addTagSuccess = user => ({
  type: ADD_TAG_SUCCESS,
  payload: user,
})

export const addTagFail = error => ({
  type: ADD_TAG_FAIL,
  payload: error,
})

export const getTagFail = error => ({
  type: GET_TAG_FAIL,
  payload: error,
})

export const updateTag = user => ({
  type: UPDATE_TAG,
  payload: user,
})

export const updateTagSuccess = user => ({
  type: UPDATE_TAG_SUCCESS,
  payload: user,
})

export const updateTagFail = error => ({
  type: UPDATE_TAG_FAIL,
  payload: error,
})
