import {
  GET_SUBSCRIPTION_RULE_DETAIL,
  GET_SUBSCRIPTION_RULE_DETAIL_SUCCESS,
  GET_SUBSCRIPTION_RULES,
  GET_SUBSCRIPTION_RULES_SUCCESS,
  ADD_NEW_SUBSCRIPTION_RULE,
  ADD_SUBSCRIPTION_RULE_SUCCESS,
  UPDATE_SUBSCRIPTION_RULE,
  UPDATE_SUBSCRIPTION_RULE_SUCCESS,
} from "./actionTypes"

export const getSubscriptionRules = (queryPayload) => ({
  type: GET_SUBSCRIPTION_RULES,
  payload: queryPayload
})
export const getSubscriptionRulesSuccess = SubscriptionRules => ({
  type: GET_SUBSCRIPTION_RULES_SUCCESS,
  payload: SubscriptionRules,
})


export const addNewSubscriptionRule = data => ({
  type: ADD_NEW_SUBSCRIPTION_RULE,
  payload: data,
})
export const addSubscriptionRuleSuccess = () => ({
  type: ADD_SUBSCRIPTION_RULE_SUCCESS,
  payload: null,
})


export const getSubscriptionRuleDetail = (params) => ({
  type: GET_SUBSCRIPTION_RULE_DETAIL,
  payload: params
})
export const getSubscriptionRuleDetailSuccess = data => ({
  type: GET_SUBSCRIPTION_RULE_DETAIL_SUCCESS,
  payload: data,
})


export const updateSubscriptionRule = data => ({
  type: UPDATE_SUBSCRIPTION_RULE,
  payload: data,
})
export const updateSubscriptionRuleSuccess = () => ({
  type: UPDATE_SUBSCRIPTION_RULE_SUCCESS,
  payload: null,
})