import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import ViewChildren from "./View";
import { Container, Table } from "reactstrap";
import { FaCaretRight, FaSortDown } from "react-icons/fa";
import "./Hierarchy.css"; // Import CSS file for styling

const Hierarchy = () => {
  const [director, setDirector] = useState([]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem("accessToken") || "{}";
      const headers = {
        Authorization: token,
      };
      const response = await axios.get(
        `${process.env.REACT_APP_DEV_URL}/admin_panel/v3.8/user/directors?hierarchy=true`,
        { headers }
      );
      setDirector(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const TreeNode = ({ director, level = 0 }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
      <>
        <tr className={`fw-bold ${isOpen ? "open" : ""}`} onClick={toggle}>
          <td style={{ paddingLeft: `${level * 10}px` }}>
            {director?.userLevels?.children[0] &&
              (isOpen ? <FaSortDown /> : <FaCaretRight />)}
            {director?.First_name} {director?.Last_name}
          </td>
          <td>{director?.RoleName}</td>
          <td>{moment(director?.createdAt).format("LL")}</td>
          <td>{director?.Email}</td>
          <td>
            <ViewChildren directorId={director?._id} />
          </td>
        </tr>
        {isOpen &&
          director?.userLevels?.children.map((team, index) => (
            <React.Fragment key={index}>
              <RecursiveTable team={team} index={index} level={level + 1} />
            </React.Fragment>
          ))}
      </>
    );
  };

  const RecursiveTable = ({ team, index, level }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
      setIsOpen(!isOpen);
    };

    return (
      <>
        <tr className={`fw-light ${isOpen ? "open" : ""}`} onClick={toggle}>
          <td style={{ paddingLeft: `${level * 10}px` }}>
            <span className="mx-1">{index + 1}.</span>
            {team?.children[0] && (
              <span className="ms-2">
                {isOpen ? <FaSortDown /> : <FaCaretRight />}
              </span>
            )}
            {team?.userDetails?.First_name} {team?.userDetails?.Last_name}
          </td>
          <td>{team?.userDetails?.RoleName}</td>
          <td>{moment(team?.userDetails?.createdAt).format("LL")}</td>
          <td>{team?.userDetails?.Email}</td>
          <td>
            <ViewChildren directorId={team?.userDetails?._id} />
          </td>
        </tr>
        {isOpen &&
          team?.children.map((subTeam, subIndex) => (
            <RecursiveTable
              key={subIndex}
              team={subTeam}
              index={subIndex}
              level={level + 1}
            />
          ))}
      </>
    );
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Table responsive hover striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Date</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {director.map((directorItem, index) => (
              <TreeNode key={index} director={directorItem} />
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export { Hierarchy };
