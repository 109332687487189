import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { WordsColumn,getMuiTheme } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import {
    getWords
} from "store/words/actions";

const WordsList = (props) => {

    const dispatch = useDispatch();

    const { wordsList } = useSelector(state => ({
        wordsList: state.words.wordsList,
    }));
    
    useEffect(() => {
        let queryPayload = {
            search: ''
        }
        dispatch(getWords(queryPayload));
    }, [getWords]);

    const getApplicationDetails = (row) => {
        props.history.push(`/staff/word/detail/${row[1]}`)
    }

    const filterList = (queryPayload) => {
        dispatch(getWords(queryPayload))
    }

    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Words Master" breadcrumbItem="Words List" />
                    <CustomBootstrapTable 
                        rows = {wordsList}
                        columns = {WordsColumn}
                        isLoading={false}
                        showCreateBtn = {true}
                        keyField={'_id'}
                        createBtnLabel="Add New Word"
                        onCreateRedirect = {'/staff/word/detail'}
                        filterList = {(...params) => filterList(...params)}
                        getApplicationDetails={(row) => getApplicationDetails(row)}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export { WordsList }