import { call, put, take, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_CATEGORIES, ADD_CATEGORY_SUCCESS , ADD_NEW_CATEGORY , UPDATE_CATEGORY, GET_PACKS_IN_CATEGORY } from "./actionTypes"

import {
  getCategoriesSuccess,
  getCategoryFail,
  addCategoryFail,
  addCategorySuccess,
  updateCategorySuccess,
  updateCategoryFail,
  getCategories,
  getPacksInCategorySuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"

function* fetchCategories(data) {
  try {
    const response = yield call(API_ENDPOINTS.getCategories, {queryStringParameters: data.payload})
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    yield put(getCategoryFail(error))
  }
}

function* onUpdateCategory(data) {
  try {
    const response = yield call(API_ENDPOINTS.createOrEditCategory, {body: data.payload})
  } catch (error) {
    yield put(updateCategoryFail(error))
  }
}

function* createOrEditCategory(data) {
  try {
    const response = yield call(API_ENDPOINTS.createOrEditCategory, {body: data.payload})

    yield put(getCategories())
    
  } catch (error) {
    yield put(addCategoryFail(error))
  }
}

function* fetchPacksInCategory(data) {
  try {
    const response = yield call(API_ENDPOINTS.getPacksInCategory, {queryStringParameters: data.payload})
    yield put(getPacksInCategorySuccess(response))
  } catch (error) {
    yield put(getCategoryFail(error))
  }
}

function* tagsSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories)
  yield takeEvery(ADD_CATEGORY_SUCCESS, fetchCategories)
  yield takeEvery(ADD_NEW_CATEGORY, createOrEditCategory)
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory)
  yield takeEvery(GET_PACKS_IN_CATEGORY, fetchPacksInCategory)
}

export default tagsSaga;
