/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL"
/**
 * add user
 */
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY"
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS"
export const ADD_CATEGORY_FAIL = "ADD_CATEGORY_FAIL"

/**
 * Edit user
 */
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"


/* PACKS IN CATEGORIES */
export const GET_PACKS_IN_CATEGORY = "GET_PACKS_IN_CATEGORY"
export const GET_PACKS_IN_CATEGORY_SUCCESS = "GET_PACKS_IN_CATEGORY_SUCCESS"
export const GET_PACKS_IN_CATEGORY_FAIL = "GET_PACKS_IN_CATEGORY_FAIL"