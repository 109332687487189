import React, { useEffect } from "react";
import {
  Container,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import {  WordiconPacksColumn } from "helpers/tableDataMapper";
import { useSelector, useDispatch } from "react-redux";
import { getWordiconPacks } from "store/actions";
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";
import { history } from "helpers/utils";

const PacksList = (props) => {
    const dispatch = useDispatch();

    const { packsList } = useSelector(state => ({
        packsList: state.wordiconPack.packsList,
    }));
    
    useEffect(() => {
        let queryPayload = {
            search: '',
        }
        dispatch(getWordiconPacks(queryPayload));
    }, [dispatch, props]);

    const getApplicationDetails = (row) => {
        props.history.push(`/staff/pack/detail/${row[9]}`)
    }
    const filterList = (queryPayload) => {
        let payload = {
            ...queryPayload,
            // curriculum_Id: props.match.params.curriculumId
        }
        dispatch(getWordiconPacks(payload))
    }

    const redirectTo = (row) => {
        history.push(`/staff/pack/detail/list/${row[9]}`)
    }

    const getTableColumns = () => {
        let editObj = {
            label: "Actions",
            name: '',
            options: { 
                filter: true,
                sort: false, 
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className='d-flex align-items-center justify-content-center'>
                        <div
                            onClick={() => redirectTo(tableMeta?.rowData)}
                        >
                            <MdOutlineRemoveRedEye size={18}/>
                        </div>
                        <div
                            className="ms-1"
                            onClick={() => getApplicationDetails(tableMeta?.rowData, true)}
                        >
                            <MdEdit size={16}/>
                        </div>  
                    </div>
                )
            }
        }
        let newtable = [...WordiconPacksColumn,editObj]
         return newtable
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Wordicon Packs" breadcrumbItem="Packs List" />
                    <CustomBootstrapTable 
                        rows = {packsList}
                        columns = {getTableColumns()}
                        isLoading={false}
                        showCreateBtn = {true}
                        keyField={'_id'}
                        createBtnLabel="Add New Pack"
                        onCreateRedirect = {`/staff/pack/detail`}
                        getApplicationDetails={(row) => null}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                        filterList = {(...params) => filterList(...params)}
                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

export { PacksList }