import React, { useEffect, useState } from "react";
import {  withRouter } from "react-router-dom";
import {
  Container,
  Form,
  Row,
  Col,
  CardTitle
} from "reactstrap";
import { CustomBootstrapTable } from 'components/Table/CustomBootstrapTable';
import { CurriculumColumn } from "helpers/tableDataMapper";
import { ModalContainer } from "components/Modal";
import FormInput from "components/FormInput";
import { LogoUpload } from "components/Uploadfile/LogoUpload";
import { useSelector, useDispatch } from "react-redux";
import {
    getCurriculums,
    createOrEditCurriculum
} from "store/curriculum/actions";
import { API_ENDPOINTS } from "api/endpoints";
import { MdEdit, MdOutlineRemoveRedEye } from "react-icons/md";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import _ from 'lodash'
import { history } from "helpers/utils";

const defaultCurriculumData = {
    curriculum_Name: '',
    logo: {
        original: ''
    },
    status: false
}

const Curriculum = (props) => {

    const dispatch = useDispatch();
    
    const [isOpen, setModal] = useState(false)
    const [curriculumDetail, setDetail] = useState({
        curriculum_Name: null,
        logo: {
            original: ''
        },
        status: true
    })
    const [isEdit, setIsEdit] = useState(false);

    const { curriculumList } = useSelector(state => ({
        curriculumList: state.curriculums.curriculumList,
    }));

    useEffect(() => {
        let queryPayload = {
            search: ''
        }
          dispatch(getCurriculums(queryPayload));
    }, [dispatch]);


    const onChange = (name, value) => {
        if(name == 'logo'){
            uploadImage(value)
        }
        setDetail({...curriculumDetail, [name]: value})
    }

    const uploadImage = async (value) => {
        let formData = new FormData()
        formData.append('image', value)
        try  { 
            const myInit = {
                body: formData,
            };
            const response = await API_ENDPOINTS.uploadImage(myInit)
            setDetail({
                ...curriculumDetail,
                logo: {
                    original: response?.originalImage?.original,
                }
            })
         } catch(error) {
            console.log('error', error)
        }
    }

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            curriculum_Name: (curriculumDetail && curriculumDetail.curriculum_Name) || "",
        },
        validationSchema: Yup.object({
            curriculum_Name: Yup.string().required("Please Enter Curriculum Name"),
            // logo: Yup.mixed().required('File is required'),
        }),
        onSubmit: (values) => {
            let body = {
                AWS_UserName: localStorage.getItem('username'),
            }
            if(isEdit){
                body = {
                    ...curriculumDetail,
                    ...body,
                    status: curriculumDetail.status ? 'Active' : 'InActive',
                    curriculum_Name: values.curriculum_Name.charAt(0).toUpperCase() + values.curriculum_Name.slice(1),
                    logo: curriculumDetail.logo
                }
            } else {
                body = {
                    ...body,
                    ...values,
                    curriculum_Name: values.curriculum_Name.charAt(0).toUpperCase() + values.curriculum_Name.slice(1),
                    status: curriculumDetail.status ? 'Active' : 'InActive',
                    logo: curriculumDetail.logo
                }
            }
        
            dispatch(createOrEditCurriculum(body));
            validation.resetForm();

            setModal(false)
        }
    });

    const getApplicationDetails = (row, edit) => {
        setIsEdit(edit)
        setDetail({
            _id: row[1],
            logo: {
                original: row[2]?.original
            },
            curriculum_Name: row[3],
            status: row[4] === 'InActive' ? false : true
        })
        setModal(true)
    }

    const deleteCurriculum = () => {
        let body = {
            ...curriculumDetail,
            isDeleted: true
        }
        dispatch(createOrEditCurriculum(body));
        setDetail({...curriculumDetail, ...defaultCurriculumData})
        setModal(false)
    }

    const filterList = (queryPayload) => {
        dispatch(getCurriculums(queryPayload))
    }

    const getTableColumns = () => {
        let editObj = {
            label: "Actions",
            name: '',
            options: { 
                filter: true,
                sort: false, 
                customBodyRender: (value, tableMeta, updateValue) => (
                    <div className='d-flex align-items-center justify-content-center'>
                        <div
                            onClick={() => redirectToCurriculumReadingPr(tableMeta?.rowData, true)}
                        >
                            <MdOutlineRemoveRedEye size={18}/>
                        </div>
                        <div
                            className="ms-1"
                            onClick={() => getApplicationDetails(tableMeta?.rowData, true)}
                        >
                            <MdEdit size={16}/>
                        </div>  
                    </div>
                )
            }
        }
        let newtable = [...CurriculumColumn,editObj]
         return newtable
    }

    const redirectToCurriculumReadingPr = (row) => {
        history.push(`/staff/curriculum/readingprogram/${row[1]}`)
    }

    const toggle = (type) => {
        setDetail({...curriculumDetail, ...defaultCurriculumData})
        setModal(type)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <CardTitle className="page-title-box">CURRICULUMS</CardTitle>
                    <CustomBootstrapTable 
                        rows = {curriculumList}
                        columns = {getTableColumns()}
                        isLoading={false}
                        keyField={'_id'}
                        showCreateBtn = {true}
                        onCreateRedirect = {null}
                        filterList = {(...params) => filterList(...params)}
                        createBtnLabel = "Add New Curriculum"
                        triggerPopup = {() => getApplicationDetails(defaultCurriculumData, false)}
                        getApplicationDetails={(row) => null}
                        showStatusFilter={false}
                        showDateFilter={false}
                        history = {props.history}
                    />
                </Container>
            </div>
            <ModalContainer
                isOpen={isOpen}
                toggle={() => toggle(false)}
                title={!isEdit ? 'Add New Curriculum' : 'Edit Curriculum'}
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <FormikFormInput 
                        placeholder="Enter Curriculum Name"
                        name="curriculum_Name" 
                        type = 'text'
                        label = 'Curriculum Name'
                        labelClassName = "col-form-label"
                        value={curriculumDetail['curriculum_Name']}
                        validation = {validation}
                    />
                    <LogoUpload 
                        title="Upload Logo"
                        name = "logo"
                        groupClassName = ""
                        labelClassName= ""
                        preview = {curriculumDetail['logo']?.original}
                        onChange = {(...params) => onChange(...params)}
                        validation = {validation}
                        removeImage = {() => setDetail({...curriculumDetail, logo: {original: ''}})}
                    />
                    <FormInput 
                        name="status" 
                        formGroupClassName = "row"
                        label = 'Status'
                        type={"two-way-switch"}
                        leftLabel = "Inactive"
                        rightLabel = "Active"
                        labelClassName = "col-md-2 col-form-label"
                        value={curriculumDetail['status']}
                        onChange = {(...params) => onChange(...params)}
                    />
                
                    <Row className="align-items-center">
                        {isEdit &&
                            <Col>
                                <div>
                                    <p className="danger" onClick={() => deleteCurriculum()}>Delete Curriculum</p>
                                </div>
                            </Col>
                        }
                        <Col>
                            <div className='d-flex justify-content-end'>
                                <button
                                    type="submit"
                                    className="px-4 primary_btn"
                                >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Form>
                        
            </ModalContainer>
        </React.Fragment>
    )
}

export { Curriculum }