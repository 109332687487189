/* TUB_CARDS */
export const GET_TUB_CARDS = "GET_TUB_CARDS"
export const GET_TUB_CARDS_SUCCESS = "GET_TUB_CARDS_SUCCESS"
export const GET_TUB_CARDS_FAIL = "GET_TUB_CARDS_FAIL"

/* TUB_CARDS DETAIL */
export const GET_TUB_CARD_DETAIL = "GET_TUB_CARD_DETAIL"
export const GET_TUB_CARD_DETAIL_SUCCESS = "GET_TUB_CARD_DETAIL_SUCCESS"
export const GET_TUB_CARD_DETAIL_FAIL = "GET_TUB_CARD_DETAIL_FAIL"

/**
 * add user
 */
export const ADD_NEW_TUB_CARD = "ADD_NEW_TUB_CARD"
export const ADD_TUB_CARD_SUCCESS = "ADD_TUB_CARD_SUCCESS"
export const ADD_TUB_CARD_FAIL = "ADD_TUB_CARD_FAIL"

/**
 * udpates user
 */
export const UPDATE_TUB_CARD = "UPDATE_TUB_CARD"
export const UPDATE_TUB_CARD_SUCCESS = "UPDATE_TUB_CARD_SUCCESS"
export const UPDATE_TUB_CARD_FAIL = "UPDATE_TUB_CARD_FAIL"