import {
  GET_COMBO_DETAIL,
  GET_COMBO_DETAIL_SUCCESS,
  GET_COMBOS,
  GET_COMBOS_SUCCESS,
  ADD_NEW_COMBO,
  ADD_COMBO_SUCCESS,
  UPDATE_COMBO,
  UPDATE_COMBO_SUCCESS,
} from "./actionTypes"

export const getCombos = (queryPayload) => ({
  type: GET_COMBOS,
  payload: queryPayload
})
export const getCombosSuccess = Combos => ({
  type: GET_COMBOS_SUCCESS,
  payload: Combos,
})


export const addNewCombo = data => ({
  type: ADD_NEW_COMBO,
  payload: data,
})
export const addComboSuccess = () => ({
  type: ADD_COMBO_SUCCESS,
  payload: null,
})


export const getComboDetail = (params) => ({
  type: GET_COMBO_DETAIL,
  payload: params
})
export const getComboDetailSuccess = data => ({
  type: GET_COMBO_DETAIL_SUCCESS,
  payload: data,
})


export const updateCombo = data => ({
  type: UPDATE_COMBO,
  payload: data,
})
export const updateComboSuccess = () => ({
  type: UPDATE_COMBO_SUCCESS,
  payload: null,
})