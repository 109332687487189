import {
  GET_ORDERS_SUCCESS,
  GET_ORDER_DETAIL_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  ordersList: [],
  orderDetail: {},
  error: {},
}

const wordiconPack = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        ordersList: action.payload,
      }
    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: action.payload,
      }
    default:
      return state
  }
}

export default wordiconPack
