import { call, put, takeEvery } from "redux-saga/effects"
import _ from 'lodash';

// Crypto Redux States
import { GET_WORDICON_PACKS , ADD_NEW_WORDICON_PACK , UPDATE_WORDICON_PACK, ADD_CONTENTS_TO_PACK,GET_WORDICON_PACK_DETAIL_LIST } from "./actionTypes"

import {
  getWordiconPacksSuccess,
  getWordiconPackDetailListSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"
import { history } from "helpers/utils"
import { Toast } from "helpers/Toast"


function* fetchWordiconPacks(data) {
  try {
    const response = yield call(API_ENDPOINTS.getTubPacks, {queryStringParameters: data.payload})
    yield put(getWordiconPacksSuccess(response))
  } catch (error) {
    Toast('error', error)
  }
}


function* onAddNewWordiconPack(data) {
  try {
    let res = yield call(API_ENDPOINTS.createOrEditWordiconPack, {body: data.payload})
  } catch (error) {
    Toast('error', error)
  }
}

function* updateWordiconPackDetail(data) {
  try {
    let body = {
      body: data.payload,
      queryStringParameters: {
        imageId: data.payload._id
      }
    }
    yield call(API_ENDPOINTS.createOrEditWordiconPack, body)

  } catch (error) {
    console.log('error', error)
    Toast('error', error)
  }
}

function* addContentsToPack(data) {
  try {
    yield call(API_ENDPOINTS.addContentsToPack, {body: data.payload})
    history.goBack()
  } catch (error) {
    Toast('error', error)
  }
}

function* fetchWordiconPackDetailList(data) {
  try {
    const response = yield call(API_ENDPOINTS.getWordiconPackDetailList,data.payload)
    if(!_.isEmpty(response)){
      let data = [].concat(response.Cards, response.Sheets)
      let result = {
        ...response,
        data: data,
        Cards: response.Cards.map(cd => ({label: cd.ObjectName, value: cd._id, data: {...cd}})),
        Sheets: response.Sheets.map(cd => ({label: cd.ObjectName, value: cd._id, data : {...cd}})),
      }
      yield put(getWordiconPackDetailListSuccess(result))
    }
    
  } catch (error) {
    Toast('error', error)
  }
}

function* wordsSaga() {
  yield takeEvery(GET_WORDICON_PACKS, fetchWordiconPacks)
  yield takeEvery(ADD_NEW_WORDICON_PACK, onAddNewWordiconPack)
  yield takeEvery(UPDATE_WORDICON_PACK, updateWordiconPackDetail)
  yield takeEvery(ADD_CONTENTS_TO_PACK, addContentsToPack)
  yield takeEvery(GET_WORDICON_PACK_DETAIL_LIST, fetchWordiconPackDetailList)
}

export default wordsSaga;
