import { Breadcrumbs } from "@mui/material";
import { API_ENDPOINTS } from "api/endpoints";
import { ModalContainer } from "components/Modal";
import { CustomBootstrapTable } from "components/Table/CustomBootstrapTable";
  // eslint-disable-next-line no-unused-vars
import { SchoolList, UserList } from "helpers/tableDataMapper";
import React, { useEffect, useState } from "react";
import { Container, Row, Form, Col } from "reactstrap";
import { MdEdit } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";
import FormikFormInput from "components/FormInput/formikForInput";
import { Toast } from "helpers/Toast";
import ReactSelect from "components/ReactSelect";
import toast from "react-hot-toast";
const AddUser = () => {

  // eslint-disable-next-line no-unused-vars
  let username = localStorage.getItem("username");
  const [isOpen, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolListData, setSchoolList] = useState([]);
  const [schoolListDatas, setSchoolLists] = useState([]);
  const [UserlListData, setUserlList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [schoolId, setSchoolID] = useState({});
  const [isActionModal, setIsActionModal] = useState(false);
  const genderOptions = [
    {
      label: "Freemium",
      value: "Freemium",
    },
    {
      label: "Premium",
      value: "Premium",
    },
  ];
  const genderOption = [
    {
      label: "Male",
      value: "M",
    },
    {
      label: "Female",
      value: "F",
    },
  ];
  const getUserListing = async () => {
    let queryPayload = {
      //   userName: username,
      limit: 1000,
      page: 1,
    };

    try {
      setLoading(true);
      const responce = await API_ENDPOINTS.getUser(queryPayload);
      // console.log(responce?.Schools, "responce");
      if (responce) {
        setLoading(false);
        setUserlList(responce?.Schools);
      }
    } catch (error) {}
  };
  const getSchoolListing = async () => {
    let queryPayload = {
      //   userName: username,
      limit: 1000,
      page: 1,
    };

    try {
      setLoading(true);
      const responce = await API_ENDPOINTS.getSchool(queryPayload);
      // console.log(responce, "responce");
      if (responce) {
        setLoading(false);

        setSchoolLists(responce);
        const transformedList = responce?.map((val) => ({
          value: val.schoolIdString,
          label: val.SchoolName,
        }));

        setSchoolList(transformedList);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getSchoolListing();
    getUserListing();
  }, []);
  const getSchoolDetails = (data) => {
    // console.log(data, "updateValue");

    const updatedSchoolIDs = {
      // SchoolName: data[2],
      // SeatsPurchased: data?.[5]?.[0]?.SeatsPurchased,
      email: data[4],
      firstName: data[1],
      lastName: data[8],
      id: data[4],
      // mobileNumber: data[6],
    };

    // Assuming schoolID is a state variable and setSchoolID is a state setter function
    setSchoolID(updatedSchoolIDs);

    setModal(true);
    setIsEdit(true);
  };
  const getTableColumns = () => {
    // console.log(schoolListDatas[3]?.subscriptions[0].SubscriptionPlan, "eeeee");
    let editObj = {
      label: "Actions",
      name: "",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div className="d-flex align-items-center justify-content-center">
            {tableMeta?.rowData[3] === "Admin" ||
            tableMeta?.rowData[3] === "Director" ? null : (
              <div
                className="ms-1"
                onClick={() => {
                  getSchoolDetails(tableMeta?.rowData);
                  setIsActionModal(true);
                }}
              >
                <MdEdit size={16} />
              </div>
            )}
          </div>
        ),
      },
    };

    let SubscriptionPlan = {
      label: "Subscription Plan",
      name: "",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowIndex = tableMeta.rowIndex;
          return (
            <>
              <div className="d-flex align-items-center justify-content-center">
                <p className="text-capitalize">
                  {
                    UserlListData[rowIndex]?.teams[0]?.subscriptions[0]?.SubscriptionPlan
                  }
                </p>
              </div>
            </>
          );
        },
      },
    };

    let newtable = [...UserList, SubscriptionPlan, editObj];
    return newtable;
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: schoolId ? schoolId.firstName : "",
      email: schoolId ? schoolId.email : "",
      lastName: schoolId ? schoolId.lastName : "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      try {
        let res;
        if (isActionModal) {
          const data = {
            emails: [values.email],
            subscriptionType: selectedOption?.SubscriptionType?.value,
          };
          res = await API_ENDPOINTS.EditUser(data);
          if (res?.status === 200) {
            setModal(false);
            getUserListing();
            getSchoolListing();
            setSelectedOption(null);
            validation.resetForm();
            // console.log(res, "responceee");
            toast.success(res?.message);
          }
        } else {
          const datas = {
            subscriptionType: selectedOption?.SubscriptionType?.value,
            user: {
              firstName: values.firstName,
              lastName: values.lastName,
              mobileNumber: values.mobileNumber,
              gender: selectedOption?.gender?.value,
              email: values.email,
            },
            school: { SchoolName: values.SchoolName },
            schoolId: selectedOption?.SchoolName?.value,
            subscription: {
              SeatsPurchased: values.SeatsPurchased,
            },
          };
          res = await API_ENDPOINTS.CreateUser(datas);
          if (res?.status === 200) {
            setModal(false);
            getUserListing();
            getSchoolListing();
            setSelectedOption(null);
            validation.resetForm();
            // console.log(res, "responceee");
            toast.success(res?.message);
          }
        }
      } catch (error) {
        Toast("Error", error.message);
      }
      validation.resetForm();
      setModal(false);
      getUserListing();
    },
  });

  const toggle = (isAction) => {
    setModal((prevModal) => !prevModal);
    setIsActionModal(isAction);
  };
  const onChange = (name, value) => {
    // console.log(value, "selectedOption");
    setSelectedOption({ ...selectedOption, [name]: value });
  };
  // console.log(selectedOption, "selectedOption");
  return loading ? (
    <></>
  ) : (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="TUB Cards" breadcrumbItem="TUB Card List" />
        <CustomBootstrapTable
          rows={UserlListData}
          columns={getTableColumns()}
          isLoading={false}
          showCreateBtn={true}
          keyField={"_id"}
          createBtnLabel="Create Admin"
          triggerPopup={toggle}
          getApplicationDetails={(row) => null}
          showStatusFilter={false}
          showDateFilter={false}
          //   history={props.history}
          //   filterList={(...params) => filterList(...params)}
        />
      </Container>
      <ModalContainer
        isOpen={isOpen}
        toggle={() => toggle(false)}
        title={isEdit ? "Edit User" : "Add New User"}
      >
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <FormikFormInput
              placeholder="Enter First Name"
              name="firstName"
              type="text"
              formGroupClassName="col-6"
              label="Enter First Name"
              // disabled={true}
              labelClassName="col-form-label"
              value={validation.firstName}
              onChange={validation.handleChange}
              validation={validation}
            />
            <FormikFormInput
              placeholder="Enter Last Name"
              name="lastName"
              type="text"
              // disabled={true}
              formGroupClassName="col-6"
              label="Enter Last Name"
              labelClassName="col-form-label"
              value={validation.lastName}
              onChange={validation.handleChange}
              validation={validation}
            />

            <FormikFormInput
              placeholder="Enter School Email"
              name="email"
              type="email"
              formGroupClassName="col-12"
              label="Enter  Email"
              labelClassName="col-form-label"
              value={validation.email}
              onChange={validation.handleChange}
              validation={validation}
            />
            <ReactSelect
              name="SubscriptionType"
              label="Subscription Type"
              type="react-select"
              formGroupClassName="row"
              labelClassName="col-12"
              placeholder="Subscription Type"
              isMulti={false}
              isClearable={false}
              closeMenuOnSelect={false}
              maxLimit={1}
              value={selectedOption?.["SubscriptionType"]}
              options={genderOptions}
              onChange={(...params) => onChange(...params)}
              //   error={validation}
            />
            <ReactSelect
              name="gender"
              label="Gender"
              type="react-select"
              formGroupClassName="row"
              labelClassName="col-12"
              placeholder="gender"
              isMulti={false}
              isClearable={false}
              closeMenuOnSelect={false}
              maxLimit={1}
              value={selectedOption?.["gender"]}
              options={genderOption}
              // isDisabled={true}
              onChange={(...params) => onChange(...params)}
              //   error={validation}
            />
            <ReactSelect
              name="SchoolName"
              label="SchoolName"
              type="react-select"
              formGroupClassName="row"
              labelClassName="col-12"
              placeholder="SchoolName"
              isMulti={false}
              isClearable={false}
              closeMenuOnSelect={false}
              maxLimit={1}
              value={selectedOption?.["SchoolName"]}
              options={schoolListData}
              // isDisabled={true}
              onChange={(...params) => onChange(...params)}
              //   error={validation}
            />
          </Row>
          <Row className="align-items-center">
            <Col>
              <div className="d-flex justify-content-end">
                <button type="submit" className="px-4 primary_btn">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalContainer>
    </div>
  );
};

export { AddUser };
