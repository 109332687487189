import {
  GET_DEAL_DETAIL,
  GET_DEAL_DETAIL_SUCCESS,
  GET_DEALS,
  GET_DEALS_SUCCESS,
  ADD_NEW_DEAL,
  ADD_DEAL_SUCCESS,
  UPDATE_DEAL,
  UPDATE_DEAL_SUCCESS,
} from "./actionTypes"

export const getDeals = (queryPayload) => ({
  type: GET_DEALS,
  payload: queryPayload
})
export const getDealsSuccess = Deals => ({
  type: GET_DEALS_SUCCESS,
  payload: Deals,
})


export const addNewDeal = data => ({
  type: ADD_NEW_DEAL,
  payload: data,
})
export const addDealSuccess = () => ({
  type: ADD_DEAL_SUCCESS,
  payload: null,
})


export const getDealDetail = (params) => ({
  type: GET_DEAL_DETAIL,
  payload: params
})
export const getDealDetailSuccess = data => ({
  type: GET_DEAL_DETAIL_SUCCESS,
  payload: data,
})


export const updateDeal = data => ({
  type: UPDATE_DEAL,
  payload: data,
})
export const updateDealSuccess = () => ({
  type: UPDATE_DEAL_SUCCESS,
  payload: null,
})