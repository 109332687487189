import {
  GET_WORD_DETAIL,
  GET_WORD_DETAIL_FAIL,
  GET_WORD_DETAIL_SUCCESS,
  GET_WORDS,
  GET_WORDS_FAIL,
  GET_WORDS_SUCCESS,
  ADD_NEW_WORD,
  ADD_WORD_SUCCESS,
  ADD_WORD_FAIL,
  UPDATE_WORD,
  UPDATE_WORD_SUCCESS,
  UPDATE_WORD_FAIL,
} from "./actionTypes"

export const getWords = (queryPayload) => ({
  type: GET_WORDS,
  payload: queryPayload
})

export const getWordsSuccess = Words => ({
  type: GET_WORDS_SUCCESS,
  payload: Words,
})

export const getWordDetail = (params) => ({
  type: GET_WORD_DETAIL,
  payload: params
})

export const addNewWord = data => ({
  type: ADD_NEW_WORD,
  payload: data,
})

export const addWordSuccess = () => ({
  type: ADD_WORD_SUCCESS,
  payload: null,
})

export const addWordFail = error => ({
  type: ADD_WORD_FAIL,
  payload: error,
})

export const getWordsFail = error => ({
  type: GET_WORDS_FAIL,
  payload: error,
})

export const getWordDetailSuccess = data => ({
  type: GET_WORD_DETAIL_SUCCESS,
  payload: data,
})

export const getWordDetailFail = error => ({
  type: GET_WORD_DETAIL_FAIL,
  payload: error,
})


export const updateWord = data => ({
  type: UPDATE_WORD,
  payload: data,
})

export const updateWordSuccess = () => ({
  type: UPDATE_WORD_SUCCESS,
  payload: null,
})

export const updateWordFail = error => ({
  type: UPDATE_WORD_FAIL,
  payload: error,
})
