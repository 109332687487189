import { call, put, take, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { FORM_LOADER } from "./actionTypes"

import {
  formLoader
} from "./actions"

function* checkFormLoading(data) {
    yield put(formLoader(data.payload))
}

function* loaderSaga() {
  yield takeEvery(FORM_LOADER, checkFormLoading)
}

export default loaderSaga;
