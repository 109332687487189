import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_WORDS , ADD_NEW_WORD , UPDATE_WORD, ADD_WORD_SUCCESS, UPDATE_WORD_SUCCESS } from "./actionTypes"

import {
  getWordsSuccess,
  getWordsFail,
  addWordFail,
} from "./actions"

//Include Both Helper File with needed methods
import { API_ENDPOINTS } from "api/endpoints"
import { history } from "helpers/utils"
import { Toast } from "helpers/Toast"


function* fetchWords(data) {
  try {
    const response = yield call(API_ENDPOINTS.getWords, {queryStringParameters: data.payload})
    yield put(getWordsSuccess(response))
  } catch (error) {
    yield put(getWordsFail(error))
  }
}


function* onAddNewWord(data) {
  try {
    const response = yield call(API_ENDPOINTS.createOrEditWord, {body: data.payload})
    history.goBack()
  } catch (error) {
    yield put(addWordFail(error))
  }
}

function* updateWordDetail(data) {
  try {
    let body = {
      body: data.payload,
      queryStringParameters: {
        imageId: data.payload._id
      }
    }
    const response = yield call(API_ENDPOINTS.createOrEditWord, body)
    history.push('/staff/words')
  } catch (error) {
    console.log('error', error)
    yield put(addWordFail(error))
  }
}

function* wordsSaga() {
  yield takeEvery(GET_WORDS, fetchWords)
  yield takeEvery(ADD_NEW_WORD, onAddNewWord)
  yield takeEvery(UPDATE_WORD, updateWordDetail)
}

export default wordsSaga;
