
import React, { useEffect, useRef, useState } from "react";
import { Badge, Collapse, Col } from "reactstrap";
import { toggleRootClassName, history } from 'helpers/utils'
import { getLinksBasedOnRoleId } from 'helpers/roleHandler'
import { Toast } from "helpers/Toast";
import "./sidebar.scss";
import logo from "../../assets/images/logo-light.png";
import logoSm from "../../assets/images/logo.svg";

let prefix = "/staff"

const Sidebar = ({ location, history }) => {
  const [filteredLinks, setFilteredLinks] = useState({})
  const { innerWidth: width } = window;

  const redirectTo = (pathName) => {
    if (width <= 1199) {
      toggleRootClassName("open-sidebar")
    }
    history.push(prefix + pathName)
  }

  let role = 1
  useEffect(() => {
    let newfilteredLinks = getLinksBasedOnRoleId(role)
    setFilteredLinks({ ...filteredLinks, ...newfilteredLinks })
  }, [])


  const toggleActiveSubMenu = (menuTitle, index = null, data = null) => {
    let extractedFilteredLinks = {...filteredLinks}
    if (index != null && data != null) {
    extractedFilteredLinks[menuTitle]?.map((link, i) => {
        if (index === i) { link.showSubContent = !data.showSubContent }
      })
    } else {
      extractedFilteredLinks[menuTitle]?.map((link, i) => {
        if (link.showSubContent != undefined) link.showSubContent = false
      })
    }
    setFilteredLinks(extractedFilteredLinks)
  }
  const handleLinkClick = (data, menuTitle, index) => {
    var body = document.body;
    if (body.classList.contains('sidebar-mini')) {
      if (data.showSubContent == undefined) {
        redirectTo(data.routeName)
        toggleActiveSubMenu(menuTitle, null, data)
      }
    } else {
      if (data.showSubContent != undefined) {
        toggleActiveSubMenu(menuTitle, index, data)
      } else {
        redirectTo(data.routeName)
        toggleActiveSubMenu(menuTitle, null, data)
      }
    }
  }

  const modelAccepted = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.replace("/auth/staff/login");
    Toast({ type: 'info', message: 'Logged out successfully!' })
  }
  const entries = Object.entries(filteredLinks);
  return (
    <>
      <div className="side-nav">
        <aside className="w-100 d-flex flex-column">
          <div className="sidebar_logo">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="logo-sm">
              <img src={logoSm} alt="logo" />
            </div>
          </div>
          <ul className="w-100 list-unstyled staff-sidebar custom-scrollbar flex-grow-1">

            {entries.map((data, i) => (
              <div>
                <li className="menu-title">{data['0']}</li>
                {data[1]?.map((dt, index) => (
                  <li
                    id={dt.togglerName}
                    className={`nav-list-menu ${dt.className} ${location.pathname.includes(prefix + dt?.routeName) ? "active" : ''
                      } `}
                  >
                    {dt.subContent !== undefined && dt.subContent.length > 0
                      ?
                      // with subcontent
                      <>
                        <div
                          onClick={() => handleLinkClick(dt, data[0], index)}
                          className={`nav-list-wrapper ${location.pathname.includes(prefix + dt.routeName) ? "text-white br-0" : "br-0"}`}
                          data-toggle={dt.showSubContent}
                        >
                          <div className="nav-view-icon">
                            <img src={dt.icon} />
                          </div>
                          <div className="nav-view-content">
                            <h5 className="name">{dt.name}</h5>
                          </div>
                          {
                            dt.subContent ? <i className={`ml-3 fa arrow fa-chevron-${dt.showSubContent ? "up" : "down"} small`}></i> : ''
                          }
                        </div>
                        <Collapse isOpen={dt.showSubContent} id="collapse">
                          <ul className={`list-unstyled sub-content `}>
                            {dt.subContent.map((subData) => {
                              return (
                                <li
                                  className={` nav-list-menu`}
                                  onClick={() => redirectTo(subData.routeName)}
                                >
                                  <div className={`${location.pathname.includes(prefix + subData.togglerName) ? 'active' : ''} nav-list-wrapper`}>
                                    <div className="nav-view-content">
                                      <h5 className="name">
                                        {subData.name}
                                      </h5>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </Collapse>
                      </>
                      :
                      // without subcontent
                      <div
                        onClick={() => handleLinkClick(dt, data[0], null)}
                        className={`nav-list-wrapper ${location.pathname.includes(prefix + dt.routeName) ? "text-white br-0" : "br-0"}`}
                      >
                        <div className="nav-view-icon">
                          <img src={dt.icon} />
                        </div>
                        <div className="nav-view-content">
                          <h5 className="name">{dt.name}</h5>
                        </div>
                      </div>
                    }
                  </li>
                ))}

              </div>
            ))}
            <div className="modal-backdrop show d-none" onClick={() => toggleRootClassName("open-sidebar")}></div>
          </ul>
        </aside>
      </div>
      <div className="modal-backdrop show d-none" onClick={() => toggleRootClassName("open-sidebar")}></div>
    </>
  );
}
export default Sidebar