import {
  GET_TUB_CARDS,
  GET_TUB_CARDS_SUCCESS,

  ADD_NEW_TUB_CARD,
  ADD_TUB_CARD_SUCCESS,

  GET_TUB_CARD_DETAIL,
  GET_TUB_CARD_DETAIL_SUCCESS,

  UPDATE_TUB_CARD,
  UPDATE_TUB_CARD_SUCCESS
  
} from "./actionTypes"

export const getTubCards = (queryPayload) => ({
  type: GET_TUB_CARDS,
  payload: queryPayload
})
export const getTubCardsSuccess = tubCards => ({
  type: GET_TUB_CARDS_SUCCESS,
  payload: tubCards,
})


export const addNewTubCard = data => ({
  type: ADD_NEW_TUB_CARD,
  payload: data,
})
export const addTubCardSuccess = () => ({
  type: ADD_TUB_CARD_SUCCESS,
  payload: null,
})


export const getTubCardDetail = (params) => ({
  type: GET_TUB_CARD_DETAIL,
  payload: params
})
export const getTubCardDetailSuccess = data => ({
  type: GET_TUB_CARD_DETAIL_SUCCESS,
  payload: data,
})


export const updateTubCard = data => ({
  type: UPDATE_TUB_CARD,
  payload: data,
})
export const updateTubCardSuccess = () => ({
  type: UPDATE_TUB_CARD_SUCCESS,
  payload: null,
})